import Validation from '@assets/scripts/components/validationChecks';

import Helpers from '@assets/scripts/helpers';
import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Validate error block and returns erros array
 * for a given block
 *
 * @param {Object} block
 *  Block to get errors
 *
 * @returns {Array}
 *	array of errors
 */
export const validateErrorBlock = async (block) => {
	let output = [];

	const setBlockError = (description) => {
		output.push(Helpers.createErrorObject(description, block.guid));
	};

	//check if error code is filled in
	if (!Validation.stringNotEmpty(block.config.error_code)) {
		setBlockError(
			t('fb.flowErrors.noErrorCode', {
				block: t('fb.blockTypes.error'),
			})
		);
	}
	return output;
};
