import { getStoreMutation } from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_FUNCTIONS } from '@modules/FlowBuilder/endpoints';

export const names = {
	ADD_BLOCK_BUTTON_CLICKED: 'addBlockButtonClicked',
	TOGGLE_CREATE_NEW: 'toggleCreateNewFlow',
	FUNCTION_BY_ID: 'functionById',
	RESET: 'reset',
};

export default {
	/**
	 * Saves options for next block to add, and opens
	 * side drawer to choose block type
	 * Called when user clicks 'Add' button on Canvas Flow
	 * N.B.: block will only actually be added after user
	 * selects block type in side drawer
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Object} options
	 *  Options as needed for mutation 'SET_NEW_BLOCK_OPTIONS'
	 *  See there for more details
	 *
	 * @returns {void}
	 */
	[names.ADD_BLOCK_BUTTON_CLICKED]({ commit }, options = {}) {
		// save options for next block to add
		commit(getStoreMutation('SET_NEW_BLOCK_OPTIONS', 'BLOCKS'), options, {
			root: true,
		});

		// open drawer to choose block type
		commit(
			getStoreMutation('OPEN_DRAWER'),
			{
				type: 'chooseNewBlock',
			},
			{
				root: true,
			}
		);
	},
	[names.TOGGLE_CREATE_NEW](store, status = true) {
		store.commit(getStoreMutation('TOGGLE_CREATE_NEW', 'FB'), !!status, {
			root: true,
		});
	},
	/**
	 * Get specific Function by GUID
	 *
	 * @param {String} guid
	 *  GUID of Function to return
	 *
	 * @returns {Object}
	 *  Object of found Function
	 */
	async [names.FUNCTION_BY_ID](store, guid) {
		// get all functions
		let result = await useApiAsync(GET_FUNCTIONS);

		if (result) {
			// filter function lists on guid
			result = result.find((row) => {
				return row.guid === guid;
			});
		}

		return result;
	},
	[names.RESET]({ commit }) {
		// reset state of all FlowBuilder related stores
		['FB', 'FLOW', 'BLOCKS'].forEach((id) => {
			try {
				commit(getStoreMutation('RESET', id), null, { root: true });
			} catch (err) {
				// no need to handle error here
			}
		});
	},
};
