<template>
	<div ref="tab" class="tab" v-show="isActive">
		<slot v-if="tabOpenedOnce"></slot>
	</div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { inject, onBeforeMount, watch } from '@vue/runtime-core';

export default {
	name: 'VTabItem',
	props: {
		title: {
			type: String,
			required: true,
		},
		/**
		 * Callback fn to execute when the tabs
		 * gets selected
		 */
		onOpen: {
			type: Function,
			required: false,
		},
	},
	setup(props) {
		const index = ref(0);
		const isActive = ref(false);
		const tabOpenedOnce = ref(false);

		const tabs = inject('TabsProvider');

		watch(
			() => tabs.selectedIndex,
			() => {
				isActive.value = index.value === tabs.selectedIndex;
			}
		);

		const unwatch = watch(isActive, (newValue) => {
			tabOpenedOnce.value = tabOpenedOnce.value || newValue;

			if (!props.onOpen && tabOpenedOnce.value) unwatch();
			else if (props.onOpen && newValue) {
				props.onOpen();
			}

		});

		onBeforeMount(() => {
			index.value = tabs.count;
			tabs.count++;
			isActive.value = index.value === tabs.selectedIndex;
		});

		return { index, isActive, tabOpenedOnce };
	},
};
</script>
