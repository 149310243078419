import Helpers from '@assets/scripts/helpers';

const state = {
	showCreateNew: false,
	modified: false,
	canBeModified: false,
	canModifyDocName: false,
	canBeExtended: false,
	currentDocument: {},
	currentConnection: false,
};

export default () => Helpers.cloneObject(state);
