import getDefaultState from './state';
import { getStoreMutation } from '@assets/scripts/store/config';
import usePermission from '@assets/scripts/composables/usePermission';

export const names = {
	SET_CURRENT_REST_API: 'setCurrentRestApi',
	SET_CURRENT_API_USER: 'setCurrentApiUser',
	UNSET_REST_API: 'unsetRestApi',
	UNSET_API_USER: 'unsetApiUser',
	MARK_REST_API_MODIFIED: 'markRestApiModified',
	MARK_API_USER_MODIFIED: 'markApiUserModified',
    TOGGLE_CREATE_NEW: 'toggleCreateNew',
    SET_NAME: 'setName',
	RESET: 'reset',
};

export default {
    [names.TOGGLE_CREATE_NEW](state, status = true) {
		if (state.showCreateNew !== status) state.showCreateNew = status;
	},
	/**
	 * Sets the current REST API to newly retrieved restapi
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} restApi
	 *  Retrieved restapi from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_REST_API](state, restApi) {
		// save restapi to state
		state.currentRestApi = restApi;

		// a newly loaded restapi is not modified yet
		state.restApiModified = false;
	},
	/**
	 * Sets the current apiUser to newly retrieved apiUser
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} apiUser
	 *  Retrieved apiUser from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_API_USER](state, apiUser) {
		// save apiUser to state
		state.currentApiUser = apiUser;

		// a newly loaded apiUser is not modified yet
		state.apiUserModified = false;
	},
    /**
	 * Updates the name of the currently active REST API
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {String} newName
	 *  Name to set for REST API
	 *
	 * @returns {void}
	 */
	[names.SET_NAME]({ currentRestApi }, newName) {
		// check if currently in edit mode
		if (!usePermission('Upsert', 'RestApiBuilder')) return;

		// set name on REST API
		currentRestApi.name = newName;

		// mark REST API as modified
		this.commit(
			getStoreMutation('MARK_REST_API_MODIFIED', 'RA'), null, { root: true }
		);
	},
	/**
	 * Unsets the current REST API
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.UNSET_REST_API](state) {
		// unset current REST API in state
		state.currentRestApi = {};

		// unset modified marker
		state.restApiModified = false;
	},
	/**
	 * Unsets the current api user
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.UNSET_API_USER](state) {
		// unset current api user in state
		state.currentApiUser = {};

		// unset modified marker
		state.apiUserModified = false;
	},
	/**
	 * Marks the current REST API as modified
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.MARK_REST_API_MODIFIED](state) {
		state.restApiModified = true;

        // every change to the REST API means that the REST API should be validated again
		this.commit(
			getStoreMutation('SET_IS_VALID'), false, { root: true }
		);
	},
	/**
	 * Marks the current api user as modified
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.MARK_API_USER_MODIFIED](state) {
		state.apiUserModified = true;
	},
	/**
	 * Resets module state to default
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @returns {void}
	 */
	[names.RESET](state) {
		Object.assign(state, getDefaultState());
	},
};
