import Helpers from '@assets/scripts/helpers';
import setLoader from '@assets/scripts/store/loader';
import callApi from '@assets/scripts/api';
import { ref } from 'vue';

/**
 * Composable for API to use in Vue Components, and other places
 * Docs about composables: https://vuejs.org/guide/reusability/composables.html#composables
 */

/**
 * Does an API call in a non-asynchronous way
 * Uses Vue refs to update output
 *
 * @param {Object} endpoint
 *  Endpoint Object
 *
 * @param {Object} config
 *  Object with options to be used by the API.
 *  Can include: 
 * 	 > keys
 * 	 > parameters
 * 	 > headers
 *  See methods in scripts/api/Api.js for more info
 *
 * @param {Boolean} showLoader
 *  Indicates whether a 'global' loader should be displayed
 *  during execution
 *
 * @return {Object}
 *  - data: recevied data
 *  - error: thrown error
 */
export const useApi = (endpoint, config = {}, showLoader = false, handleError = true) => {
	const data = ref(null);
	const error = ref(null);
	let loaderGuid;
	
	if (showLoader) {
		// show loader if requested
		loaderGuid = Helpers.newGuid();
		setLoader(loaderGuid);
	}

	// do api call
	callApi({
		...config,
		handleErrors: handleError,
		endpoint
	})
	.then((response) => {
		data.value = response;
	})
	.catch((response) => {
		error.value = response
	})
	.finally(() => {
		//stop loader
		if (showLoader) setLoader(loaderGuid, false);
	});

	return { data, error };
};

/**
 * Does an API call in a asynchronous way
 *
 * @param {Object} endpoint
 *  Endpoint Object
 *
 * @param {Object} config
 *  Object with options to be used by the API.
 *  Can include: 
 * 	 > keys
 * 	 > parameters
 * 	 > headers
 *  See methods in scripts/api/Api.js for more info
 *
 * @param {Boolean} showLoader
 *  Indicates whether a 'global' loader should be displayed
 *  during execution
 *
 * @param {Function} onError
 *  Custom error handler for failing requests. If given the default error handling
 *  of the API is suppressed
 *
 * @return {Any}
 *  Received result, error or false
 */
export const useApiAsync = async (endpoint, config = {}, showLoader = true, onError = false) => {
	let result = false;
	let loaderGuid;
	const useCustomErrorHandling = (typeof onError === 'function');
	
	if (showLoader) {
		// show loader if requested
		loaderGuid = Helpers.newGuid();
		setLoader(loaderGuid);
	}

	await callApi({
		...config,
		handleErrors: !useCustomErrorHandling,
		endpoint,
	})
	.then((response) => {
		result = response;
	})
	.catch((error) => {
		if (useCustomErrorHandling) onError(error);
	})
	.finally(() => {
		//stop loader
		if (showLoader) setLoader(loaderGuid, false);
	});

	return result;
};