import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

export default {
    label: t('ra.permissions.label'),
    info: t('ra.permissions.info'),
    module: 'RestApiBuilder',
    permissions: {
        'Read': {
            label: t('ra.permissions.readRestApi.label'),
            info: t('ra.permissions.readRestApi.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Upsert': {
            implicit: [
                'Read',
            ],
            label: t('ra.permissions.editRestApi.label'),
            info: t('ra.permissions.editRestApi.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Delete': {
            implicit: [
                'Read',
            ], 
            label: t('ra.permissions.deleteRestApiUser.label'),
            info: t('ra.permissions.deleteRestApiUser.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        }
    }
};
