<template>
	<div class="notification"
		:class="[
			'is-' + type,
			{ 'is-rounded': isRounded },
			{ 'is-fullwidth': isFullwidth },
			{ 'is-deleted': isDeleted },
		]">
		<div class="notification-content">
			<VFlag :type="type" />
			<div class="notification-text" v-html="text"></div>
			<VButton 
				v-if="hasClose"
				:is-tool="true"
				:is-rounded="true"
				:title="$t('general.close')"
				classes="notification-close"
				size="is-large"
				icon="cross"
				@clicked="deleteNotification"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'VNotification',
	mounted: function () {
		// acts on CSS transition between 'default' and
		// 'is-deleted' state
		this.$el.addEventListener('transitionend', () => {
			// check if notification still exists
			if (this.$el && this.isDeleted) {
				// emit, so parent component can act and remove the data
				this.$emit('deleted');
				// hide notification with css
				this.$el.classList.add('is-hidden');
			}
		});

		// set timer for auto delete, if needed
		if (this.autoDelete > 0) {
			setTimeout(() => {
				if (!this.isDeleted) this.deleteNotification();
			}, this.autoDelete);
		}
	},
	data: function () {
		return {
			/**
			 * Marks whether the notification has been deleted,
			 * either automatically or manually
			 */
			isDeleted: false,
		}
	},
	props: {
		/**
		 * Text to display in the notification
		 */
		text: {
			type: String,
			required: true,
		},
		/**
		 * Type of notification, determines the color and icon
		 */
		type: {
			type: String,
			default: 'info',
			validator: (value) => {
                return [
					'',
                    'info',
                    'success',
                    'warning',
                    'danger'
                ].includes(value)
            }
		},
		/**
		 * Number of milliseconds before the notification
		 * deletes itself. Useful for non-obtrusive success
		 * messages. Setting 0 means no auto-delete.
		 */
		autoDelete: {
			type: Number,
			default: 0,
		},
		/**
		 * Gives notification rounded top left and bottom
		 * right corners
		 */
		isRounded: {
			type: Boolean,
			default: false
		},
		/**
		 * Makes notification as wide as its parent
		 */
		isFullwidth: {
			type: Boolean,
			default: false
		},
		/**
		 * Adds close/delete button to notification, so
		 * user can manually remove it
		 */
		hasClose: {
			type: Boolean,
			default: false
		},
	},
	emits: ['deleted'],
	methods: {
		/**
		 * Called after click on the 'Delete' button
		 */
		deleteNotification: function (ev) {
			this.isDeleted = true;
		}
	}
};
</script>
