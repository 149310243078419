<template>
	<VField
		:label="$t('general.parent')"
		:isFloating="true"
		:class="{ 'is-active': value || value === 0 }"
	>
		<VSelect
			:options="options"
			v-model="value"
			@change="inputChanged"
			:disabled="disabled || null"
		/>
	</VField>
</template>

<script>
import Field from '@assets/scripts/components/field';
import Helpers from '@assets/scripts/helpers';

export default {
	name: 'ParentSelector',
	props: {
		/**
		 * Array of parent fields
		 */
		parents: {
			type: Array,
			default: () => [],
		},
		/**
		 * Value to used for select
		 */
		valueModel: {
			type: null,
			default: 0,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			value: this.valueModel,
			modified: false,
		};
	},
	mounted: function () {
		if (!this.value && this.value !== 0) this.value = 0;
	},
	computed: {
		options: function () {
			let options = [
				{
					value: 0,
					text: this.$t('general.none'),
				},
			];

			const parents = [];

			this.parents.forEach((parent) => {
				parents.push({
					key: Field.getName(parent),
					text: Field.getNameAsPath(parent),
				});
			});

			options = options.concat(
				Helpers.mapObjectArrayForSelect(parents, {
					key: 'key',
					text: 'text',
				})
			);

			return options;
		},
	},
	watch: {
		valueModel: function (val, oldVal) {
			if (val !== oldVal) this.value = val || 0;
		},
	},
	methods: {
		inputChanged: function () {
			this.$emit('update:valueModel', this.value);
		},
	},
};
</script>

<style></style>
