<template>
	<component
		:is="element ? element : `h${size}`"
		:class="['title', `is-${size}`, classes]"
	>
		{{ text }}
		<!--
      @slot Slot can be used to add HTML inside title component
    -->
		<slot></slot>
	</component>
</template>

<script>
export default {
	name: 'VTitle',
	props: {
		/**
		 * HTML element to use
		 */
		element: {
			type: String,
		},
		/**
		 * Size of title, ranges from 1-N
		 */
		size: {
			type: Number,
			default: 3,
			validator(value) {
				return value > 0;
			},
		},
		/**
		 * Additional classes to add to component
		 */
		classes: {
			type: String,
		},
		/**
		 * Text of title
		 */
		text: {
			type: String,
		},
	},
};
</script>
