/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
 export default {
	pageTitle: 'REST API',
    restApiManagement: 'REST API management',
    apiUsers: 'API users',
	restApi: 'REST API',
    apiUserList: {
		addApiUser: 'Add API user',
		table: {
			noResults: 'No API users found',
			columns: {
				userName: 'Username',
				isActive: 'Is active',
				last_time_edited: 'Last time edited',
			},
		},
		delete: {
			confirm: {
				title: 'Delete this API user?',
				body: 'Are you sure you want to delete this API user? This action can not be undone.',
				confirmButtonText: 'Delete',
			},
		},
	},
    apiUserDetails: {
		title: 'API User details',
		apiUserInformation: 'API User information',
		apiUserStatus: 'API User status',
        last_time_edited: 'Last time edited',
		username: 'API User username',
        apiToken: 'API token',
		isActive: 'Active',
		nameError: 'Please enter a unique name',
		saveButton: 'Save API user',
		savedSuccessfully: 'API User was successfully saved',
        copy: 'Copy',
        copySuccess: 'Copied to clipboard',
        renew: 'Renew',
		close: {
			confirm: {
				title: 'Go back to overview?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you go back.',
				confirmButtonText: 'Ok',
			},
		},
	},
    apiUserDelete: {
		error: 'Something went wrong while deleting API user. Please try again.',
		success: 'API User was succesfully deleted',
	},
	restApiDelete: {
		error: 'Something went wrong while deleting REST API. Please try again.',
		success: 'REST API was succesfully deleted',
	},
    restApiList: {
		addRestApi: 'Add REST API',
		table: {
			noResults: 'No REST APIS found',
			columns: {
				name: 'Name',
                returnType: 'Return type',
				isActive: 'Is active',
				status: 'Status',
				last_time_edited: 'Last time edited',
			},
		},
		delete: {
			confirm: {
				title: 'Delete this REST API?',
				body: 'Are you sure you want to delete this REST API? This action can not be undone.',
				confirmButtonText: 'Delete',
			},
		},
	},
    validation: {
		restApiNameEmpty: 'REST API name can not be empty',
		restApiNameIsShort: 'REST API name should be at least 2 characters',
		restApiNameContainsSpace: 'REST API name contains a space',
		restApiNameNotUnique: 'REST API name already exists',
		apiReturnTypeNotChosen: 'Choose an API return type',
		apiReturnTypeNotExist: 'API return type does not exist',
		apiAuthenticationNotChosen: 'Choose an API authentication',
		apiAuthenticationNotExist: 'API authentication does not exist',
	},
    newRestApi: {
		title: 'Create new REST API',
		whatName: 'Give your REST API a name',
		giveDescription: 'Add a description for the new REST API',
		nameInputLabel: 'REST API name',
		submitButton: 'Next',
		createdSuccess: 'Succesfully created new REST API',
	},
    restApiDetails: {
		backToOverview: 'Back to overview',
		restApiName: 'REST API name',
		restApiSettings: 'REST API settings',
		description: 'Description',
		users: 'API Users',
        returnType: 'Return type',
        selectReturnType: 'Select API return type',
        selectApiAuth: 'Select API authentication',
		isActive: 'Active',
		isInternal: 'Internal',
		close: {
			confirm: {
				title: 'Go back to REST API overview?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you close this overlay.',
				confirmButtonText: 'Ok',
			},
		},
		savedSuccessfully: 'REST API was successfully saved',
		publish: {
			success: 'REST API was successfully published',
			confirm: {
				title: 'Publish the REST API?',
				body: 'Are you sure you want to publish the REST API? this action can not be undone.',
				confirmButtonText: 'Publish',
			},
		},
	},
    error: {
		loadRestApi: 'REST API was not found',
	},
	permissions: {
		label: 'REST API',
		info: 'Permissions connected to REST API & REST API users',
		readRestApi: {
			label: 'Read REST API',
			info: 'Permission to read all REST API and REST API users',
		},
		editRestApi: {
			label: 'Edit REST API',
			info: 'Permission to edit all REST API and REST API users',
		},
		deleteRestApiUser: {
			label: 'Delete REST API users',
			info: 'Permission to delete all REST API users',
		},
	}
 }