import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

export default {
    label: t('user.permissions.label'),
    info: t('user.permissions.info'),
    module: 'UserManagement',
    permissions: {
        'Read': {
            label: t('user.permissions.readUsers.label'),
            info: t('user.permissions.readUsers.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Upsert': {
            implicit: [
                'Read',
            ],
            label: t('user.permissions.editUsers.label'),
            info: t('user.permissions.editUsers.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Delete': {
            implicit: [
                'Read',
            ], 
            label: t('user.permissions.deleteUsers.label'),
            info: t('user.permissions.deleteUsers.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        }
    }
};
