import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

export default {
    label: t('cb.permissions.label'),
    info: t('cb.permissions.info'),
    module: 'ConnectionBuilder',
    permissions: {
        'Read': {
            label: t('cb.permissions.readConnection.label'),
            info: t('cb.permissions.readConnection.info'),
            availableInRole: true, // if true will be visible in role details as a checkbox
        },
        'Upsert': {
            implicit: [
                'Read',
                'Publish',
            ],
            label: t('cb.permissions.editConnection.label'),
            info: t('cb.permissions.editConnection.info'),
            availableInRole: true, // if true will be visible in role details as a checkbox
        },
        'Publish': {
            // this permission is only for the backend to check if user can publish connection
            // in the frontend we implicit this permission with Upsert permission and we only show one checkbox for both Upsert and publish
            // in this case backend will get a separate publish and upsert permissions
            availableInRole: false // if true will be visible in role details as a checkbox
        },
    }
};
