import i18n from '@assets/i18n';
import Helpers from '@assets/scripts/helpers';
import { isEmpty } from 'lodash';
import { composedDocumentMeta } from '@modules/ComposedDocument/endpoints';
import usePermission from '@assets/scripts/composables/usePermission';
import { debug } from '@assets/scripts/components/notifications';
import Validation from '@assets/scripts/components/validationChecks';
import { useApiAsync } from '@assets/scripts/composables/useApi';

import {
	GET_REFERENCING_DOCUMENTS,
	GET_COMPOSED_DOCUMENTS,
} from '@modules/ComposedDocument/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

// possible statuses for documents
const statuses = {
	PUBLISHED: 'published',
	DRAFT: 'draft',
};

/**
 * Returns a newly created composed document
 *
 * @param {String} name
 *  Name of the Document
 *
 * @param {String} description
 *  Description of the Document
 *
 * @param {String} type
 *  Type of Document
 *
 * @param {String} conn_guid
 *  GUID of Connection that Composed Document will be part of
 *
 * @returns {Object}
 *  New Composed Document
 */
export const createNewComposedDocument = ({ name, description, conn_guid }) => {
	// get current date
	const dateIso = new Date().toISOString();

	// create and return new flow
	return Helpers.obj.create(composedDocumentMeta, {
		name,
		description,
		guid: Helpers.newGuid(),
		conn_guid,
		created: dateIso,
		modified: dateIso,
	});
};

/**
 * Prepares a given composed document for posting to the with the Nominow API
 *
 * @param {Object} composedDocument
 *  Normalized version of composed document to construct
 *
 * @returns {Object}
 *  Cloned and updated method object
 */
export const prepareComposedDocumentForPost = (composedDocument) => {
	// return empty object if no method is given
	if (!composedDocument || isEmpty(composedDocument)) return {};
	
	// find the right object recursivly using its guid and delete it
	const findAndDeleteEmptyChildRecursive = (document) => {
		let foundTargetChild = false;
		document.childs.forEach((child) => {
			if (child.childs && child.guid) {
				findAndDeleteEmptyChildRecursive(child);
			}
			if (!child.guid) {
				foundTargetChild = true;
			}
		});
		if (foundTargetChild) {
			// getting the index of the object we want to delete
			const indexOfObject = document.childs.findIndex(
				(object) => {
					return !object.guid;
				}
			);
			// delete target object from the parentDocument.childs array
			document.childs.splice(indexOfObject, 1);
		}
	};
	findAndDeleteEmptyChildRecursive(composedDocument.document);

	// return updated method
	return composedDocument;
};

/**
 * Gets the status of a given composed document
 *
 * @param {Object} composedDocument
 *  Composed document to get status of (normalized)
 *
 * @returns {String}
 *  Status of given composed document
 */
const getComposedDocStatus = (composedDocument) => {
	// determine status
	let status = statuses.DRAFT;

	// check if composed document is published
	if (composedDocument.is_published === true) {
		status = statuses.PUBLISHED;
	}

	return status;
};

/**
 * Gets the status of a given composed document as human readable text,
 * i.e.: Draft, Published etc.
 * Meant for display purposes, not for checking conditions
 *
 * @param {Object} composedDocument
 *  Composed document to get status of (normalized)
 *
 * @returns {String}
 *  Translated human readable status
 */
const getComposedDocStatusName = (composedDocument) => {
	let output;

	// determine status
	switch (getComposedDocStatus(composedDocument)) {
		case statuses.DRAFT:
			output = t('general.draft');
			break;
		case statuses.PUBLISHED:
			output = t('general.published');
			break;
	}

	return output;
};

/**
 * Check if a given composed document can be modified by current user
 *
 * @param {Object} composedDocument
 *  Composed document to check
 *
 * @returns {Boolean}
 */
export const userCanModifyComposedDoc = (composedDocument) => {
	return (
		(getComposedDocStatus(composedDocument) === statuses.DRAFT && usePermission('Upsert', 'ComposedDocument')) || // document is a Draft & user can edit docs
		usePermission('Upsert admin', 'ComposedDocument') // user is admin
	);
};

/**
 * Check if a given composed document can be extended by current user
 *
 * @param {Object} composedDocument
 *  Composed Document to check
 *
 * @returns {Boolean}
 */
export const userCanExtendComposedDoc = (composedDocument) => {
	return (
		getComposedDocStatus(composedDocument) === statuses.PUBLISHED && // composed document is published
		usePermission('Upsert', 'ComposedDocument') // user can edit docs
	);
};

/**
 * Check if a given composed document can be deleted by
 * the current user
 *
 * @param {Object} composedDocument
 *  Composed Document to check
 *
 * @returns {Boolean}
 */
const userCanDeleteComposedDoc = (composedDocument) => {
	return (
		(
			usePermission('Delete', 'ComposedDocument') && 
			getComposedDocStatus(composedDocument) === statuses.DRAFT
		) || usePermission('Delete published', 'ComposedDocument')
	);
};

/********************/
/* TABLE FORMATTING */
/********************/

/**
 * Get info about composed documents to use in Table in ComposedDocumentList component
 *
 * @param {Array} composedDocuments
 *  Array of composed document objects
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForTable = (composedDocuments) => {
	const result = [];

	// loop over fields
	composedDocuments.forEach((document, key) => {
		// get last modified time
		const lastModified = document.modified;

		result.push({
			key, // key, useful for handling clicks
			guid: document.guid,
			// name used for sorting
			doc_name: document.name,
			// is read action document
			is_read: document.is_read ? t('general.yes') : t('general.no'),
			// root child first document
			root_child: document.document.name,
			// time as ISO string, used for sorting
			time: lastModified,
			// localized time for display
			last_time_edited: lastModified
				? Helpers.date.localeStringWithMinutes(lastModified)
				: t('general.dash'),
			status: getComposedDocStatusName(document),
			edit: userCanModifyComposedDoc(document) || userCanExtendComposedDoc(document) ? 'edit' : 'view',
			delete: userCanDeleteComposedDoc(document),
		});
	});

	return result;
};

/********************/
/*    VALIDATION    */
/********************/

const createErrorObject = (description) => {
	return Helpers.createErrorObject(description);
};

/**
 * Validates given composed document and returns errors array
 *
 * @param {Object} composedDocument
 *  Composed Document to validate
 *
 * @returns {Array}
 *	array of errors
 */
export const validateComposedDocument = async (composedDocument) => {
	debug('Validating', { composedDocument });

	let output = [];

	const setError = (description) => {
		output.push(createErrorObject(description));
	};

	const validateChildRecursive = async (document) => {
		const referenceingDocuments = await useApiAsync(GET_REFERENCING_DOCUMENTS, {
			keys: {
				connection: composedDocument.conn_guid,
				guid: document.guid,
			},
			parameters: {
				read: composedDocument.is_read,
			},
		});

		// add all child names from one document childs in an array
		const childNames = document.childs.map((doc) => {
			return doc.child_name.toLowerCase();
		});

		// make an empty already checked document names per document
		const checkedDocumentNames = [];

		for (const i in document.childs) {
			if(document.childs[i].guid) {
				if (document.childs[i].childs) {
					await validateChildRecursive(document.childs[i]);
				}
				// check if child doc has method choosen
				if (!Validation.stringNotEmpty(document.childs[i].method)) {
					setError(
						t('cd.validation.documentMethodNotChosen', {
							documentName: document.childs[i].name,
						})
					);	
				}
				// check if child doc has child name set
				if (!Validation.stringNotEmpty(document.childs[i].child_name)) {
					setError(
						t('cd.validation.childNameIsEmpty', {
							documentName: document.childs[i].name,
						})
					);	
				} else {
					// check if child doc has unique child name
					if (!checkedDocumentNames.includes(document.childs[i].child_name.toLowerCase()) && 
					!Validation.stringIsUniqueInList(document.childs[i].child_name.toLowerCase(), childNames)) 
					{
						setError(
							t('cd.validation.childNameIsNotUnique', {
								childName: document.childs[i].child_name,
							})
						);	
					}
				}
				// check if child doc has child field set
				if (!Validation.stringNotEmpty(document.childs[i].child_field)) {
					setError(
						t('cd.validation.childFieldIsEmpty', {
							documentName: document.childs[i].name,
						})
					);	
				}
				// check if child doc has key field set
				if (!Validation.stringNotEmpty(document.childs[i].key_field)) {
					setError(
						t('cd.validation.keyFieldIsEmpty', {
							documentName: document.childs[i].name,
						})
					);	
				}
				// check if child doc has good refernce to parent doc
				if(referenceingDocuments && referenceingDocuments.length > 0) {
					const list = referenceingDocuments.map((doc) => {
						return doc.guid
					})
					if (!Validation.stringExistsInList(document.childs[i].guid, list)) {
						setError(
							t('cd.validation.referencingDocumentIsWrong', {
								documentName: document.childs[i].name,
							})
						);	
					}
				}
				// add doc child name to already checked doc names array
				checkedDocumentNames.push(document.childs[i].child_name.toLowerCase());
			}
		}
	};

	// check for empty composed document name
	if (!Validation.stringNotEmpty(composedDocument.name)) {
		setError(
			t('cd.validation.nameEmpty')
		);
	} 
	// check if composed document name has atleast 2 characters
	else if(!Validation.stringHasAtleastTwoCharacters(composedDocument.name)){
		setError(
			t('cd.validation.nameIsShort')
		);	
	}
	else {
		// check if composed document name without white spacing
		if (!Validation.stringWithoutSpacing(composedDocument.name)) {
			setError(t('cd.validation.nameContainsSpace'));
		}

		// get all composed documents for connection
		const connComposedDocuments = await useApiAsync(GET_COMPOSED_DOCUMENTS, {
			keys: {
				connection: composedDocument.conn_guid
			}
		});

		if (connComposedDocuments) {
			const list = [];

			// create list of composed document names, excluding composed document that is
			// being validated
			connComposedDocuments.forEach((comp) => {
				if (comp.guid !== composedDocument.guid) list.push(comp.name.toLowerCase());
			});

			// check if composed document name already exists
			if (Validation.stringExistsInList(composedDocument.name.toLowerCase(), list)) {
				setError(
					t('cd.validation.nameNotUnique')
				);
			}
		}
	}
	// check if root document is choosen
	if (!composedDocument.document || !Validation.stringNotEmpty(composedDocument.document.guid)) {
		setError(
			t('cd.validation.rootDocumentNotChosen')
		);
	} else {
		// check if root document method is choosen
		if (!Validation.stringNotEmpty(composedDocument.document.method)) {
			setError(
				t('cd.validation.documentMethodNotChosen', {
					documentName: composedDocument.document.name,
				})
			);	
		}
		// check if atleast one child document is choosen
		if(!composedDocument.document.childs.some(child => child.guid !== '' )) {
			setError(
				t('cd.validation.atleastOneChildChoosen')
			);	
		} else {
			// check the child documents if they have method choosen and has good reference to parent document and a child name is not empty
			await validateChildRecursive(composedDocument.document);
		}
	}

	return output;
};