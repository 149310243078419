import ability from '@assets/scripts/auth';

/**
 * Composable to check permission, to use in Vue Components, and other places
 * Docs about composables: https://vuejs.org/guide/reusability/composables.html#composables
 * 
 * Docs about CASL + Vue: https://casl.js.org/v5/en/package/casl-vue
 */

export default (permission, module) => {
	return ability.can(permission, module);
};
