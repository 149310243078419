/**
 * Checks if a given value is either a boolean with value true
 * or a string with value 'true'. Useful for checking values
 * retrived from JSON, where Boolean are sometimes parsed as strings
 */
export default (value) => {
	return (
		(typeof value === 'string' && value.toLowerCase() === 'true') ||
		value === true
	);
};
