<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="$t('fb.chooseNewBlockDrawer.drawerName')"
		:hasJsonButton="false"
		:hasFullwidthBody="true"
		:closeOnBackgroundClick="true"
	>
		<template #header>
			<p>
				{{ $t('fb.chooseNewBlockDrawer.text') }}
			</p>
		</template>

		<ul class="building-block-options">
			<li v-for="(option, key) in options" :key="key">
				<a
					class="building-block-option"
					:class="{ 'is-disabled': option.disabled }"
					@click.prevent.stop="() => { !option.disabled ? selectBlockType(option.type) : '' }"
				>
					<VIcon v-if="option.icon" class="block-icon" :name="option.icon" />

					<div class="content">
						<p>
							{{ option.title }} <br />
							<span class="is-small">{{ option.text }}</span>
						</p>
					</div>
				</a>
			</li>
		</ul>
	</DrawerItem>
</template>
<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreMutation, getStoreGetter } from '@assets/scripts/store/config';

export default {
	name: 'ChooseNewBlockDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			/**
			 * Get options for block that will be created
			 */
			newBlockOptions: getStoreGetter('NEW_BLOCK_OPTIONS', 'BLOCKS'),
			flowMethod: getStoreGetter('FLOW_METHOD', 'FLOW'),
		}),
		options: {
			get() {
				return [
					{
						type: 'ADD',
						title: this.$t('fb.chooseNewBlockDrawer.blockItems.add.title'),
						text: this.$t('fb.chooseNewBlockDrawer.blockItems.add.text'),
						icon: 'plus',
					},
					{
						type: 'CHECK',
						title: this.$t('fb.chooseNewBlockDrawer.blockItems.check.title'),
						text: this.$t('fb.chooseNewBlockDrawer.blockItems.check.text'),
						icon: 'check',
					},
					{
						type: 'READ',
						title: this.$t('fb.chooseNewBlockDrawer.blockItems.read.title'),
						text: this.$t('fb.chooseNewBlockDrawer.blockItems.read.text'),
						icon: 'import',
					},
					{
						type: 'WRITE',
						title: this.$t('fb.chooseNewBlockDrawer.blockItems.write.title'),
						text: this.$t('fb.chooseNewBlockDrawer.blockItems.write.text'),
						icon: 'export',
						disabled: this.flowMethod && this.flowMethod.toLowerCase() === 'get',
					},
					// NOTE: this is temp out for version 1.0 we will have External Connector in 1.1
					// {
					// 	type: 'EXTERNAL',
					// 	title: this.$t('fb.chooseNewBlockDrawer.blockItems.external.title'),
					// 	text: this.$t('fb.chooseNewBlockDrawer.blockItems.external.text'),
					// 	icon: 'result',
					// 	// External Connector can only be put after Result block
					// 	disabled:
					// 		typeof this.newBlockOptions.isAfterResult !== 'undefined' && !this.newBlockOptions.isAfterResult
					// },
				];
			},
		}
	},
	methods: {
		selectBlockType(type) {
			// close the drawer
			this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);

			// dispatch action to add new block
			this.$store.dispatch(
				getStoreAction('NEW_BLOCK_SELECTED', 'BLOCKS'),
				type
			);
		},
	},
};
</script>
