import Helpers from '@assets/scripts/helpers';

const state = {
	currentApiUser: {},
    apiUserModified: false,
	currentRestApi: {},
    restApiModified: false,
    showCreateNew: false,
};

export default () => Helpers.cloneObject(state);
