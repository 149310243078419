<template>
    <p v-if="sharedSecret">{{$t('deviceEnrollment.description')}}</p>
    <canvas ref="qrCanvas"></canvas>
    <p v-if="sharedSecret">{{$t('deviceEnrollment.alternativeDescription')}}</p>
    <p v-if="sharedSecret"><strong>{{sharedSecret}}</strong></p>
</template>

<script>
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { CREATE_SHARED_SECRET } from '@assets/scripts/api/config';
import { log, debug } from '@assets/scripts/components/notifications';
import QRCode from 'qrcode';
export default {
	name: 'DeviceEnrollment',
    data: function () {
		return {
			sharedSecret: false,
		};
	},
    mounted: async function () {
		// create shared secret from the API 
		this.sharedSecret = await useApiAsync(CREATE_SHARED_SECRET);

		if(this.sharedSecret) {
            const url = this.sharedSecret;
        
            QRCode.toCanvas(this.$refs.qrCanvas, url, (error) => {
                if (error) {
                    debug('error in QR code', error);
                    log(error, 'danger');
                };
            });
		}
	},
};
</script>
