<template>
	<a
		class="link"
		:href="$attrs.href ? $attrs.href : null"
		:class="{
			'is-underline': hasUnderline,
			'has-no-icon-border': noIconBorder,
		}"
		:disabled="isDisabled || null"
	>
		<VIcon
			:class="{ 'is-left': text }"
			:name="icon"
			v-if="icon && !iconOnRight"
		/>
		<span :class="{ 'has-tooltip': tooltipText }" class="link-text" v-if="text">{{ text }}</span>
		<VIcon
			:class="{ 'is-right': text }"
			:name="icon"
			v-if="icon && iconOnRight"
		/>
		<VTooltip
			v-if="tooltipText"
			:text="tooltipText"
		/>
	</a>
</template>
<script>
export default {
	name: 'VLink',
	props: {
		/**
		 * Text visible in the link element.
		 */
		text: {
			type: String,
			default: null,
		},
		/**
		 * If true the component will be disabled and not clickable.
		 */
		isDisabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * tooltip text.
		 */
		tooltipText: {
			type: String,
			default: '',
		},
		/**
		 * Icon shown in the component.
		 */
		icon: {
			type: String,
			default: null,
		},
		/**
		 * If true the icon will be shown on the right side of the text.
		 */
		iconOnRight: {
			type: Boolean,
			default: false,
		},
		/**
		 * If true the anchor will have an underline on hover.
		 */
		hasUnderline: {
			type: Boolean,
			default: false,
		},
		/**
		 * Removes the border around the icon on links with
		 * class 'is-alternative'
		 */
		noIconBorder: {
			type: Boolean,
			default: false,
		},
	},
	setup() {},
};
</script>
