<template>
	<DeviceEnrollment v-if="!mfaEnabled" />
    <VField
        :isFloating="true"
        :label="$t('twoFA.label')"
        :class="['two-fa-field', { 'is-active': isActive }]"
        :isError="showError"
    >
        <VInput
            v-model="value"
            :placeholder="$t('twoFA.placeholder')"
            type="text"
			:hasAutoFocus="true"
            class="two-fa"
            inputmode="numeric"
            pattern="[0-9]*"
            :maxlength="6"
            :minlength="6"
            @input="inputChanged"
            @keypress.enter.prevent="checkTwoFAFn"
        />
    </VField>
</template>

<script>
import DeviceEnrollment from '@materials/structures/DeviceEnrollment.vue';
import useJWT from '@assets/scripts/composables/useJWT.js';

export default {
	name: 'TwoFAInput',
	components: {
		DeviceEnrollment
	},
	setup() {
		const { mfaEnabled } = useJWT();
		return {
			mfaEnabled,
		};
	},
	props: {
		valueModel: {
			type: null,
		},
		validModel: {
			type: Boolean,
			default: false,
		},
        checkTwoFAFn: {
            type: Function,
            required: true,
        },
	},
	data: function () {
		return {
			value: typeof this.valueModel === 'string' ? this.valueModel : '',
			modified: false,
		};
	},
	mounted: function () {
		if (this.value !== this.valueModel)
			this.$emit('update:valueModel', this.value);
		this.$emit('update:validModel', this.isValid);
	},
	watch: {
		isValid() {
			this.$emit('update:validModel', this.isValid);
		},
	},
	computed: {
		isActive: function () {
			return this.value !== '';
		},
		isValid: function () {
			return (
				(this.value.length === 6) 
			);
		},
		showError: function () {
			return this.modified && !this.isValid;
		},
	},
	methods: {
		inputChanged: function (ev) {
			this.modified = true;
			this.$emit('update:valueModel', this.value);
		},
	},
};
</script>
