import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import {
	createNewMetaDocument,
	constructFullMetaDocument,
	validateMetaDocument,
	flattenMetaDocumentFields,
} from '@modules/MetaDocumentBuilder/components/metadata-document';
import { log, debug } from '@assets/scripts/components/notifications';
import i18n from '@assets/i18n';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	GET_REFERENCE_LIST,
	GET_METADATA_DOCUMENT_BY_ID,
	DELETE_METADATA_DOCUMENT,
	POST_METADATA_DOCUMENT,
} from '@modules/MetaDocumentBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

export const names = {
	GET_SCHEMA_TYPES: 'getSchemaTypes',
	TOGGLE_CREATE_NEW: 'toggleCreateNewDocument',
	NEW_META_DOCUMENT: 'newMetaDocument',
	LOAD_AND_SHOW_META_DOCUMENT: 'loadAndShowMetaDocument',
	UNSET_META_DOCUMENT: 'unsetMetaDocument',
	SAVE_CURRENT_META_DOCUMENT: 'saveCurrentMetaDocument',
	PUBLISH_CURRENT_META_DOCUMENT: 'publishCurrentMetaDocument',
	VALIDATE_CURRENT_META_DOCUMENT: 'validateCurrentMetaDocument',
	DELETE_DOCUMENT: 'deleteDocument',
	DOCUMENT_UPDATED: 'documentUpdated',
	UPDATE_DOCUMENT_LIST: 'updateDocumentList',
	RESET: 'reset',
	SAVE_FIELD: 'saveField',
};

export default {
	/**
	 * Action triggered to get schemaTypes
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @returns {Object}
	 * schemaTypes object within an items arrray
	 */
	async [names.GET_SCHEMA_TYPES]() {
		const schemaTypes = await useApiAsync(GET_REFERENCE_LIST, {
			keys: {
				name: 'SchemaTypes',
			},
		});

		if (!schemaTypes) {
			log(t('mb.error.loadSchemaTypes'), 'danger');
			return;
		}

		return schemaTypes.items || [];
	},
	[names.TOGGLE_CREATE_NEW]({ commit }, status = true) {
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'MB'), !!status, {
			root: true,
		});
	},
	/**
	 * Action triggered to validate the current meta document
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	[names.VALIDATE_CURRENT_META_DOCUMENT]({ commit, dispatch }) {
		// prepare for validation
		dispatch(getStoreAction('PREPARE_VALIDATION'), null, { root: true });

		// get current document from store
		const currentDoc = this.getters[getStoreGetter('CURRENT_META_DOCUMENT', 'MB')];

		// setTimeout is needed becouse we use async functions and we want the dom to be updated to show the loader first
		// setTimeout without time given we use 1 ms just to be sure that it works everywhere
		// we should fix this with better solution in the future because the async function for a reason are blocking the dom update
		setTimeout(async () => {
			const errors = await validateMetaDocument(currentDoc);

			// loop errors and add to the errors array
			errors.forEach((error) => {
				commit(
					getStoreMutation('ADD_VALIDATION_ERROR'),
					error,
					{ root: true }
				);
			});

			// finish validation
			dispatch(getStoreAction('FINISH_VALIDATION'), null, { root: true });
		}, 1);
	},
	/**
	 * Action triggered when a new Meta Document must be created
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} settings
	 *  Settings for the new Meta Document
	 *
	 * @returns {void}
	 */
	async [names.NEW_META_DOCUMENT]({ commit }, settings) {
		// create new Meta Document object with given settings
		const newMetaDocument = createNewMetaDocument(settings);

		// commit mutation in to set new Meta Document
		commit(getStoreMutation('SET_CURRENT_META_DOCUMENT', 'MB'), newMetaDocument, {
			root: true,
		});

		// unset create new
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'MB'), false, {
			root: true,
		});

		// mark new document as modified so it can be saved immediately
		commit(
			getStoreMutation('MARK_MODIFIED', 'MB'),
			{},
			{
				root: true,
			}
		);

		// show success message
		log(t('mb.newDocument.createdSuccess'), 'success');
	},
	/**
	 * Action triggered to load an existing Meta Document
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} guid
	 *  GUID of Meta Document to load
	 *
	 * @returns {void}
	 */
	async [names.LOAD_AND_SHOW_META_DOCUMENT]({ commit }, guid) {
		// load meta document from api
		const document = await useApiAsync(GET_METADATA_DOCUMENT_BY_ID, {
			keys: {
				guid
			}
		});

		if (document !== false) {
			// commit mutation in root store
			commit(getStoreMutation('SET_CURRENT_META_DOCUMENT', 'MB'),
				// flatten child structure of metadata document
				flattenMetaDocumentFields(document),
				{ root: true }
			);
		} else {
			log(t('mb.error.loadDocument'), 'danger');
		}
	},
	[names.UNSET_META_DOCUMENT]({ commit }) {
		// reset current document
		commit(getStoreMutation('UNSET_META_DOCUMENT', 'MB'), null, { root: true });

		// unset
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'MB'), false, {
			root: true,
		});
	},
	/**
	 * Action triggered to save the current Meta Document
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.SAVE_CURRENT_META_DOCUMENT]({ dispatch }) {
		// get current document from store
		const currentDoc = this.getters[getStoreGetter('CURRENT_META_DOCUMENT', 'MB')];

		// get fully constructed meta document object
		const document = constructFullMetaDocument(currentDoc);

		// post document through api
		const result = await useApiAsync(POST_METADATA_DOCUMENT, {
			parameters: {
				...document
			}
		});

		if (result !== false) {
			debug('succesfully saved', result);
			
			// show success message
			log(t('mb.docDetails.savedSuccessfully'), 'success');
	
			// dispatch action to take needed steps after saving
			dispatch(
				getStoreAction('DOCUMENT_UPDATED', 'MB'),
				null,
				{ root: true }
			);
	
			// dispatch action to unset document
			dispatch(getStoreAction('UNSET_META_DOCUMENT', 'MB'), null, { root: true });
		}
	},
	/**
	 * Action triggered to delete a metadata document
	 * with a given GUID
	 *
	 * @param {String} guid
	 *  GUID of document
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.DELETE_DOCUMENT]({ dispatch }, guid) {
		// do API call
		const result = await useApiAsync(DELETE_METADATA_DOCUMENT, {
			keys: {
				guid
			}
		});

		if (result !== false) {
			// show debug message in console
			debug('successful delete', {
				guid,
				result,
			});
	
			// dispatch action to take needed steps after deleting
			dispatch(
				getStoreAction('DOCUMENT_UPDATED', 'MB'),
				null,
				{ root: true }
			);
	
			// show success message
			log(t('mb.docDelete.success'), 'success');
		} else {
			// show debug message in console
			debug('error in delete', {
				guid,
				error,
			});
	
			// show error message
			log(t('mb.docDelete.error'), 'danger');
		}
	},
	[names.DOCUMENT_UPDATED]({ dispatch }) {
		// dispatch action to reload document list
		dispatch(
			getStoreAction('UPDATE_DOCUMENT_LIST', 'MB'),
			{},
			{ root: true }
		);
},
	[names.UPDATE_DOCUMENT_LIST]() {
		// do nothing
		// only exists so components can subscribe to it
	},
	/**
	 * Action triggered to publish the current Meta Document
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.PUBLISH_CURRENT_META_DOCUMENT]({ commit, dispatch }) {
		// mark meta document as published
		commit(getStoreMutation('SET_PUBLISHED_STATE', 'MB'), true, {
			root: true,
		});

		// get current document from store
		const currentDoc = this.getters[getStoreGetter('CURRENT_META_DOCUMENT', 'MB')];

		// get fully constructed meta document object
		const document = constructFullMetaDocument(currentDoc);

		// post document through api
		const result = await useApiAsync(POST_METADATA_DOCUMENT, {
			parameters: {
				...document
			}
		});

		if (result !== false) {
			debug('successfully published', result);
	
			// show success message
			log(t('mb.docDetails.publish.success'), 'success');
	
			// dispatch action to take needed steps after publishing
			dispatch(
				getStoreAction('DOCUMENT_UPDATED', 'MB'),
				null,
				{ root: true }
			);
	
			// dispatch action to unset meta document
			dispatch(getStoreAction('UNSET_META_DOCUMENT', 'MB'), null, { root: true });
		} else {
			// mark meta document as not published if anything
			// went wrong
			commit(getStoreMutation('SET_PUBLISHED_STATE', 'MB'), false, {
				root: true,
			});
		}
	},
	/**
	 * Updates an existing field of the current meta Document or
	 * adds a new field
	 * N.B.: Field is updated/saved in Current meta Document in module state
	 * Only after Save by user any changes will be moved to
	 * meta document
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Object} field
	 *  Field object to save
	 *
	 * @param {Integer} key
	 *  Key of field to update in field list
	 *
	 * @param {String} drawerId
	 *  ID of drawer to close after successful update
	 *
	 * @param {Boolean} showMessage
	 *  Indicator whether to show a success message when field
	 *  is saved
	 */
 	[names.SAVE_FIELD](
		{ commit },
		{ field, key = false, drawerId = false, showMessage = true }
	) {
		// prepare parameters for update mutation
		// success of update is based on value of 'result'
		// property in parameters, since mutations do not
		// return anything
		const param = {
			field,
			key,
			result: false,
		};

		// save field
		commit(getStoreMutation('SAVE_FIELD', 'MB'), param, {
			root: true,
		});

		if (!param.result) {
			// show error
			log(t('error.updateError'), 'danger');
			return;
		}

		// close drawer
		if (drawerId !== false) {
			commit(getStoreMutation('CLOSE_DRAWER'), drawerId, {
				root: true,
			});
		}

		// mark current document as modified
		commit(
			getStoreMutation('MARK_MODIFIED', 'MB'),
			{},
			{
				root: true,
			}
		);

		// show success message
		if (showMessage) log(t('field.updateSuccess'), 'success');
	},
	[names.RESET]({ commit }) {
		// reset state of store
		commit(getStoreMutation('RESET', 'MB'), null, { root: true });
	},
};
