import Helpers from '@assets/scripts/helpers';
import { userMeta } from '@modules/UserManagement/endpoints';
import usePermission from '@assets/scripts/composables/usePermission';
import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Returns a newly created user
 *
 * @returns {Object}
 *  New User
 */
export const createNewUser = () => {
	const user = Helpers.obj.create(userMeta, {});
	user.guid = Helpers.newGuid();
	return user
};

/**
 * Constructs a User Object from a given user
 * as can be used in communication with the Nominow API
 *
 * @param {Object} user
 *  Normalized version of user to construct
 *
 * @returns {Object}
 *  Fully constructed User Object
 */
export const constructFullUser = (user) => {
	// return fully constructed user
	return Helpers.obj.construct(user, userMeta);
};

/********************/
/* TABLE FORMATTING */
/********************/

/**
 * Get info about users to use in Table in UsersList component
 *
 * @param {Array} users
 *  Array of user objects
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForTable = (users) => {
	const result = [];
	// loop over users
	users.forEach((user) => {
		result.push({
			user,
			id: user.guid,
			firstName: user.first_name,
			lastName: user.last_name,
			email: user.email,
			isActive: user.is_active ? t('general.yes') : t('general.no'),
			// time as ISO string, used for sorting
			time: user.last_login,
			// localized time for display
			lastLogin: user.last_login
				? Helpers.date.localeStringWithMinutes(user.last_login)
				: t('general.dash'),
			edit: usePermission('Upsert', 'UserManagement') ? 'edit' : 'view',
			delete: usePermission('Delete', 'UserManagement'),
		});
	});

	return result;
};
