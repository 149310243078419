<template>
    <div class="is-flex is-flex-wrap-wrap">
        <div
            v-for="(item, index) in options"
            :key="index"
            class="is-flex is-align-items-center has-margin-right-3 has-margin-bottom-1"
        >
            <VOption
                v-model:modelValue="model[item.value]"
                type="checkbox"
                :label="item.label"
                wrapperClasses="has-margin-0 has-margin-right-05"
                @update:modelValue="modelChanged"
                :disabled="disabled"
            />

            <VTooltip
                v-if="item.info"
                :text="item.info"
            />
        </div>
    </div>
</template>

<script>
import Helpers from '@assets/scripts/helpers';

export default {
	name: 'ConfigOptions',
    props: {
        options: {
            type: Array,
            default: [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        valueModel: {
            type: Object,
            default: () => {},
        }
    },
    data: function () {
        return {
            model: this.setModel(),
        };
    },
	methods: {
		setModel: function () {
			return Helpers.cloneObject(this.valueModel);
		},
        modelChanged: function () {
            this.$emit('update:valueModel', this.model);
        },
	},
    watch: {
        valueModel: function () {
            this.model = this.setModel();
        }
    }
}
</script>
