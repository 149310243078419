<template>
	<div class="error-list" :class="{ 'collapsed': errorsListCollapsed }">
		<ul>
			<li
				v-for="(error, index) in errors"
				:key="index"
				@click.prevent.stop="() => (isClickable ? openBlock(error.blockGuid) : '')"
				class="flow-error"
			>
                <VNotification
                    :text="$t(error.errorDescription)"
                    :type="'danger'"
                    :is-rounded="false"
                    :is-fullwidth="true"
                    :has-close="false"
                />
			</li>
		</ul>
	</div>
</template>

<script>
import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';

export default {
	name: 'ErrorsList',
	props: {
		errors: {
			type: Array,
			required: false,
			default: function () {
				return [];
			},
		},
		errorsListCollapsed: {
			type: Boolean,
			required: false,
			default: false,
		},
		isClickable: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	methods: {
		openBlock: function (guid) {
			// scroll to block that has error
			document.querySelector(`#block-${guid}`).scrollIntoView();
			// get block that has error
			const block =
				this.$store.getters[getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')](
					guid
				);
			// open block details that has error
			this.$store.commit(
				getStoreMutation('SELECT_BLOCK', 'BLOCKS'),
				block
			);
		},
	},
};
</script>
