/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
 export default {
	pageTitle: 'Script Builder',
	scriptList: {
		title: 'Script Builder',
        scriptTypes: 'Script type',
		addScript: 'Add script',
		table: {
			noResults: 'No scripts found',
			columns: {
				name: 'Name',
				script_type: 'Script type',
                status: 'Status',
                host_name: 'Service host name',
				last_time_edited: 'Last time edited',
			},
		},
		delete: {
			confirm: {
				title: 'Delete this script?',
				body: 'Are you sure you want to delete this script?',
				confirmButtonText: 'Delete',
			},
		},
	},
    validation: {
		scriptNameEmpty: 'Script name can not be empty',
		scriptNameIsShort: 'Script name should be at least 2 characters',
		scriptNameContainsSpace: 'Script name contains a space',
		scriptNameNotUnique: 'Script name already exists',
		scriptIsEmpty: 'Add script',
		scriptTypeNotExist: 'Script type does not exist',
		scriptCommitIsEmpty: 'Commit note can not be empty',
	},
    scriptDelete: {
		error: 'Something went wrong while deleting script. Please try again.',
		success: 'Script was succesfully deleted',
	},
	error: {
		loadScript: 'Script was not found',
	},
    newScript: {
		title: 'Create new script',
		whatName: 'Give your script a name',
		giveDescription: 'Add a description for the new script',
		nameInputLabel: 'Script name',
		submitButton: 'Next',
		createdSuccess: 'Succesfully created new Script',
	},
    scriptDetails: {
		backToOverview: 'Back to overview',
		scriptName: 'Script name',
        scriptSettings: 'Script settings',
		description: 'Description',
        scriptCode: 'C# code',
        hostName: 'Service host name',
        scriptType: 'Script type',
        type: 'Type',
        commit: 'Commit note',
		close: {
			confirm: {
				title: 'Go back to script overview?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you close this overlay.',
				confirmButtonText: 'Ok',
			},
		},
		savedSuccessfully: 'Script was successfully saved',
		publish: {
			success: 'Script was successfully published',
			confirm: {
				title: 'Publish the script?',
				body: 'Are you sure you want to publish the script? this action can not be undone.',
				confirmButtonText: 'Publish',
			},
		},
	},
};
