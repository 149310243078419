<template>
	<VField :isGrouped="isGrouped" :label="label || null" :class="classes">
		<VOption
			v-for="(item, key) in options"
			:isButton="isButton"
			:key="key"
			:value="item.id"
			:label="item.label"
			v-model="value"
			type="radio"
			:disabled="isDisabled"
		/>
	</VField>
</template>

<script>
export default {
	name: 'VOptionBoolean',
	props: {
		isGrouped: {
			type: Boolean,
			default: false,
		},
		isButton: {
			type: Boolean,
			default: false,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: '',
		},
		modelValue: {
			type: Boolean,
			default: false,
		},
		trueLabel: {
			type: String,
			default: '',
		},
		falseLabel: {
			type: String,
			default: '',
		},
		classes: {
			type: String,
		}
	},
	data: function () {
		return {
			value: this.calculateValue(),
			options: [
				{
					label: this.trueLabel || this.$t('general.true'),
					id: 'yes',
				},
				{
					label: this.falseLabel || this.$t('general.false'),
					id: 'no',
				},
			],
		};
	},
	methods: {
		calculateValue: function () {
			return this.modelValue ? 'yes' : 'no';
		},
	},
	watch: {
		value() {
			this.$emit('update:modelValue', this.value === 'yes');
		},
		modelValue() {
			this.value = this.calculateValue();
		}
	},
};
</script>
