import modules from '@modules/abilities';

const abilities = {};

// loop over modules
for (const module in modules) {
	// add module abilities to existing abilities
	abilities[module] = modules[module];
}

export default abilities;
