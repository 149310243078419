/**
 * File offers functionality to handle Nominows 'Functions'
 * These are functions that return a single value based on
 * one or multiple inputs. For instance get the euro amount
 * based on the dollar amount and the SaleDate
 */
 import Helpers from '@assets/scripts/helpers';
import { store } from '@assets/scripts/components/store-proxy';
import Field from '@assets/scripts/components/field';
import { getStoreAction } from '@assets/scripts/store/config';

const getFunction = async (guid) => {
	// load Function
	return await store.dispatch(getStoreAction('FUNCTION_BY_ID', 'FB'), guid);
};

/**
 * Get info about Functions formatted for
 * use in FunctionsDrawer component table
 *
 * @param {Array} functions
 *  Array of Function definitions
 *
 * @param {Array} connections
 *  Array of Connections
 *
 * @param {String} fieldType
 *  Variable type that user wants to have as output for
 *  the Function. Used to disable all table rows that
 *  have different output type
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForFunctionsTable = (functions, connections = [], fieldType = false) => {
	const result = [];

	// loop over function lists
	functions.forEach((item) => {
		const outputType = getOutputTypeForFunction(item);

		result.push({
			guid: item.guid,
			field_name: item.name,
			field_description: item.description,
			field_output: outputType
				? Field.translateVarType(outputType)
				: false,
			conn_guid: item.conn_guid || false,
			connection: Helpers.getPropValueByProp(connections, 'conn_guid', 'name', item.conn_guid || false),
			disabled: fieldType && outputType && outputType !== fieldType,
		});
	});

	return result;
};

/**
 * Checks if the Function with a given GUID is
 * available for the given var type
 *
 * @param {String} guid
 *  GUID of Function
 *
 * @param {String} fieldType
 *  Var type to check
 *
 * @returns {Boolean}
 */
export const functionAvailableForType = async (guid, fieldType) => {
	// load Function
	const item = await getFunction(guid);

	// check if Function exists
	if (!item) return false;

	// get type of output of Function
	const type = getOutputTypeForFunction(item);

	// compare key field type and given field type
	return type === fieldType;
};

/**
 * Returns the output field type from the Functions
 *
 * @param {Object} item
 *  Instance of Function
 *
 * @returns {String}
 *  Found output type or false
 */
const getOutputTypeForFunction = (item) => {
	return item.output.type || false;
};
