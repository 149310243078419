import Validation from '@assets/scripts/components/validationChecks';

import Helpers from '@assets/scripts/helpers';
import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Validate config block and returns erros array
 * for a given block
 *
 * @param {Object} block
 *  Block to get errors
 *
 * @returns {Array}
 *	array of errors
 */
export const validateConfigBlock = async (block) => {
	let output = [];

	const setBlockError = (description) => {
		output.push(Helpers.createErrorObject(description, block.guid));
	};

	//check if config field is filled in
	if (!Validation.isNonEmptyObject(block.config.properties)) {
		setBlockError(
			t('fb.flowErrors.noPropertiesJson', {
				block: t('fb.blockTypes.config'),
			})
		);
	}
	if (!Validation.isNonEmptyObject(block.config.input_mapping)) {
		setBlockError(
			t('fb.flowErrors.noInputMappingJson', {
				block: t('fb.blockTypes.config'),
			})
		);
	}
	if (!Validation.isNonEmptyObject(block.config.config)) {
		setBlockError(
			t('fb.flowErrors.noConfigJson', {
				block: t('fb.blockTypes.config'),
			})
		);
	}
	return output;
};
