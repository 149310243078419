import Helpers from '@assets/scripts/helpers';
import i18n from '@assets/i18n';
import {
	scriptMeta,
	GET_SCRIPTS,
	GET_SCRIPT_TYPES,
	CHECK_COMPILATION,
} from '@modules/ScriptBuilder/endpoints';
import { debug } from '@assets/scripts/components/notifications';
import Validation from '@assets/scripts/components/validationChecks';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import usePermission from '@assets/scripts/composables/usePermission';

// translate function of vue-i18n
const { t } = i18n.global;

// possible statuses for scripts
const statuses = {
	PUBLISHED: 'published',
	DRAFT: 'draft',
};

/**
 * Gets the status of a given script as human readable text,
 * i.e.: Draft, Published etc.
 * Meant for display purposes, not for checking conditions
 *
 * @param {Object} script
 *  Script to get status of (normalized)
 *
 * @returns {String}
 *  Translated human readable status
 */
const getScriptStatusName = (script) => {
	let output;

	// determine status
	switch (getScriptStatus(script)) {
		case statuses.DRAFT:
			output = t('general.draft');
			break;
		case statuses.PUBLISHED:
			output = t('general.published');
			break;
	}

	return output;
};
/**
 * Gets the status of a given script
 *
 * @param {Object} script
 *  Script to get status of (normalized)
 *
 * @returns {String}
 *  Status of given script
 */
const getScriptStatus = (script) => {
	// determine status
	let status = statuses.DRAFT;

	// check if script is published
	if (script.is_published === true) {
		status = statuses.PUBLISHED;
	}

	return status;
};

/**
 * Returns a newly created script
 *
 * @param {String} name
 *  Name of the script
 *
 * @param {String} description
 *  Description of the script
 *
 * @returns {Object}
 *  New Script
 */
export const createNewScript = ({ name, description }) => {
	// create and return new script
	return Helpers.obj.create(scriptMeta, {
		name,
		description,
		guid: Helpers.newGuid(),
		is_new: true,
	});
};

export const trimScriptName = (scriptName) => {
	// only allow lower and uppercase letters and numbers and underscroe and dot
	const regex2 = new RegExp('(^[.])|([^a-z0-9_.])|(\\.\\.)', 'gi');
	return scriptName.replace(regex2, '');
};

/**
 * Check if a given script can be modified.
 *
 * @returns {Boolean}
 */
export const userCanModifyScript = () => {
	return usePermission('Upsert', 'ScriptBuilder') // user can edit scripts
};

/********************/
/* TABLE FORMATTING */
/********************/

/**
 * Get info about scripts to use in Table in metdataList component
 *
 * @param {Array} documents
 *  scripts (normalized)
 *
 * @returns {Array}
 * 	Array with info about given scripts
 */
export const formatForTable = (scripts) => {
	const result = [];

	// loop over fields
	scripts.forEach((script, key) => {
		// get last modified time
		const lastModified = script.modified;

		result.push({
			key, // key, useful for handling clicks
			guid: script.guid,
			// name used for sorting
			name: script.name,
			// scriptType of the script
			type: script.script_type,
            // schemaType of the script
			host_name: script.host_name,
			// time as ISO string, used for sorting
			time: lastModified,
			// localized time for display
			last_time_edited: lastModified
				? Helpers.date.localeStringWithMinutes(lastModified)
				: t('general.dash'),
            status: getScriptStatusName(script),
			edit: userCanModifyScript() ? 'edit' : 'view',
			delete: usePermission('Delete', 'Script'),
		});
	});

	return result;
};

/********************/
/*    VALIDATION    */
/********************/

const createErrorObject = (description) => {
	return Helpers.createErrorObject(description);
};

const scriptCodeIsWrong = async (scriptCode) => {
	// check script with an api call
	// bad request is a wrong script, 200 is a good script
	let result = false;

	await useApiAsync(CHECK_COMPILATION, {
		parameters: scriptCode,
	}, true, (error) => {
		// set error for the return value
		result = error.response.data;

		// show debug message in console
		debug('error in checking', {
			error,
		});
	});

	if (result === false) {
		// show debug message in console
		debug('successful checked', {
			result,
		});
	}

	return result;
}

/**
 * Validates given Script and returns errors array
 *
 * @param {Object} script
 *  Script to validate
 *
 * @returns {Array}
 *	array of errors
 */
export const validateScript = async (script) => {
	debug('Validating', { script });

	const scriptError = await scriptCodeIsWrong(script.script);

	let output = [];

	const setError = (description) => {
		output.push(createErrorObject(description));
	};

	if (scriptError) {
		setError(scriptError);
	}

	// check for empty script name
	if (!Validation.stringNotEmpty(script.name)) {
		setError(t('sb.validation.scriptNameEmpty'));
	}
	// check if script name has atleast 2 characters
	else if (!Validation.stringHasAtleastTwoCharacters(script.name)) {
		setError(t('sb.validation.scriptNameIsShort'));
	} else {
		// check if script name without white spacing
		if (!Validation.stringWithoutSpacing(script.name)) {
			setError(t('sb.validation.scriptNameContainsSpace'));
		}
		// get all scripts
		const scripts = await useApiAsync(GET_SCRIPTS);

		if (scripts) {
			const list = [];

			// create list of script names, excluding script that is
			// being validated
			scripts.forEach((scr) => {
				if (scr.guid !== script.guid) list.push(scr.name.toLowerCase());
			});

			// check if script name already exists
			if (Validation.stringExistsInList(script.name.toLowerCase(), list)) {
				setError(
					t('sb.validation.scriptNameNotUnique')
				);
			}
		}
	}
    // check for empty script
    if (!Validation.stringNotEmpty(script.script)) {
		setError(t('sb.validation.scriptIsEmpty'));
	}
    // check for empty script type
	if (Validation.stringNotEmpty(script.script_type)) {
        // get all available script types objects
		const scriptTypes = await useApiAsync(GET_SCRIPT_TYPES);

        // check if script type exist
        if(!Validation.stringExistsInList(script.script_type, scriptTypes)) {
            setError(t('sb.validation.scriptTypeNotExist'));
        }
    }
	// check for empty commit
	if (!Validation.stringNotEmpty(script.commit)) {
		setError(t('sb.validation.scriptCommitIsEmpty'));
	}
	return output;
};