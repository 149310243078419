import getDefaultState from './state';

export const names = {
	SET_CURRENT_ROLE: 'setCurrentRole',
	SET_CURRENT_USER: 'setCurrentUser',
	UNSET_ROLE: 'unsetRole',
	UNSET_USER: 'unsetUser',
	MARK_ROLE_MODIFIED: 'markRoleModified',
	MARK_USER_MODIFIED: 'markUserModified',
	SET_TWO_FACTOR: 'setTwoFactor',
	RESET: 'reset',
};

export default {
	/**
	 * Sets the current role to newly retrieved role
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} role
	 *  Retrieved Role from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_ROLE](state, role) {
		// save role to state
		state.currentRole = role;

		// a newly loaded role is not modified yet
		state.roleModified = false;
	},

	/**
	 * Sets the current user to newly retrieved user
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} user
	 *  Retrieved user from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_USER](state, user) {
		// save user to state
		state.currentUser = user;

		// a newly loaded user is not modified yet
		state.userModified = false;
	},
	/**
	 * Unsets the current role
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.UNSET_ROLE](state) {
		// unset current role in state
		state.currentRole = {};

		// unset modified marker
		state.roleModified = false;
	},
	/**
	 * Unsets the current user
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.UNSET_USER](state) {
		// unset current user in state
		state.currentUser = {};

		// unset modified marker
		state.userModified = false;
	},
	/**
	 * Set two factor status in user
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} status
	 *  twor factor status of a user
	 *
	 * @returns {void}
	 */
	[names.SET_TWO_FACTOR](state, status) {
		// set two factor status in user
		state.currentUser.two_factor = status;
	},
	/**
	 * Marks the current role as modified
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.MARK_ROLE_MODIFIED](state) {
		state.roleModified = true;
	},
	/**
	 * Marks the current user as modified
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.MARK_USER_MODIFIED](state) {
		state.userModified = true;
	},
	/**
	 * Resets module state to default
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @returns {void}
	 */
	[names.RESET](state) {
		Object.assign(state, getDefaultState());
	},
};
