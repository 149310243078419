import { getStoreMutation, getStoreAction, getStoreGetter } from '@assets/scripts/store/config';
import Helpers from '@assets/scripts/helpers';
import i18n from '@assets/i18n';
import { log, debug } from '@assets/scripts/components/notifications';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	GET_RECORD_BY_KEY,
	POST_RECORD,
} from '@modules/DocumentEditor/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

export const names = {
	RESET: 'reset',
	LOAD_AND_SHOW_RECORD: 'loadAndShowRecord',
	CREATE_NEW_RECORD: 'createNewRecord',
	SAVE_CURRENT_RECORD: 'saveCurrentRecord',
	UPDATE_RECORD_LIST: 'updateRecordList',
};

export default {
	[names.RESET]({ commit }) {
		// reset state of store
		commit(getStoreMutation('RESET', 'DE'), null, { root: true });
	},
	/**
	 * Action triggered to load an existing Record
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} conn_guid
	 *  Connection GUID
	 *
	 * @param {String} document_guid
	 * 	GUID of Document to load
	 *
	 * @param {String} keyField
	 *	Name of the Key Field of the document 
	 *
	 * @param {String} keyFieldValue
	 *	Key Field Value of the record we will get
	 *
	 * @returns {void}
	 */
	async [names.LOAD_AND_SHOW_RECORD](
		{ commit },
		{ conn_guid, document_guid, keyField, keyFieldValue }
	) {
		const record = await useApiAsync(GET_RECORD_BY_KEY, {
			keys: {
				connection: conn_guid,
				guid: document_guid,
			},
			parameters: {
				key: keyField,
				value: keyFieldValue
			}
		});

		if (!record) {
			log(t('de.error.loadRecord'), 'danger');
			return;
		}

		// commit mutation in root store
		commit(
			getStoreMutation('SET_CURRENT_RECORD', 'DE'),
			Helpers.cloneObject(record),
			{
				root: true,
			}
		);
	},
	/**
	 * Action triggered to create a new Record
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @returns {void}
	 */
	[names.CREATE_NEW_RECORD]({ commit }) {
		// commit mutation in root store
		commit(
			getStoreMutation('SET_CURRENT_RECORD', 'DE'),
			{},
			{
				root: true,
			}
		);
	},
	[names.UPDATE_RECORD_LIST]({conn_guid, document_guid}) {
		// do nothing
		// only exists so components can subscribe to it
	},
	/**
	 * Action triggered to save a Record
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Object} recordData
	 *  data object of the record
	 *
	 * @returns {void}
	 */
	async [names.SAVE_CURRENT_RECORD]({ commit, dispatch }, record) {
		// get current document from store
		const currentDoc = this.getters[getStoreGetter('CURRENT_DOCUMENT', 'DE')];

		// post flow through api
		const result = await useApiAsync(POST_RECORD, {
			keys: {
				connection: currentDoc.conn_guid,
				guid: currentDoc.guid
			},
			parameters: {
				...record
			}
		});

		if (result !== false) {
			debug('succesfully saved', record);
	
			// show success message
			log(t('de.savedSuccessfully'), 'success');
	
			// close modal
			commit(getStoreMutation('RESET', 'DE'), null, { root: true });
	
			// dispatch action to take needed steps after saving
			dispatch(
				getStoreAction('UPDATE_RECORD_LIST', 'DE'),
				{
					conn_guid: currentDoc.conn_guid || false,
					document_guid: currentDoc.guid || false,
				},
				{ root: true }
			);

		}
	},
};
