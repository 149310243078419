<template>
	<div class="has-padding-top-6">
		<ContentBlock :size="3" :isVCentered="true">
			<VTitle class="has-margin-bottom-3" :size="1">{{
				$t('changePassword.title')
			}}</VTitle>
			<VNotification
				v-if="errorMessage"
				:text="errorMessage"
				:isFullwidth="false"
				:hasClose="false"
				type="danger"
			/>

			<div>
				<VField
					:isFloating="true"
					:label="$t('changePassword.label.currentPassword')"
					:class="{ 'is-active': currentPassword }"
					:isError="currentPasswordError"
					:help="$t('changePassword.currentPasswordHelpText')"
				>
					<VInput
						v-model="currentPassword"
						:placeholder="
							$t('changePassword.label.currentPassword')
						"
						type="password"
						@keypress.enter.prevent="changePassword"
						@keyup="testPasswordWithDebounce"
					/>
				</VField>
				<VField
					:isFloating="true"
					:label="$t('changePassword.label.newPassword')"
					:class="{ 'is-active': newPassword }"
					:isError="newPasswordError || noMatchError"
					:help="$t('changePassword.newPasswordHelpText')"
				>
					<VInput
						v-model="newPassword"
						:placeholder="$t('changePassword.label.newPassword')"
						type="password"
						@keypress.enter.prevent="changePassword"
						@keyup="testPasswordWithDebounce"
					/>
				</VField>
				<VField
					:isFloating="true"
					:label="$t('changePassword.label.confirmPassword')"
					:class="{ 'is-active': confirmPassword }"
					:isError="confirmPasswordError || noMatchError"
				>
					<VInput
						v-model="confirmPassword"
						:placeholder="
							$t('changePassword.label.confirmPassword')
						"
						type="password"
						@keypress.enter.prevent="changePassword"
						@keyup="testPasswordWithDebounce"
					/>
				</VField>
			</div>
			<div class="content-block-card-foot">
				<VButton
					class="button-content-block-footer"
					:text="$t('changePassword.changePasswordButton')"
					icon="chevron-right"
					@click="changePassword"
					:iconOnRight="true"
					:disabled="changePasswordButtonIsDisabled"
				/>
			</div>
		</ContentBlock>
	</div>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { getStoreAction } from '@assets/scripts/store/config';
import Helpers from '@assets/scripts/helpers';

export default {
	name: 'TheProfilepage',
	setup() {
		const { t } = useI18n();
		const store = useStore();

		const state = reactive({
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
			overlayIsActive: true,
			newPasswordError: false,
			noMatchError: false,
			confirmPasswordError: false,
			currentPasswordError: false,
			errorMessage: '',
			testPasswordWithDebounce: Helpers.debounce(() => {
				testPassword();
			}, 500),
		});

		// test password with a strong regex
		const testPassword = (showErrors = true) => {
			// the password must be 8 characters or longer
			// and must contain at least 1 lowercase character
			// and 1 uppercase character,
			// and 1 numeric character and 1 special character.
			const strongRegex = new RegExp(
				'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})'
			);
			// check if newPassword is not empty
			if (state.newPassword.length < 1) {
				state.newPasswordError = showErrors;
				state.errorMessage = showErrors
					? t('changePassword.noNewPassword')
					: '';
				return false;
			}
			// test state.newPassword with regex
			else if (!strongRegex.test(state.newPassword)) {
				state.newPasswordError = showErrors;
				// show error
				state.errorMessage = showErrors
					? t('changePassword.passwordNoMatchRequirements')
					: '';
				return false;
			}
			// check if confirmPassword is not empty
			else if (state.confirmPassword.length < 1) {
				state.confirmPasswordError = showErrors;
				state.errorMessage = showErrors
					? t('changePassword.noConfirmPassword')
					: '';
				return false;
			}
			// check if confirmPassword and newPassword are the same
			else if (
				state.confirmPassword.length > 1 &&
				state.newPassword !== state.confirmPassword
			) {
				state.noMatchError = showErrors;
				state.errorMessage = showErrors
					? t('changePassword.passwordNoMatch')
					: '';
				return false;
			}
			// check if currentPassword is not empty
			else if (state.currentPassword.length < 1) {
				state.currentPasswordError = showErrors;
				state.errorMessage = showErrors
					? t('changePassword.noCurrentPassword')
					: '';
				return false;
			} else {
				state.currentPasswordError = false;
				state.newPasswordError = false;
				state.confirmPasswordError = false;
				state.noMatchError = false;
				state.errorMessage = '';
				return true;
			}
		};

		const changePassword = () => {
			if (testPassword()) {
				store
					.dispatch(getStoreAction('CHANGE_PASSWORD'), {
						currentPassword: state.currentPassword,
						newPassword: state.newPassword,
					})
					.then(() => {
						// success, no further action needed
						state.errorMessage = '';
						state.currentPassword = '';
						state.newPassword = '';
						state.confirmPassword = '';
					})
					.catch((error = false) => {
						// show error in form
						state.errorMessage = error;
					});
			}
		};

		return {
			...toRefs(state),
			changePassword,
			testPassword,
		};
	},
	computed: {
		// button is disabled if
		// it passwords does not pass the test
		changePasswordButtonIsDisabled() {
			return !this.testPassword(false);
		},
	},
};
</script>
