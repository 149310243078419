import Helpers from '@assets/scripts/helpers';
import { roleMeta } from '@modules/UserManagement/endpoints';
import usePermission from '@assets/scripts/composables/usePermission';

/**
 * Returns a newly created role
 *
 * @returns {Object}
 *  New Role
 */
export const createNew = () => {
	return Helpers.obj.create(roleMeta, {
		guid: Helpers.newGuid(),
	});
};

export const prepareRoleForPost = (role, availablePermissions) => {
	// loop over all modules
	for (const module in availablePermissions) {
		// loop over every available permission for the module
		for(const permission in availablePermissions[module].permissions) {
			// check if user has specific permission and that permission has
			// other permissions it implicitly grants
			if(role.permissions[module][permission] && availablePermissions[module].permissions[permission].implicit) {
				// loop over implicit permissions
				availablePermissions[module].permissions[permission].implicit.forEach(implicit => {
					// enable permission for user
					role.permissions[module][implicit] = true;
				})
			}
		}
	}

	return role;
}

/**
 * Constructs a Role Object from a given role
 * as can be used in communication with the Nominow API
 *
 * @param {Object} role
 *  Normalized version of role to construct
 *
 * @returns {Object}
 *  Fully constructed Role Object
 */
export const constructFullRole = (role) => {
	// return fully constructed role
	return Helpers.obj.construct(role, roleMeta);
};

/********************/
/* TABLE FORMATTING */
/********************/

/**
 * Get info about roles to use in Table in RoleList component
 *
 * @param {Array} roles
 *  Array of role objects
 *
 * @returns {Array}
 *  Array of objects per table row
 */
 export const formatForTable = (roles) => {
	const result = [];

	// loop over roles
	roles.forEach((role) => {
		result.push({
			role,
			id: role.guid,
			name: role.name,
			edit: usePermission('Upsert', 'UserManagement')? 'edit' : 'view',
			delete: usePermission('Delete', 'UserManagement'),
		});
	});

	return result;
};