import { getDefaultState } from './state';
import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import {
	userCanModifyComposedDoc,
	userCanExtendComposedDoc,
} from '@modules/ComposedDocument/components/composed-document';

export const names = {
	TOGGLE_CREATE_NEW: 'toggleCreateNewDocument',
	SET_CURRENT_COMPOSED_DOCUMENT: 'setCurrentComposedDocument',
	UNSET_COMPOSED_DOCUMENT: 'unsetComposedDocument',
	SET_CURRENT_CONNECTION: 'setCurrentConnection',
	MARK_MODIFIED: 'markModified',
	SET_PUBLISHED_STATE: 'setPublishedState',
	SET_NAME: 'setName',
	RESET: 'reset',
};

export default {
	[names.TOGGLE_CREATE_NEW](state, status = true) {
		if (state.showCreateNew !== status) state.showCreateNew = status;
	},
	/**
	 * Sets the current composed document to newly retrieved document
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} document
	 *  Retrieved composed document from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_COMPOSED_DOCUMENT](state, composedDocument) {
		// save composed document to state
		state.currentComposedDocument = composedDocument;

		// a newly loaded composed document is not modified yet
		state.modified = false;

		// determine if composed document can be modified
		state.canBeModified = userCanModifyComposedDoc(composedDocument);

		// determine if composed document can be extended
		state.canBeExtended = userCanExtendComposedDoc(composedDocument);
	},
	/**
	 * Unsets the current composed document
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.UNSET_COMPOSED_DOCUMENT](state) {
		// unset current composed document in state
		state.currentComposedDocument = {};

		// unset modified marker
		state.modified = false;
	},
	/**
	 * Sets the current connection to given connection
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} connection
	 *  Retrieved connection from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_CONNECTION](state, connection) {
		// save connection guid to state
		state.currentConnection = connection.conn_guid;
	},
	/**
	 * Marks the current document as modified
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.MARK_MODIFIED](state) {
		state.modified = true;

		// every change to the composed document means that the composed document should be validated again
		this.commit(
			getStoreMutation('SET_IS_VALID'), false, { root: true }
		);
	},
	/**
	 * Updates the published state of the current composed document
	 *
	 * @param {Object} currentComposedDocument
	 *	Currently active composed document
	 *
	 * @param {Boolean} status
	 *  Boolean indicating whether current composed document is
	 *  published
	 */
	[names.SET_PUBLISHED_STATE]({ currentComposedDocument }, status = false) {
		currentComposedDocument.is_published = status;
	},
	/**
	 * Updates the name of the currently active composed document
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {String} newName
	 *  Name to set for composed document
	 *
	 * @returns {void}
	 */
	[names.SET_NAME]({ currentComposedDocument }, newName) {
		// check if currently in edit mode
		if (!this.getters[getStoreGetter('CAN_BE_EDITED', 'CD')]) return;

		// set name on composed document
		currentComposedDocument.name = newName;

		// mark composed document as modified
		this.commit(
			getStoreMutation('MARK_MODIFIED', 'CD'), null, { root: true }
		);
	},
	/**
	 * Resets module state to default
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @returns {void}
	 */
	[names.RESET](state) {
		Object.assign(state, getDefaultState());
	},
};
