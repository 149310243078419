import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

export default {
    label: t('db.permissions.label'),
    info: t('db.permissions.info'),
    module: 'DocumentBuilder',
    permissions: {
        'Read': {
            label: t('db.permissions.readDocument.label'),
            info: t('db.permissions.readDocument.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Upsert': {
            implicit: [
                'Read',
                'Publish',
            ],
            label: t('db.permissions.editDocument.label'),
            info: t('db.permissions.editDocument.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Delete': {
            implicit: [
                'Read',
            ],
            label: t('db.permissions.deleteDocumentDraft.label'),
            info: t('db.permissions.deleteDocumentDraft.info'),
            availableInRole: true
        },
        'Publish': {
            // this permission is only for the backend to check if user can publish document 
            // in the frontend we implicit this permission with Upsert permission and we only show one checkbox for both Upsert and publish
            // in this case backend will get a separate publish and upsert permissions
            availableInRole: false // if true will be visible in role details as a checkbox
        },
    }
};
