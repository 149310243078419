import getDefaultState from './state';
import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import {
	userCanModifyDoc,
	userCanExtendDoc,
	userCanModifyDocName,
} from '@modules/DocumentBuilder/components/connection-document';

export const names = {
	TOGGLE_CREATE_NEW: 'toggleCreateNewDocument',
	SET_CURRENT_DOCUMENT: 'setCurrentDocument',
	UNSET_DOCUMENT: 'unsetCurrentDocument',
	SET_CURRENT_CONNECTION: 'setCurrentConnection',
	MARK_MODIFIED: 'markModified',
	SET_PUBLISHED_STATE: 'setPublishedState',
	SAVE_FIELD: 'saveField',
	SET_NAME: 'setName',
	RESET: 'reset',
};

export default {
	[names.TOGGLE_CREATE_NEW](state, status = true) {
		if (state.showCreateNew !== status) state.showCreateNew = status;
	},
	/**
	 * Sets the current document to newly retrieved document
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} document
	 *  Retrieved document from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_DOCUMENT](state, document) {
		// save document to state
		state.currentDocument = document;

		// a newly loaded document is not modified yet
		state.modified = false;

		// determine if document can be modified
		state.canBeModified = userCanModifyDoc(document);

		// determine if document name can be modified
		state.canModifyDocName = userCanModifyDocName(document);

		// determine if document can be extended
		state.canBeExtended = userCanExtendDoc(document);
	},
	/**
	 * Unsets the current document
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.UNSET_DOCUMENT](state) {
		// unset current document in state
		state.currentDocument = {};

		// unset modified marker
		state.modified = false;
	},
	/**
	 * Sets the current connection to given connection
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} connection
	 *  Retrieved connection from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_CONNECTION](state, connection) {
		// save connection guid to state
		state.currentConnection = connection.conn_guid;
	},
	/**
	 * Marks the current document as modified
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.MARK_MODIFIED](state) {
		state.modified = true;

		// every change to the document means that the document should be validated again
		this.commit(
			getStoreMutation('SET_IS_VALID'), false, { root: true }
		);
	},
	/**
	 * Updates the published state of the current document
	 *
	 * @param {Object} currentDocument
	 *	Currently active document
	 *
	 * @param {Boolean} status
	 *  Boolean indicating whether current document is
	 *  published
	 */
	[names.SET_PUBLISHED_STATE]({ currentDocument }, status = false) {
		currentDocument.is_published = status;
	},
	/**
	 * Updates a Document field with a given key of
	 * the currently viewed/edited document, or adds
	 * a new field to it
	 *
	 * @param {Object} state
	 *  Ref to module state
	 *
	 * @param {Object} param
	 *  Parameters, not destructured since the object
	 *  is used to signal success/failure back to
	 *  caller function
	 *
	 *   @param {Object} field
	 *    Field object to save
	 *   @param {Integer} key
	 *    Key of field to update in field list, or false
	 *    to trigger insert of new field
	 *   @param {Boolean} result
	 *    Indicates success/failure of update
	 *
	 *  Start field to add to current block
	 */
	[names.SAVE_FIELD](state, param) {
		const { field, key } = param;

		const document = this.getters[getStoreGetter('CURRENT_DOCUMENT', 'DB')];

		if (!document) {
			param.result = false;
			return;
		}

		if (key === false) {
			// add new field
			document.fields.push(field);
			// mark insert as success
			param.result = true;
		} else if (typeof document.fields[key] !== 'undefined') {
			// replace field with new field
			document.fields[key] = field;
			// mark update as success
			param.result = true;
		} else {
			// mark update as failed
			param.result = false;
		}
	},
	/**
	 * Updates the name of the currently active document
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {String} newName
	 *  Name to set for document
	 *
	 * @returns {void}
	 */
	[names.SET_NAME]({ currentDocument }, newName) {
		// check if currently in edit mode
		if (!this.getters[getStoreGetter('CAN_BE_EDITED', 'DB')]) return;

		// set name on document
		currentDocument.name = newName;

		// mark document as modified
		this.commit(
			getStoreMutation('MARK_MODIFIED', 'DB'), null, { root: true }
		);
	},
	/**
	 * Resets module state to default
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @returns {void}
	 */
	[names.RESET](state) {
		Object.assign(state, getDefaultState());
	},
};
