<template>
	<div class="option-wrapper" :class="wrapperClasses">
		<label
			:class="[classType, { 'is-button': isButton }]"
			:disabled="$attrs.disabled || null"
		>
			<input v-bind="$attrs" v-model="model" class="is-hidden" />

			<span class="checkmark" v-if="!isButton"></span>

			<span v-if="label" class="label-text">
				{{ label }}
			</span>
		</label>
	</div>
</template>
<script>
import { computed } from '@vue/reactivity';

/**
 * Component to both render checkboxes and radio options depending on `type` given to the declaration.
 */
export default {
	name: 'VOption',
	inheritAttrs: false,
	props: {
		/**
		 * Value to use for the form element
		 */
		modelValue: {
			type: [Array, String, Boolean],
			required: true,
		},
		/**
		 * Label text for checkbox and radio button.
		 * Based on Bulma docs the input needs to be setup as a child of the label: https://bulma.io/documentation/form/checkbox/
		 */
		label: {
			type: String,
			default: null,
		},
		/**
		 * Styles option as a button
		 */
		isButton: {
			type: Boolean,
			default: false,
		},
		/**
		 * Additional classes for the wrapper
		 */
		wrapperClasses: {
			type: String,
			default: '',
		},
	},
	setup(props, { emit, attrs }) {
		const classType = attrs.type == 'checkbox' ? 'checkbox' : 'radio';

		const model = computed({
			get() {
				return props.modelValue;
			},
			set(value) {
				emit('update:modelValue', value);
			},
		});

		return { model, classType };
	},
};
</script>
