import {
	defaultValueMeta,
	validationMeta,
} from '@assets/scripts/api/config';

export const methodFieldMeta = {
	name: {
		name: 'Name',
		type: 'string',
		default: '',
	},
	type: {
		name: 'Type',
		type: 'string',
		default: '',
	},
	validation: validationMeta,
	default: defaultValueMeta,
};

// definition of meta data for Connection Method
export const methodMeta = {
	name: {
		name: 'MethodName',
		default: '',
	},
	guid: {
		name: 'MethodGuid',
		default: '',
	},
	description: {
		name: 'Description',
		default: '',
	},
	type: {
		name: 'MethodType',
		default: 'Stored Procedure',
	},
	conn_guid: {
		name: 'FlowConnectionGuid',
		default: '',
	},
	created: {
		name: 'CreatedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	modified: {
		name: 'LastModifiedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	is_published: {
		name: 'IsActive',
		type: 'boolean',
		default: false,
	},
	is_read: {
		name: 'IsReadAction',
		type: 'boolean',
		default: false,
	},
	is_sp: {
		name: 'IsSp',
		type: 'boolean',
		default: true,
	},
	full_output: {
		name: 'FullResultOutput',
		type: 'boolean',
		default: false,
	},	
	script: {
		name: 'Query',
		default: '',
	},
	input: {
		name: 'Input',
		type: 'array',
		default: [],
		children: methodFieldMeta,
	},
	output: {
		name: 'Output',
		type: 'array',
		default: [],
		children: methodFieldMeta,
	},
};

// File exports all available Endpoints

const apiBase = 'UIServices/SPActionDocument';

// Get Methods for specific Connection
export const GET_METHODS = {
	path: 'GetConnectionMethods/{connection}',
	realm: apiBase,
	meta: methodMeta,
};

// Get Method for specific Connection
export const GET_METHOD = {
	path: 'GetConnectionMethod/{connection}/{guid}',
	realm: apiBase,
	meta: methodMeta,
};

// Save or publish Connection Method
export const POST_METHOD = {
	path: 'PostConnectionMethod/{connection}/{guid}',
	method: 'post',
	realm: apiBase,
	meta: methodMeta,
};

// Delete Connection Method
export const DELETE_METHOD = {
	path: 'DeleteConnectionMethod/{connection}/{guid}',
	method: 'delete',
	realm: apiBase,
};

// Get Stored Procedures for specific Connection
export const GET_SPS = {
	path: 'GetSPS/{connection}',
	realm: apiBase,
	parameters: {
		exclude: 'excludeDocumentedSPs'
	}
};

// Get Stored Procedure for specific Connection
export const GET_SP_PARAMETERS = {
	path: 'GetSPParameters/{connection}/{name}',
	realm: apiBase,
	meta: methodFieldMeta,
};