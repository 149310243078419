<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="$t('cd.documentDetails.previewTitle')"
		:hasJsonButton="false"
		:hasFullwidthBody="false"
		:closeOnBackgroundClick="true"
		:onClose="closeDrawer"
	>
		<pre><code>{{json}}</code></pre>
	</DrawerItem>
</template>

<script>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import Helpers from '@assets/scripts/helpers';
import { prepareComposedDocumentForPost } from '@modules/ComposedDocument/components/composed-document';
import { composedDocumentMeta } from '@modules/ComposedDocument/endpoints';
import {
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';

export default {
	name: 'DocumentJsonPreviewDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
    setup: function () {
		const store = useStore();
		const { t } = useI18n();

		const state = {
			/**
			 * Gets currently active Composed Document
			 */
			document: store.getters[getStoreGetter('CURRENT_COMPOSED_DOCUMENT', 'CD')],
			json: '',
		};
		// construct document to be BE json and delete all empty childs using prepareComposedDocumentForPost function
		state.document = Helpers.obj.construct(prepareComposedDocumentForPost(state.document), composedDocumentMeta)

		// stringfy the document to be used in VTextaria component
		state.json = JSON.stringify(state.document, null, 4);

		return {
			...state
		};
	},
    methods: {
		closeDrawer: function () {
			this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
		},
    }
};
</script>
