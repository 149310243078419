// file exports store module for 'FlowBuilder' by
// combining the state, getters, mutations and actions
import getDefaultState from './state';
import actions, { names as actionNames } from './actions';
import getters, { names as getterNames } from './getters';
import mutations, { names as mutationNames } from './mutations';

export default {
	// namespaced means that all getters, mutations
	// and actions will be prefixed with the name
	// of the module and a '/'
	namespaced: true,
	state: getDefaultState(),
	getters,
	mutations,
	actions,
};

export const storeNames = {
	actions: actionNames,
	getters: getterNames,
	mutations: mutationNames,
};