<template>
	<!-- tabindex in the first div with class modal is needed to make modal focusable and close with escape will work -->
	<focus-trap :active="isActive && useFocusTrap" :initial-focus="() => $refs.modal" :fallbackFocus="() => $refs.modal">
		<div
			class="modal"
			@keydown.esc="closeOnKeydown()"
			tabindex="0"
			ref="modal"
			:class="[
				{
					'is-active': isActive,
					'is-large': isLarge,
					'is-v-centered': isVCentered,
					'has-fullwidth-body': hasFullwidthBody,
					'is-fullheight': isFullHeight,
				},
				align,
			]"
		>
			<div
				@click="close(closeOnBackgroundClick)"
				class="modal-background"
				v-if="hasBackground"
			></div>
			<div
				class="modal-card"
				:class="{ 'modal-card-has-footer-button': modalHasSharpCorner }"
			>
				<div class="modal-card-head">
					<div class="modal-card-title">
						<div class="modal-card-title-content">
							<VTitle :size="size" v-if="title">
								<VIcon v-if="icon" :name="icon" />
								<span>
									{{ title }}
								</span>
							</VTitle>
						</div>
						<div class="buttons">
							<VButton
								v-if="hasJsonButton"
								class=""
								@click="(ev) => $emit('json', ev)"
								:isRounded="true"
								variant="is-secondary"
								:text="$t('baseOverlay.json')"
							/>
							<VButton
								class="close"
								v-if="hasCloseButton"
								@click="close(true)"
								:isTool="true"
								:isRounded="true"
								variant="is-secondary"
								size="is-medium"
								icon="cross"
							/>
						</div>
					</div>
					<div v-if="$slots.header" class="modal-card-head-content">
						<!-- @slot This slot adds content to the header part of the overlay. It will be placed below the buttons and title. It is fixed when scrolling -->
						<slot name="header"></slot>
					</div>
				</div>
				<div
					class="modal-card-body"
					:class="{ 'has-scrollable-content': hasScrollableContent }"
				>
					<!-- @slot This slot adds content to the body part of the overlay. It has scrollable content -->
					<slot />
				</div>
				<div v-if="$slots.footer" class="modal-card-foot">
					<!-- @slot This slot adds content to the footer part of the overlay. By default the footer is fixed to the bottom.
					Use the `button-modal-footer` class on a button to align it at the end of the modal.
					If this class is used the border-bottom-right-radius of the modal-card will be 0
					-->
					<slot name="footer"></slot>
				</div>
			</div>
		</div>
	</focus-trap>
</template>
<script>
import { computed } from '@vue/reactivity';
export default {
	name: 'VOverlay',
	props: {
		/**
		 * If true show the overlay component.
		 * The value will be based off of the v-model on the component
		 */
		modelValue: {
			type: Boolean,
			required: true,
		},
		/**
		 * Option which side to align the modal on the screen: centered, left, or right.
		 */
		align: {
			type: String,
			default: '',
			validator(value) {
				return ['', 'is-left', 'is-right'].includes(value);
			},
		},
		/**
		 * Option to center a modal vertically. Set to true by default. Reason for not
		 * centering vertically might be a modal with a data table that can be fitlered
		 * by user. That way the height and therefor positioning of the modal would
		 * constantly change
		 */
		isVCentered: {
			type: Boolean,
			default: true,
		},
		/**
		 * If true, an extra button will be visible next to the close button.
		 * This button has a text 'json' and emits an event called 'json' when clicked.
		 */
		hasJsonButton: {
			type: Boolean,
			default: false,
		},
		/**
		 * If true the modal gets a max-width based on the $modal-content-width-large defined in the config-bulma.scss
		 * If false the size will be the default max-width of $modal-content-width-small.
		 */
		isLarge: {
			type: Boolean,
			default: false,
		},
		/**
		 * If true, the body of the modal has no padding left and right.
		 * Useful for modals with 100% wide content
		 */
		hasFullwidthBody: {
			type: Boolean,
			default: false,
		},
		/**
		 * Text for overlay title
		 */
		title: {
			type: String,
			default: null,
		},
		/**
		 * Size of the title in the overlay
		 */
		size: {
			type: Number,
			default: 1,
		},
		/**
		 * Icon next to overlay title
		 */
		icon: {
			type: String,
			default: null,
		},
		/**
		 * If true, show darkened background behind the overlay when the overlay is open
		 */
		hasBackground: {
			type: Boolean,
			default: true,
		},
		/**
		 * If true, show the close button in the overlay to be able to close it.
		 */
		hasCloseButton: {
			type: Boolean,
			default: true,
		},
		/**
		 * If true, the modal card will have a sharp right bottom corner
		 */
		modalHasSharpCorner: {
			type: Boolean,
			default: false,
		},
		/**
		 * When true the modal closes when clicked on the background overlay
		 */
		closeOnBackgroundClick: {
			type: Boolean,
			default: true,
		},
		/**
		 * When true the modal body gets an additional class
		 */
		hasScrollableContent: {
			type: Boolean,
			default: false,
		},
		/**
		 * When true the modal body is set to max height
		 */
		isFullHeight: {
			type: Boolean,
			default: false,
		},
		/**
		 * When true the focus will be trapped in the overlay component
		 */
		useFocusTrap: {
			type: Boolean,
			default: true,
		},
		closeWithEscape: {
			type: Boolean,
			default: true,
		}
	},
	setup(props, { emit }) {
		let isActive = computed({
			get() {
				return props.modelValue;
			},
			set(_value) {
				emit('update:modelValue', _value);
			},
		});

		const close = (isClosable) => {
			if (!isClosable) {
				return;
			}
			isActive = false;
			emit('update:modelValue', isActive);
		};

		return { isActive, close, props};
	},
	methods: {		
		closeOnKeydown: function(){
			this.close(this.closeWithEscape && this.isActive)
   		},
	},
};
</script>
