<template>
	<div class="tooltip" ref="tooltip">
		<VButton
			class="tooltip-button"
			icon="questionmark"
			size="is-small"
			:isTool="true"
			:isButton="false"
			variant="is-secondary"
			@mouseenter="activateTooltip"
			@mouseleave="deactivateTooltip"
		/>

		<div
			class="tooltip-content"
			ref="content"
			:class="{ 'is-active': isActive, 'is-moved': isMoved }"
		>
			<template v-if="text">{{ text }}</template>
			<!-- @slot Slot can be used to add custom HTML in the tooltip content -->
			<slot v-else />
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';

export default {
	name: 'VTooltip',
	props: {
		/**
		 * Text visible in the tooltip-content.
		 * The text prop has priority over the slot content.
		 * Make sure the text prop is empty if you want to use the slot.
		 */
		text: {
			type: String,
			default: '',
		},
	},
	data: function () {
		return {
			isActive: false,
			isMoved: false,
			appLayer: false,
		};
	},
	setup() {
		const tooltip = ref(null);
		const content = ref(null);
		return { tooltip, content };
	},
	mounted: function () {
		// find closest fullscreen layer to place tooltip
		// content in
		this.appLayer = this.tooltip.closest('.app-layer');
	},
	unmounted: function () {
		this.deactivateTooltip();
	},
	methods: {
		activateTooltip() {
			if (this.isActive || !this.appLayer) return;

			// get position of tooltip content
			const pos = this.content.getBoundingClientRect();

			// remove tooltip content from position in DOM
			const content = this.tooltip.removeChild(this.content);

			// add tooltip content to correct layer
			this.appLayer.appendChild(content);

			// set some positioning styles
			this.content.style.top = `${pos.top}px`;
			this.content.style.left = `${pos.left}px`;
			this.content.style.height = `${pos.height}px`;

			// mark tooltip as active and moved
			this.isMoved = true;
			this.isActive = true;
		},
		deactivateTooltip() {
			if (!this.isActive || !this.appLayer) return;

			// remove tooltip content from higher layer
			const content = this.appLayer.removeChild(this.content);

			// place tooltip content back at original position in DOM
			this.tooltip.appendChild(content);

			// unset positioning styles
			this.content.style.top = '';
			this.content.style.left = '';
			this.content.style.height = '';

			// mark tooltip as inactive and unmoved
			this.isMoved = false;
			this.isActive = false;
		},
	},
};
</script>
