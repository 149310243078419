// File exports all available Endpoints
const apiBase = 'UIServices/DocumentEditor';

// Connection Document Records
export const GET_RECORDS = {
	path: 'GetConnectionDocument/{connection}/{guid}/records',
	realm: apiBase,
};

// Connection Document Record
export const GET_RECORD_BY_KEY = {
	path: 'GetConnectionDocument/{connection}/{guid}/record',
	realm: apiBase,
	parameters: {
		key: 'keyField',
		value: 'keyValue',
	}
};

// post record
export const POST_RECORD = {
	path: 'PostRecord/{connection}/{guid}',
	method: 'post',
	realm: apiBase,
};