import { getStoreMutation } from '@assets/scripts/store/config';
import { store } from '@assets/scripts/components/store-proxy';
import { IS_DEV } from '@assets/scripts/helpers';

/**
 * Helper function to log an event and optionally
 * show a notification about it to the user
 *
 * @param {String} text
 *  Text of message
 *
 * @param {String} type
 *  Type of message
 *
 * @param {Any} vars
 *  Additional info to output to console
 *
 * @param {Boolean} showAsMessage
 *  Indicator whether to show the message as
 *  notification to the user
 *
 * @returns {void}
 */
export const log = (text, type = 'info', vars = null, showAsMessage = true) => {
	// In DEV mode all messages and in PROD mode only
	// non-success messages will be logged to console
	if (IS_DEV || type !== 'success') {
		let logger = console.log;

		switch (type) {
			case 'danger':
				logger = console.error;
				break;
			case 'warning':
				logger = console.warn;
				break;
		}

		if (vars !== null) {
			logger(text, vars);
		} else {
			logger(text);
		}
	}

	// show message as notification if requested
	if (showAsMessage) {
		// use store to show notifications if
		// initialized correctly
		if (store) {
			store.commit(getStoreMutation('SHOW_NOTIFICATION'), {
				type,
				text,
			});
		} else if (type !== 'success') {
			// use alert if store is not initialized and
			// message is not of type success
			window.alert(text);
		}
	}
};

export const debug = (text, vars = null, type = 'info') => {
	log(text, type, vars, false);
};
