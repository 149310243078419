import { useApi } from './useApi';
import { ref, watch } from 'vue';
import { GET_AVAILABLE_MODULES } from '@assets/scripts/api/config';

/**
 * Composable for available modules to use in Vue Components, and other places
 * Docs about composables: https://vuejs.org/guide/reusability/composables.html#composables
 *
 * Available modules are fetched on request, and served within the `modules` array
 */

const modules = ref([]);

export const fetchAvailableModules = () => {
	// fetch available modules
	const { data } = useApi(GET_AVAILABLE_MODULES);

	// returned data object is reactive, so watch for update
	// then add result to modules array
	watch(data, () => {
		modules.value = data.value;
	});
};

export default modules;