<template>
	<VOverlay
		class="login-overlay"
		:hasBackground="false"
		:hasJsonButton="false"
		:hasCloseButton="false"
		:closeOnBackgroundClick="false"
		v-model="overlayIsActive"
		:modalHasSharpCorner="true"
		:useFocusTrap="false"
		:closeWithEscape="false"
	>			
		<template #header>
			<img class="has-margin-bottom-4" src="@assets/images/Logo.svg" />
		</template>

		<VTitle class="has-margin-bottom-3" :size="1">{{
			$t('requestNewPassword.title')
		}}</VTitle>

		<VNotification
			v-if="errorMessage"
			:text="errorMessage"
			:isFullwidth="false"
			:hasClose="false"
			type="danger"
		/>

		<div class="login-form-wrapper">
			<VField
				:isFloating="true"
				:label="$t('requestNewPassword.label.email')"
				:class="{ 'is-active': email }"
				:isError="emailError"
				:help="$t('requestNewPassword.helpText')"
			>
				<VInput
					v-model="email"
					:placeholder="$t('requestNewPassword.label.email')"
					type="email"
					@keypress.enter.prevent="askForNewPassword"
				/>
			</VField>
		</div>
		<template #footer>
			<VButton
				class="button-modal-footer"
				:text="$t('requestNewPassword.button')"
				icon="chevron-right"
				@click="askForNewPassword"
				:iconOnRight="true"
				:disabled="buttonIsDisabled"
			/>
		</template>
	</VOverlay>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { getStoreAction } from '@assets/scripts/store/config';
import Helpers from '@assets/scripts/helpers';

export default {
	name: 'AskForNewPassword',
	setup() {
		const { t } = useI18n();
		const store = useStore();

		const state = reactive({
			email: '',
			overlayIsActive: true,
			emailError: false,
			errorMessage: '',
		});

		const askForNewPassword = () => {
			if (state.email.length < 1) {
				state.emailError = true;
				state.errorMessage = t('requestNewPassword.noEmail');
			} else if(!Helpers.validateEmail(state.email)) {
				state.emailError = true;
				state.errorMessage = t('requestNewPassword.noValidEmail');
			} else {
				state.errorMessage = '';

				store
					.dispatch(getStoreAction('ASK_FOR_NEW_PASSWORD'), {
						email: state.email,
					})
					.then(() => {
						// success, no further action needed
						state.errorMessage = '';
					})
					.catch((error = false) => {
						// show error in form
						state.errorMessage = error;
					});
			}
		};

		return {
			...toRefs(state),
			askForNewPassword,
		};
	},
	computed: {
		buttonIsDisabled() {
			return !(!!this.email && this.email.length > 0);
		},
	},
};
</script>
