import Helpers from '@assets/scripts/helpers';

const state = {
	editMode: true,
	testMode: false,
	modified: false,
	canBeModified: false,
	currentFlow: {},
};

export default () => Helpers.cloneObject(state);
