<template>
    <VField
        icon="search"
        class="has-margin-top-auto has-margin-left-auto"
		:btnOnRight="true"
    >
		<VInput
			v-model="value"
			:placeholder="$t('general.search')"
			type="text"
			@keydown.esc="clearSearch"
		/>
		<VButton 
			v-show="value.length > 0"
			:is-tool="true"
			:is-rounded="true"
			text=""
			:title="$t('general.clearSearch')"
			classes="search-clear-button"
			size="is-large"
			icon="cross"
			@clicked="clearSearch"
		/>
    </VField>
</template>

<script>
import { computed } from '@vue/reactivity';
import Helpers from '@assets/scripts/helpers';

export default {
	name: 'VSearch',
	props: {
		/**
		 * Optional value to use for input
		 */
		modelValue: {
			type: [String, Number],
			required: true,
		},
	},
	setup(props, { emit }) {
        const debounceEmit = Helpers.debounce((value) => {
            emit('update:modelValue', value);
        }, 200);

		const value = computed({
			get() {
				return props.modelValue;
			},
			set(_value) {
                debounceEmit(_value);
			},
		});

		return {
			value,
		};
	},
	methods: {
		clearSearch: function () {
			this.value = ''
		},
	}
}
</script>
