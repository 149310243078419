<template>
	<VOverlay
		class="is-drawer"
		:modelValue="true"
		:style="{ marginRight: offsetStyle }"
		closeOnBackgroundClick
		:isVCentered="false"
		:size="2"
		:useFocusTrap="!confirmationActive"
		@update:modelValue="drawerClosed"
	>
		<!-- @slot This slot adds content to the header part of the overlay. By default it will be placed below the close button and is fixed to the top -->
		<template v-if="$slots.header" #header>
			<slot name="header"></slot>
		</template>

		<!-- @slot content shown in the modal. The body will have by default an overflow-y auto for the content. -->
		<slot />

		<!-- @slot This slot adds content to the footer part of the overlay. By default the footer is fixed to the bottom -->
		<template v-if="$slots.footer" #footer>
			<slot name="footer"></slot>
		</template>
	</VOverlay>
</template>
<script>
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';
import { mapGetters } from 'vuex';
import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';

export default {
	name: 'DrawerItem',
	/**
	 * Props get automatically binded to the root of the component.
	 * So the Overlay props are able to be used without needing to be specified.
	 */
	props: {
		/**
		 * Index of the drawerItem.
		 * This prop is needed to know if the drawer should be open or closed.
		 * It is also used to calculate the offset of the drawer when another one is opened on top.
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		/**
		 * Identifier of this specific drawer. Used when closing drawer.
		 */
		id: {
			type: String,
			required: true,
		},
		/**
		 * When true the drawer closes when clicked on the background overlay
		 */
		closeOnBackgroundClick: {
			type: Boolean,
			default: false,
		},
		onClose: {
			type: Function,
			required: false,
		},
	},
	setup(props) {
		const store = useStore();

		const { tablet } = store.getters[getStoreGetter('SCREEN_SIZES')];

		// Get offset based on screen size
		const getBaseOffset = () => {
			const screenSize = window.innerWidth;

			if (screenSize <= tablet) {
				return 3; // 48px based on $spacing-5
			}

			return 6; // 96px based on $spacing-7
		};

		// calculate the offset without knowing the amount of DrawerItem components used.
		const calculateOffset = () => {
			const baseOffset = getBaseOffset();
			const totalDrawers = store.getters[getStoreGetter('DRAWER_COUNT')];
			const { drawerIndex } = props;

			/**
			 * Calculate the offset based on the total count of active drawers.
			 *
			 * Because the active drawer does not need an offset we subtract the drawerIndex from the activeDrawer
			 * to remove the offset.
			 */
			const offset = baseOffset * (totalDrawers - drawerIndex);

			// Apply styling only if greater than 0.
			if (offset < 0) {
				return null;
			}

			return offset + 'rem';
		};

		return {
			props,
			offsetStyle: computed(() => calculateOffset()),
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether the confirmation
			 * popup should be displayed
			 */
			confirmationActive: getStoreGetter('CONFIRMATION_ACTIVE'),
		}),
	},
	methods: {
		drawerClosed: function () {
			if (this.onClose) {
				this.onClose();
			} else {
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			}
		},
	},
};
</script>
