<template>
	<div
		class="app-layer has-background"
		:class="{ 'is-active': drawers.length > 0 }"
	>
		<Component
			v-for="(drawer, index) in drawers"
			:key="drawer.key"
			:id="drawer.key"
			:config="drawer.data || null"
			:drawerIndex="index + 1"
			:is="getDrawerName(drawer.type)"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreGetter } from '@assets/scripts/store/config';
import modules from '@modules/drawers';

const drawers = {};

// loop over modules
for (const module in modules) {
	// add module drawers to available drawers
	Object.assign(drawers, modules[module]);
}

export default {
	name: 'Drawers',
	components: {
		...drawers,
	},
	computed: {
		...mapGetters({
			drawers: getStoreGetter('DRAWERS'),
		}),
	},
	methods: {
		getDrawerName: function (name) {
			return name + 'Drawer';
		},
	},
};
</script>
