import Helpers from '@assets/scripts/helpers';

const state = {
	newBlockOptions: {},
	blocks: [],
	startId: false,
	resultId: false,
	xFlowBlockStart: 0,
	modified: false,
	blockRef: false,
	currentBlock: {},
};

export default () => Helpers.cloneObject(state);
