<template>
	<span class="icon" :class="['icon--' + name, classes]"></span>
</template>

<script>
export default {
	name: 'VIcon',
	props: {
		/**
		 * Name of icon to use
		 */
		name: {
			type: String,
			required: true,
		},
		/**
		 * Additional classes to add to the component
		 */
		classes: {
			type: String,
		},
	},
};
</script>
