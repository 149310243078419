import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

export default {
    label: t('de.permissions.label'),
    info: t('de.permissions.info'),
    module: 'DocumentEditor',
    permissions: {
        'Read': {
            label: t('de.permissions.readRecord.label'),
            info: t('de.permissions.readRecord.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Upsert': {
            implicit: [
                'Read',
            ],
            label: t('de.permissions.editRecord.label'),
            info: t('de.permissions.editRecord.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
    }
};
