import getDefaultState from './state';

export const names = {
	TOGGLE_CREATE_NEW: 'toggleCreateNewFlow',
	RESET: 'reset',
};

export default {
	[names.TOGGLE_CREATE_NEW](state, status = true) {
		if (state.showCreateNew !== status) state.showCreateNew = status;
	},
	/**
	 * Resets module state to default
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @returns {void}
	 */
	[names.RESET](state) {
		Object.assign(state, getDefaultState());
	},
};
