<template>
	<div
		class="validation-container"
		:class="{ 'is-hidden': errorsListCollapsed }"
	>
		<div class="validation-header">
			<VButton
				icon="clear"
				:title="$t('appOptions.buttons.clearValidation')"
				:disabled="!editMode"
				classes="clear-validation-button"
				:is-tool="true"
				@clicked.prevent.stop="clearValidation"
			/>
			<VButton
				icon="refresh"
				:title="$t('appOptions.buttons.refreshValidation')"
				:is-tool="true"
				:icon-on-right="true"
				:disabled="!editMode"
				classes="error-list-refresh-button"
				@clicked.prevent.stop="refreshValidation"
			/>
		</div>
		<div class="validation">
			<div
				class="validation-state-container"
				:class="{
					'has-errors': errorsCount > 0 && !validationInprogress,
				}"
			>
				<template v-if="validationInprogress">
					<p>
						{{
							$t(
								'appOptions.notifications.validationInProgressNotification'
							)
						}}
					</p>
				</template>
				<template v-else-if="isValid">
					<VIcon
						name="check"
						class="validation-state-icon icon-valid"
					/>
					<p>
						{{ $t('appOptions.notifications.isValidNotification') }}
					</p>
				</template>
				<template v-else-if="errorsCount > 0">
					<ErrorsList
						:isClickable="errorListItemIsClickable"
						:errors="errors"
					/>
				</template>
				<template v-else>
					<VIcon
						name="warning"
						class="validation-state-icon icon-warning"
					/>
					<p>
						{{
							$t(
								'appOptions.notifications.requiresValidationNotification'
							)
						}}
					</p>
					<VButton
						icon="chevron-right"
						:title="$t('appOptions.buttons.validate')"
						:text="$t('appOptions.buttons.validate')"
						:icon-on-right="true"
						:disabled="!editMode"
						classes="needs-validation-button"
						@clicked.prevent.stop="refreshValidation"
					/>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreGetter, getStoreAction } from '@assets/scripts/store/config';

export default {
	name: 'Validation',
	props: {
		editMode: {
			type: Boolean,
			default: false,
		},
		refreshValidationFn: {
			type: Function,
		},
		errorListItemIsClickable: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		...mapGetters({
			/**
			 * Get all errors
			 */
			errors: getStoreGetter('VALIDATION_ERRORS'),
			/**
			 * Get errorsListCollapsed from the store
			 */
			errorsListCollapsed: getStoreGetter('ERRORS_LIST_COLLAPSED'),
			/**
			 * Get valid state
			 */
			isValid: getStoreGetter('IS_VALID'),
			/**
			 * Get validation in progress state
			 */
			validationInprogress: getStoreGetter('VALIDATION_IN_PROGRESS'),
		}),
		// return amount of errors
		errorsCount: function () {
			return this.errors.length;
		},
	},
	methods: {
		refreshValidation: function () {
			// refresh validattion
			this.refreshValidationFn();
		},
		clearValidation: function () {
			// clear errors array
			this.$store.dispatch(getStoreAction('CLEAR_VALIDATION'));
		},
	},
};
</script>
