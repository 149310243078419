<template>
	<span class="loader-animation" :class="{ 'is-active': active }"></span>
</template>

<script>
export default {
	name: 'VLoader',
	props: {
		/**
		 * Sets loader to active/visible
		 */
		active: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
