/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
export default {
	pageTitle: 'Document Editor',
	savedSuccessfully: 'Your record has been successfully saved',
	documentList: {
		title: 'Document Editor',
	},
	recList: {
		recTypes: 'Select list document',
		addRecord: 'Add record',
		table: {
			noResults: 'No Records found',
		},
		delete: {
			confirm: {
				title: 'Delete this record?',
				body: 'Are you sure you want to delete this record?',
				confirmButtonText: 'Delete',
			},
		},
	},
	recDetails: {
		title: '{name} Record',
		createNew: 'Create New {fullName}',
		close: {
			confirm: {
				title: 'Go back to record overview?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you close this overlay.',
				confirmButtonText: 'Ok',
			},
		},
	},
	error: {
		loadRecord: 'Record was not found',
	},
	permissions: {
		label: 'Document editor',
		info: 'Permissions connected to the Document editor',
		readRecord: {
			label: 'Read record',
			info: 'Permission to read all records',
		},
		editRecord: {
			label: 'Edit record',
			info: 'Permission to create and update records',
		},
	}
};
