<template>
	<div class="flag" :class="['is-' + type, classes]">
		<VIcon :name="icon" />
	</div>
</template>

<script>
export default {
	name: 'VFlag',
	props: {
		/**
		 * Type of flag
		 */
		type: {
			type: String,
			default: 'success',
			validator: (value) => {
                return [
                    'info',
                    'success',
                    'warning',
                    'danger'
                ].includes(value)
            }
		},
		/**
		 * Additional classes to add to the component
		 */
		classes: {
			type: String,
		},
	},
	computed: {
		icon: function () {
			let icon = 'info';
			switch(this.type) {
				case 'success':
					icon = 'check';
					break;
				case 'warning':
					icon = 'warning';
					break;
				case 'danger':
					icon = 'cross';
					break;
			}
			return icon;
		}
	}
};
</script>
