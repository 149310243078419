import Helpers from '@assets/scripts/helpers';

const state = {
	showCreateNew: false,
	modified: false,
	canBeModified: false,
	canBeExtended: false,
	currentComposedDocument: {},
	currentConnection: false,
};

export const getDefaultState = () => Helpers.cloneObject(state);
