import { getStoreMutation, getStoreGetter, getStoreAction } from '@assets/scripts/store/config';
import { log, debug } from '@assets/scripts/components/notifications';
import Helpers from '@assets/scripts/helpers';
import {
	createNewApiUser,
} from '@modules/RestApiBuilder/components/apiuser';
import { 
    createNewRestApi,
    validateRestApi,
 } from '@modules/RestApiBuilder/components/restapi';
import i18n from '@assets/i18n';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	POST_API_USER,
	DELETE_API_USER,
	GET_REST_API_BY_ID,
	POST_REST_API,
	DELETE_REST_API,
} from '@modules/RestApiBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

export const names = {
	UPDATE_REST_API_LIST: 'updateRestApiList',
	UPDATE_API_USER_LIST: 'updateApiUserList',
    LOAD_AND_SHOW_API_USER: 'loadAndShowApiUser',
    SAVE_CURRENT_API_USER: 'saveCurrentApiUser',
    API_USER_UPDATED: 'apiUserUpdated',
    REST_API_UPDATED: 'restApiUpdated',
    DELETE_API_USER: 'deleteApiUser',
	DELETE_REST_API: 'deleteRestApi',
    TOGGLE_CREATE_NEW: 'toggleCreateNew',
    NEW_REST_API: 'newRestApi',
    UNSET_REST_API: 'unsetRestApi',
    LOAD_AND_SHOW_REST_API: 'loadAndShowRestApi',
    VALIDATE_CURRENT_REST_API: 'validateCurrentRestApi',
    PUBLISH_CURRENT_REST_API: 'publishCurrentRestApi',
    RESET: 'reset',
};

export default {
	[names.UPDATE_REST_API_LIST]() {
		// do nothing
		// only exists so components can subscribe to it
	},
	[names.UPDATE_API_USER_LIST]() {
		// do nothing
		// only exists so components can subscribe to it
	},
    [names.TOGGLE_CREATE_NEW]({ commit }, status = true) {
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'RA'), !!status, {
			root: true,
		});
	},
    [names.UNSET_REST_API]({ commit }) {
		// reset current REST API
		commit(getStoreMutation('UNSET_REST_API', 'RA'), null, { root: true });

		// unset
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'RA'), false, {
			root: true,
		});
	},
    /**
	 * Action triggered when a new REST API must be created
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} settings
	 *  Settings for the new REST API
	 *
	 * @returns {void}
	 */
	async [names.NEW_REST_API]({ commit }, settings) {
		// create new REST API object with given settings
		const newRestApi = createNewRestApi(settings);

		// commit mutation in to set new REST API
		commit(getStoreMutation('SET_CURRENT_REST_API', 'RA'), newRestApi, {
			root: true,
		});

		// unset create new
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'RA'), false, {
			root: true,
		});

		// mark new REST API as modified so it can be saved immediately
		commit(
			getStoreMutation('MARK_REST_API_MODIFIED', 'RA'),
			{},
			{
				root: true,
			}
		);

		// show success message
		log(t('ra.newRestApi.createdSuccess'), 'success');
	},
    /**
	 * Action triggered to load an existing REST API
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} guid
	 *  GUID of REST API to load
	 *
	 * @returns {void}
	 */
	async [names.LOAD_AND_SHOW_REST_API]({ commit }, guid) {
		// load REST API from api
		const restApi = await useApiAsync(GET_REST_API_BY_ID, {
			keys: {
				guid
			}
		});

		if (!restApi) {
			log(t('ra.error.loadRestApi'), 'danger');
			return;
		}
		// commit mutation in root store
		commit(getStoreMutation('SET_CURRENT_REST_API', 'RA'), Helpers.cloneObject(restApi), {
			root: true,
		});
	},
    /**
	 * Action triggered to load an existing API User
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Object} apiUser
	 *  API User to load
	 *
	 * @returns {void}
	 */
	async [names.LOAD_AND_SHOW_API_USER]({ commit }, apiUser = false) {
		if (!apiUser) {
			// create new API user object if no API user
			// is given
			apiUser = createNewApiUser();
		}

		// commit mutation in store
		commit(
			getStoreMutation('SET_CURRENT_API_USER', 'RA'),
			Helpers.cloneObject(apiUser),
			{ root: true }
		);

		// open drawer with API user information
		commit(
			getStoreMutation('OPEN_DRAWER'),
			{ type: 'apiUserDetails' },
			{ root: true }
		);
	},
    [names.REST_API_UPDATED]({ dispatch }) {
		// dispatch action to reload REST API list
		dispatch(
			getStoreAction('UPDATE_REST_API_LIST', 'RA'),
			{},
			{ root: true }
		);
    },
    [names.API_USER_UPDATED]({ dispatch }) {
		// dispatch action to reload API user list
		dispatch(
			getStoreAction('UPDATE_API_USER_LIST', 'RA'),
			null,
			{ root: true }
		);
	},
    /**
	 * Action triggered to validate the current REST API
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	[names.VALIDATE_CURRENT_REST_API]({ commit, dispatch }) {
		// prepare for validation
		dispatch(getStoreAction('PREPARE_VALIDATION'), null, { root: true });

		// get current REST API from store
		const currentRestApi = this.getters[getStoreGetter('CURRENT_REST_API', 'RA')];

		// setTimeout is needed becouse we use async functions and we want the dom to be updated to show the loader first
		// setTimeout without time given we use 1 ms just to be sure that it works everywhere
		// we should fix this with better solution in the future because the async function for a reason are blocking the dom update
		setTimeout(async () => {
			const errors = await validateRestApi(currentRestApi);

			// loop errors and add to the errors array
			errors.forEach((error) => {
				commit(
					getStoreMutation('ADD_VALIDATION_ERROR'),
					error,
					{ root: true }
				);
			});

			// finish validation
			dispatch(getStoreAction('FINISH_VALIDATION'), null, { root: true });
		}, 1);
	},
    /**
	 * Action triggered to publish the current REST API
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.PUBLISH_CURRENT_REST_API]({ dispatch }) {
		// get current REST API from store
		const restApi = this.getters[getStoreGetter('CURRENT_REST_API', 'RA')];

		// post REST API through api
		const result = await useApiAsync(POST_REST_API, {
			parameters: {
				...restApi
			}
		});

		if (result !== false) {
			debug('successfully published', restApi);

			// show success message
			log(t('ra.restApiDetails.publish.success'), 'success');

			// dispatch action to take needed steps after publishing
			dispatch(
				getStoreAction('REST_API_UPDATED', 'RA'),
				null,
				{ root: true }
			);

			// dispatch action to unset REST API
			dispatch(getStoreAction('UNSET_REST_API', 'RA'), null, { root: true });
		}
	},
    /**
	 * Action triggered to delete a API User
	 * with a given GUID
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} guid
	 *  GUID of API User
	 *
	 * @returns {void}
	 */
	async [names.DELETE_API_USER]({ dispatch }, guid) {
		// do API call
		const result = await useApiAsync(DELETE_API_USER, {
			keys: {
				guid
			}
		});

		if (result !== false) {
			// show debug message in console
			debug('successful delete', {
				guid,
				result,
			});
	
			// dispatch action to take needed steps after deleting
			dispatch(
				getStoreAction('API_USER_UPDATED', 'RA'),
				null,
				{ root: true }
			);
	
			// show success message
			log(t('ra.apiUserDelete.success'), 'success');
		} else {
			// show debug message in console
			debug('error in delete', {
				guid,
				error,
			});
	
			// show error message
			log(t('ra.apiUserDelete.error'), 'danger');
		}
	},
	/**
	 * Action triggered to delete a REST API
	 * with a given GUID
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} guid
	 *  GUID of API User
	 *
	 * @returns {void}
	 */
	async [names.DELETE_REST_API]({ dispatch }, guid) {
		// do API call
		const result = await useApiAsync(DELETE_REST_API, {
			keys: {
				guid
			}
		});

		if (result !== false) {
			// show debug message in console
			debug('successful delete', {
				guid,
				result,
			});
	
			// dispatch action to take needed steps after deleting
			dispatch(
				getStoreAction('REST_API_UPDATED', 'RA'),
				null,
				{ root: true }
			);
	
			// show success message
			log(t('ra.restApiDelete.success'), 'success');
		} else {
			// show debug message in console
			debug('error in delete', {
				guid,
				error,
			});
	
			// show error message
			log(t('ra.restApiDelete.error'), 'danger');
		}
	},
    /**
	 * Action triggered to save the current API User
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} drawerId
	 *  GUID of drawer to close after succesful save
	 *
	 * @returns {void}
	 */
	async [names.SAVE_CURRENT_API_USER]({ commit, dispatch }, { drawerId }) {
		// get current API user from store
		const apiUser = this.getters[getStoreGetter('CURRENT_API_USER', 'RA')];

		// post API user through API
		const result = await useApiAsync(POST_API_USER, {
			parameters: {
				...apiUser
			}
		});

		if (result !== false) {
			debug('succesfully saved', apiUser);
			
			// show success message
			log(t('ra.apiUserDetails.savedSuccessfully'), 'success');
	
			// dispatch action to take needed steps after updating/saving
			dispatch(
				getStoreAction('API_USER_UPDATED', 'RA'),
				null,
				{ root: true }
			);
	
			// unset current API user
			commit(getStoreMutation('UNSET_API_USER', 'RA'), null, { root: true });
	
			// close drawer
			commit(getStoreMutation('CLOSE_DRAWER'), drawerId, { root: true });
		}
	},
	[names.RESET]({ commit }) {
		// reset state of store
		commit(getStoreMutation('RESET', 'RA'), null, { root: true });
	},
};
