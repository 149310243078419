<template>
	<span v-if="innerIsActive" class="tag" :class="{ 'is-disabled': isDisabled }">
		{{ text }}
		<VButton
			v-if="!isDisabled"
			@click.stop="close"
			class="delete"
			size="is-small"
			:icon="null"
			:variant="null"
		/>
	</span>
</template>
<script>
import { ref } from '@vue/reactivity';
export default {
	name: 'VTag',
	props: {
		/**
		 * Text shown in the Tag component
		 */
		text: {
			type: String,
			default: null,
		},
		/**
		 * If true show the Tag component on the page
		 */
		isActive: {
			type: Boolean,
			default: false,
		},
		/**
		 * If true, hide the delete button
		 */
		isDisabled: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit }) {
		const innerIsActive = ref(props.isActive);

		const close = (event) => {
			innerIsActive.value = false;
			emit('deleted', event);
		};

		return { props, innerIsActive, close };
	},
};
</script>
