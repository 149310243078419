import isObject from './isObject';
import cloneObject from './cloneObject';
import getKeyByValue from './getKeyByValue';

/**
 * Receives an array of Objects of which the
 * properties needs to be renamed according to the
 * received mapping object
 *
 * @param {Array} input
 * 	Array of similar Objects
 *
 * @param {Object} mapping
 * 	Object with key/value pairs
 *    key:	 property name to use in result
 *    value: property name in original object
 *
 * @param {Boolean} omitIfNotFound
 *  Determines whether the prop of the original
 *  object should be omitted in the result if no
 *  mapping key is given for it
 *
 * @returns {Array}
 *  Array of input Objects with renamed properties
 */
export const mapObjectArray = (input, mapping, omitIfNotFound = false) => {
	const output = [];

	// loop over input array
	input.forEach((row) => {
		// check if row is an object
		// rows that are not an object are omitted
		// from the result
		if (isObject(row)) {
			// prepare new row
			const newRow = {};

			// loop over properties in row
			for (const key in row) {
				// determine new key to use from mapping object,
				// use original key if not found in mapping object
				// and omitIfNotFound is set to false
				const newKey =
					getKeyByValue(mapping, key) ||
					(!omitIfNotFound ? key : false);

				// add property value with new key
				// to new object
				if (newKey) newRow[newKey] = cloneObject(row[key]);
			}

			// add newly created object to result array
			output.push(newRow);
		}
	});

	// return result
	return output;
};

/**
 * Receives an array of Objects that need to be
 * mapped to use in VSelect component
 *
 * @param {Array} input
 * 	Array of similar Objects
 *
 * @param {String} key
 *  Name of object prop to use as key
 *
 * @param {String} text
 *  Name of object prop to use as text
 *
 * @returns {Array}
 *  Array of Objects that can be used in VSelect component,
 *  ordered alphabetically by text
 */
export const mapObjectArrayForSelect = (input, { key, text }) => {
	const result = mapObjectArray(
		input,
		{
			value: key,
			text,
		},
		true
	);

	// order options alphabetically on text value
	result.sort((a, b) => a.text.localeCompare(b.text));

	return result;
};

/**
 * Receives an array of Objects that need to be
 * mapped to use in VSelect component
 *
 * @param {Array} input
 * 	Array of similar Objects
 *
 * @param {Object} objMeta
 * 	Definition of objects
 *
 * @param {String} key
 *  Name of object prop to use as key
 *
 * @param {String} text
 *  Name of object prop to use as text
 *
 * @returns {Array}
 *  Array of Objects that can be used in VSelect component,
 *  ordered alphabetically by text
 */
export const mapObjectArrayForSelectUsingMeta = (
	input,
	objMeta,
	{ key, text }
) => {
	return mapObjectArrayForSelect(
		input,
		{
			key: objMeta[key],
			text: objMeta[text],
		},
		true
	);
};
