import Helpers from '@assets/scripts/helpers';
import { restApiMeta } from '@modules/RestApiBuilder/endpoints';
import i18n from '@assets/i18n';
import { debug } from '@assets/scripts/components/notifications';
import Validation from '@assets/scripts/components/validationChecks';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	GET_REST_API_LIST,
	GET_API_RETURN_TYPES,
	GET_API_AUTHENTICATIONS,
} from '@modules/RestApiBuilder/endpoints';
import usePermission from '@assets/scripts/composables/usePermission';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Returns a newly created REST API
 *
 * @param {String} name
 *  Name of the REST API
 *
 * @param {String} description
 *  Description of the REST API
 *
 * @returns {Object}
 *  New REST API
 */
export const createNewRestApi = ({ name, description }) => {
	// create and return new REST API
	return Helpers.obj.create(restApiMeta, {
		name,
		description,
		guid: Helpers.newGuid(),
		is_new: true,
	});
};

/********************/
/*    VALIDATION    */
/********************/

const createErrorObject = (description) => {
	return Helpers.createErrorObject(description);
};

/**
 * Validates given REST API and returns errors array
 *
 * @param {Object} restApi
 *  REST API to validate
 *
 * @returns {Array}
 *	array of errors
 */
export const validateRestApi = async (restApi) => {
	debug('Validating', { restApi });

	let output = [];

	const setError = (description) => {
		output.push(createErrorObject(description));
	};

	// check for empty REST API name
	if (!Validation.stringNotEmpty(restApi.name)) {
		setError(t('ra.validation.restApiNameEmpty'));
	}
	// check if REST API name has atleast 2 characters
	else if (!Validation.stringHasAtleastTwoCharacters(restApi.name)) {
		setError(t('ra.validation.restApiNameIsShort'));
	} else {
		// check if REST API name without white spacing
		if (!Validation.stringWithoutSpacing(restApi.name)) {
			setError(t('ra.validation.restApiNameContainsSpace'));
		}
		// get all REST API's
		const restApis = await useApiAsync(GET_REST_API_LIST);

		if (restApis) {
			const list = [];

			// create list of REST API names, excluding REST API that is
			// being validated
			restApis.forEach((api) => {
				if (api.guid !== restApi.guid) list.push(api.name.toLowerCase());
			});

			// check if REST API name already exists
			if (Validation.stringExistsInList(restApi.name.toLowerCase(), list)) {
				setError(
					t('ra.validation.restApiNameNotUnique')
				);
			}
		}
	}
    // check for empty API return type
	if (!Validation.stringNotEmpty(restApi.return_type)) {
		setError(t('ra.validation.apiReturnTypeNotChosen'));
	} else {
        // get all available return types objects
		const returnTypes = await useApiAsync(GET_API_RETURN_TYPES);

        // check if API return type exist
        if(!Validation.stringExistsInList(restApi.return_type, returnTypes)) {
            setError(t('ra.validation.apiReturnTypeNotExist'));
        }
    }
    // check for empty API Authentication
	if (!Validation.stringNotEmpty(restApi.api_authentication)) {
		setError(t('ra.validation.apiAuthenticationNotChosen'));
	} else {
        // get all available api authentication objects
		const apiAuthentications = await useApiAsync(GET_API_AUTHENTICATIONS);

        // check if API Authentication exist
        if(!Validation.stringExistsInList(restApi.api_authentication, apiAuthentications)) {
            setError(t('ra.validation.apiAuthenticationNotExist'));
        }
    }
	return output;
};

/********************/
/* TABLE FORMATTING */
/********************/

/**
 * Get info about api users to use in Table in ApiUsersList component
 *
 * @param {Array} restApis
 *  Array of api user objects
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForTable = (restApis) => {
	const result = [];
	// loop over users
	restApis.forEach((api, key) => {
        const lastModified = api.modified;

		result.push({
			key, // key, useful for handling clicks
            guid: api.guid,
			name: api.name,
            returnType: api.return_type,
			isActive: api.is_active ? t('general.yes') : t('general.no'),
			// time as ISO string, used for sorting
			time: lastModified,
			// localized time for display
			last_time_edited: lastModified
				? Helpers.date.localeStringWithMinutes(lastModified)
				: t('general.dash'),
			edit: usePermission('Upsert', 'RestApiBuilder')? 'edit' : 'view',
			delete: usePermission('Delete rest api', 'RestApiBuilder'),
		});
	});

	return result;
};
