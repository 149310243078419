import { getStoreAction, getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import { log, debug } from '@assets/scripts/components/notifications';
import Helpers from '@assets/scripts/helpers';
import {
	createNew,
	prepareRoleForPost,
} from '@modules/UserManagement/components/role';
import {
	createNewUser,
} from '@modules/UserManagement/components/user';
import i18n from '@assets/i18n';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	POST_USER,
	DELETE_USER,
	POST_ROLE,
	DELETE_ROLE,
	GET_USER,
	GET_ROLE,
	RESET_MFA,
} from '@modules/UserManagement/endpoints';

import availableModules from '@assets/scripts/composables/useModules';
import allAbilities from '@assets/scripts/auth/abilities';

// translate function of vue-i18n
const { t } = i18n.global;

export const names = {
	DELETE_ROLE: 'deleteRole',
	DELETE_USER: 'deleteUser',
	ROLE_UPDATED: 'roleUpdated',
	USER_UPDATED: 'userUpdated',
	UPDATE_ROLE_LIST: 'updateRoleList',
	UPDATE_USER_LIST: 'udpateuserList',
	LOAD_AND_SHOW_ROLE: 'loadAndShowRole',
	LOAD_AND_SHOW_USER: 'loadAndShowUser',
	SAVE_CURRENT_ROLE: 'saveCurrentRole',
	SAVE_CURRENT_USER: 'saveCurrentUser',
	RESET_MFA: 'resetMfa',
    RESET: 'reset',
};

export default {
	/**
	 * Action triggered to delete a Role
	 * with a given GUID
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} guid
	 *  GUID of Role
	 *
	 * @returns {void}
	 */
	async [names.DELETE_ROLE]({ dispatch }, guid) {

		const result = await useApiAsync(DELETE_ROLE, {
			keys: {
				guid,
			}
		});

		if (result !== false) {
			// show debug message in console
			debug('successful delete', {
				guid,
				result,
			});
	
			// dispatch action to take needed steps after deleting
			dispatch(
				getStoreAction('ROLE_UPDATED', 'USER'),
				null,
				{ root: true }
			);
	
			// show success message
			log(t('user.roleDelete.success'), 'success');
		} else {
			// show error message
			log(t('user.roleDelete.error'), 'danger');
		}
	},
	/**
	 * Action triggered to delete a User
	 * with a given GUID
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} guid
	 *  GUID of User
	 *
	 * @returns {void}
	 */
	async [names.DELETE_USER]({ dispatch }, guid) {

		const result = await useApiAsync(DELETE_USER, {
			keys: {
				guid,
			}
		});

		if (result !== false) {
			debug('successful delete', {
				guid,
			});
	
			// dispatch action to take needed steps after deleting
			dispatch(
				getStoreAction('USER_UPDATED', 'USER'),
				null,
				{ root: true }
			);
	
			// show success message
			log(t('user.userDelete.success'), 'success');
		} else {
			// show error message
			log(t('user.userDelete.error'), 'danger');
		}
	},
	[names.ROLE_UPDATED]({ dispatch }) {
		// dispatch action to reload document list
		dispatch(
			getStoreAction('UPDATE_ROLE_LIST', 'USER'),
			null,
			{ root: true }
		);
	},
	[names.USER_UPDATED]({ dispatch }) {
		// dispatch action to reload user list
		dispatch(
			getStoreAction('UPDATE_USER_LIST', 'USER'),
			null,
			{ root: true }
		);
	},
	[names.UPDATE_ROLE_LIST]() {
		// do nothing
		// only exists so components can subscribe to it
	},
	[names.UPDATE_USER_LIST]() {
		// do nothing
		// only exists so components can subscribe to it
	},
	/**
	 * Action triggered to load an existing Role
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} role
	 *  Role to load
	 *
	 * @returns {void}
	 */
	async [names.LOAD_AND_SHOW_ROLE]({ commit }, guid = false) {
		let role;
		if (!guid) {
			// create new role object if no role
			// is given
			role = createNew();
		} else {
			role = await useApiAsync(GET_ROLE, {
				keys: {
					guid,
				}
			});
		}

		// commit mutation in store
		commit(
			getStoreMutation('SET_CURRENT_ROLE', 'USER'),
			Helpers.cloneObject(role),
			{ root: true }
		);
			
		// open drawer with role information
		commit(
			getStoreMutation('OPEN_DRAWER'),
			{ type: 'roleDetails' },
			{ root: true }
		);
	},
	/**
	 * Action triggered to load an existing User
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} user
	 *  User to load
	 *
	 * @returns {void}
	 */
	async [names.LOAD_AND_SHOW_USER]({ commit }, guid = false) {
		let user;
		if (!guid) {
			// create new user object if no user
			// is given
			user = createNewUser();
		} else {
			user = await useApiAsync(GET_USER, {
				keys: {
					guid,
				}
			});
		}

		// commit mutation in store
		commit(
			getStoreMutation('SET_CURRENT_USER', 'USER'),
			Helpers.cloneObject(user),
			{ root: true }
		);

		// open drawer with user information
		commit(
			getStoreMutation('OPEN_DRAWER'),
			{ type: 'userDetails' },
			{ root: true }
		);
	},
	/**
	 * Action triggered to save the current Role
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} drawerId
	 *  GUID of drawer to close after succesful save
	 *
	 * @returns {void}
	 */
	async [names.SAVE_CURRENT_ROLE]({ commit, dispatch }, { drawerId }) {
		// get current role from store
		const role = this.getters[getStoreGetter('CURRENT_ROLE', 'USER')];

		const availableAbilities = {};

		// loop over allAbilities
		for (const moduleName in allAbilities) {
			// check if moduleName is available in the clientModules
			if (availableModules.value.includes(moduleName)) {
				// add ability to availableAbilities
				availableAbilities[moduleName] = allAbilities[moduleName];
			}
		}
		const roleForPost = prepareRoleForPost(role, availableAbilities);
		// post role through api
		const result = await useApiAsync(POST_ROLE, {
			parameters: {
				...roleForPost,
			}
		});

		if (result !== false) {
			debug('succesfully saved', roleForPost);
			
			// show success message
			log(t('user.roleDetails.savedSuccessfully'), 'success');

			// dispatch action to take needed steps after updating/saving
			dispatch(
				getStoreAction('ROLE_UPDATED', 'USER'),
				null,
				{ root: true }
			);

			// unset current role
			commit(getStoreMutation('UNSET_ROLE', 'USER'), null, { root: true });

			// close drawer
			commit(getStoreMutation('CLOSE_DRAWER'), drawerId, { root: true });
		}
	},
	/**
	 * Action triggered to save the current User
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} drawerId
	 *  GUID of drawer to close after succesful save
	 *
	 * @returns {void}
	 */
	async [names.SAVE_CURRENT_USER]({ commit, dispatch }, { drawerId }) {
		// get current user from store
		const user = this.getters[getStoreGetter('CURRENT_USER', 'USER')];

		const result = await useApiAsync(POST_USER, {
			parameters: {
				...user,
			}
		});

		if (result !== false) {
			debug('succesfully saved', user);
			
			// show success message
			log(t('user.userDetails.savedSuccessfully'), 'success');
	
			// dispatch action to take needed steps after updating/saving
			dispatch(
				getStoreAction('USER_UPDATED', 'USER'),
				null,
				{ root: true }
			);
	
			// unset current user
			commit(getStoreMutation('UNSET_USER', 'USER'), null, { root: true });
	
			// close drawer
			commit(getStoreMutation('CLOSE_DRAWER'), drawerId, { root: true });
		}
	},
	/**
	 * Action triggered to reset the User MFA
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} user_guid
	 *  GUID of the user
	 *
	 * @param {String} user
	 *  user object
	 *
	 * @returns {void}
	 */
	async [names.RESET_MFA]({ commit, dispatch }, { user_guid, user }) {
		const result = await useApiAsync(RESET_MFA, {
			parameters: {
				user_guid: user_guid,
			}
		});

		if (result !== false) {
			debug('succesfully reset mfa', user_guid);
			
			// show success message
			log(t('user.userDetails.mfaResetSuccessfully'), 'success');

			// set user two_factor status to false
			commit(getStoreMutation('SET_TWO_FACTOR', 'USER'), false, {root: true});
		}
	},
	[names.RESET]({ commit }) {
		// reset state of store
		commit(getStoreMutation('RESET', 'USER'), null, { root: true });
	},
};
