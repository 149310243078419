<template>
	<div
		class="notification-bar"
		v-if="this.notifications.length > 0"
	>
		<VButton 
			v-if="notifications.length > 1"
			:is-rounded="true"
			:is-tool="true"
			text=""
			:title="$t('general.closeAll')"
			classes="notification-close-all"
			size="is-medium"
			icon="clear"
			@clicked="deleteNotifications"
		/>
		<div
			class="notification-holder"
		>
			<VNotification
				v-for="notification in notifications"
				:key="notification.key"
				:text="notification.text"
				:type="notification.type"
				:is-rounded="true"
				:is-fullwidth="true"
				:has-close="true"
				:auto-delete="notification.type === 'success' || notification.type === 'info' ? 5000 : 0"
				@deleted="() => notificationDeleted(notification.key)"
			/>
		</div>
	</div>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
import { getStoreMutation } from '@assets/scripts/store/config';

export default {
	name: 'TheNotificationBar',
	data: function () {
		return {
			notifications: this.initialNotifications,
		}
	},
	props: {
		/**
		 * Notifications to display on load
		 */
		initialNotifications: {
			type: Array,
			required: false,
			default: function() {
				return [];
			}
		}
	},
	mounted: function() {
		// subscribe to state updates of Store
		this.$store.subscribe((mutation) => {
			if (mutation.type !== getStoreMutation('SHOW_NOTIFICATION')) return;

			// show new notification
			this.notifications.push({
				key: Helpers.newGuid(),
				...mutation.payload
			});

		});
	},
	methods: {
		/**
		 * Called on emit 'deleted' from a notification
		 */
		notificationDeleted: function(key) {
			// filter out notification from current list
			this.notifications = this.notifications.filter(
				note => note.key !== key
			);
		},
		deleteNotifications: function(){
			this.notifications = [];
		}
	}
};
</script>
