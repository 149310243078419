<template>
	<component
		:is="isButton ? 'button' : 'a'"
		:type="isButton && type ? type : null"
		:class="[
			'button',
			classes,
			size,
			variant,
			{ 'is-tool': isTool },
			{ 'is-rounded': isRounded },
		]"
		:disabled="isDisabled || null"
		:title="title || null"
		@click="(ev) => $emit('clicked', ev)"
	>
		<VIcon
			:name="icon"
			:classes="iconClasses"
			v-if="icon && !iconOnRight"
		/>
		<span v-if="text" class="button-text">{{ text }}</span>
		<VIcon :name="icon" :classes="iconClasses" v-if="icon && iconOnRight" />
	</component>
</template>

<script>
export default {
	name: 'VButton',
	props: {
		/**
		 * Renders component as \<button\> rather than as \<a\>
		 */
		isButton: {
			type: Boolean,
			default: true,
		},
		/**
		 * Additional classes to add to the component
		 */
		classes: {
			type: String,
		},
		/**
		 * Size for the button component, also styles icon size:
		 * 	'',
		 *	'is-small',
		 *	'is-medium',
		 *	'is-large'
		 */
		size: {
			type: String,
			default: '',
			validator: (value) => {
				return ['', 'is-small', 'is-medium', 'is-large'].includes(
					value
				);
			},
		},
		/**
		 * Disables the button and the click handler
		 */
		isDisabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * title attribute for accessibility
		 */
		title: {
			type: String,
			default: null,
		},
		/**
		 * Add styling to the button, based on value:
		 * 'is-primary',
		 * 'is-secondary',
		 * 'is-tertiary'
		 */
		variant: {
			type: String,
			default: 'is-primary',
			validator: (value) => {
				return [
					'is-primary',
					'is-secondary',
					'is-tertiary',
					'is-quaternary',
				].includes(value);
			},
		},
		/**
		 * Used for general styling for the button when only icons need to be used
		 */
		isTool: {
			type: Boolean,
			default: false,
		},
		/**
		 * Used to give button with `is-tool` the option for a circled button, instead of a square
		 */
		isRounded: {
			type: Boolean,
			default: false,
		},
		/**
		 * Text to display in button
		 */
		text: {
			type: String,
		},
		/**
		 * Type of button, either "button", "submit" or "reset". Only applies if isButton is true.
		 */
		type: {
			type: String,
			default: 'button',
		},
		/**
		 * Optional name of icon to use in the button
		 */
		icon: {
			type: String,
		},
		/**
		 * Optional prop for placing the icon on the right. Standard it gets placed on the left
		 */
		iconOnRight: {
			type: Boolean,
			default: false,
		},
		/**
		 * Additional classes to add to the icon component
		 */
		iconClasses: {
			type: String,
		},
	},
};
</script>
