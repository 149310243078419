/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
export default {
	pageTitle: 'User Management',
	userManagement: 'User management',
	users: 'Users',
	roles: 'Roles',
	roleList: {
		addRole: 'Add role',
		table: {
			noResults: 'No roles found',
			columns: {
				name: 'Name',
			},
		},
		delete: {
			confirm: {
				title: 'Delete this role?',
				body: 'Are you sure you want to delete this role? This action can not be undone.',
				confirmButtonText: 'Delete',
			},
		},
	},
	userList: {
		addUser: 'Add user',
		table: {
			noResults: 'No users found',
			columns: {
				userName: 'Username',
				firstName: 'First name',
				lastName: 'Last name',
				email: 'Email address',
				isActive: 'Is active',
				lastLogin: 'Last login',
			},
		},
		delete: {
			confirm: {
				title: 'Delete this user?',
				body: 'Are you sure you want to delete this user? This action can not be undone.',
				confirmButtonText: 'Delete',
			},
		},
	},
	roleDelete: {
		error: 'Something went wrong while deleting role. Please try again.',
		success: 'Role was succesfully deleted',
	},
	userDelete: {
		error: 'Something went wrong while deleting user. Please try again.',
		success: 'User was succesfully deleted',
	},
	roleDetails: {
		roleName: 'Name of the role',
		nameError: 'Please enter a unique name',
		saveButton: 'Save role',
		savedSuccessfully: 'Role was successfully saved',
		close: {
			confirm: {
				title: 'Go back to overview?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you go back.',
				confirmButtonText: 'Ok',
			},
		},
	},
	userDetails: {
		title: 'User details',
		roles: 'Roles',
		userInformation: 'User information',
		userStatus: 'User status',
		gender: 'Gender',
		male: 'Male',
		female: 'Female',
		unknown: 'Unknown',
		firstName: 'First name',
		lastName: 'Last name',
		email: 'Email',
		isActive: 'Active',
		isAdmin: 'Admin',
		lastLogin: 'Last login',
		logins: 'Logins count',
		emailError: 'Please enter a valid unique email',
		saveButton: 'Save user',
		savedSuccessfully: 'User was successfully saved',
		mfaResetSuccessfully: 'User MFA is reset',
		close: {
			confirm: {
				title: 'Go back to overview?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you go back.',
				confirmButtonText: 'Ok',
			},
		},
		resetMFA: {
			MFAstatus: 'MFA status',
			MFAEnabled: 'MFA is enabled',
			MFADisabled: 'MFA is disabled',
			button: 'Reset MFA',
			confirm: {
				title: 'Reset MFA for this user?',
				body: 'Are you sure you want to reset MFA for this user?',
				confirmButtonText: 'Ok',
			},
		}
	},
	permissions: {
		label: 'User management',
		info: 'Permissions connected to Users & Roles',
		readUsers: {
			label: 'Read user management',
			info: 'Permission to read all users and roles',
		},
		editUsers: {
			label: 'Edit user management',
			info: 'Permission to Edit all users and roles',
		},
		deleteUsers: {
			label: 'Delete users',
			info: 'Permission to delete all users',
		},
	},
};
