<template>
	<textarea class="textarea" :class="{'is-narrow': isNarrow}" v-model="value"></textarea>
</template>

<script>
import { computed } from '@vue/reactivity';

/**
 * Component to both render text, email and number input.
 *
 * $attrs is automatically binded to the input, so normal html attributes (like: type="...", class="...", and disabled)
 * Can be added to it without the need for props.
 */
export default {
	name: 'VTextarea',
	props: {
		/**
		 * Optional value to use for input
		 */
		modelValue: {
			type: [String],
			required: true,
		},
		isNarrow: {
			type: Boolean,
			required: false,
			default: false
		},
	},
	setup(props, { emit }) {
		const value = computed({
			get() {
				return props.modelValue;
			},
			set(_value) {
				emit('update:modelValue', _value);
			},
		});

		return {
			value,
		};
	},
};
</script>
