<template>
	<div class="active-indicator">
		<span class="active-indicator-dot"></span>
		<span v-if="isFull" class="active-indicator-text">{{
			$t('general.active')
		}}</span>
	</div>
</template>

<script>
export default {
	name: 'VActiveIndicator',
	props: {
		/**
		 * Boolean indicator to also show the text 'Active'
		 */
		isFull: {
			type: Boolean,
			default: true,
		},
	},
};
</script>
