<template>
	<p class="menu-item-content">
		<VIcon :name="icon" />
		<span class="menu-item-text"> {{ name }} </span>
	</p>
</template>
<script>
export default {
	name: 'VMenuItemContent',
	props: {
		/**
		 * Text visible in the link element.
		 */
		name: {
			type: String,
			default: null,
		},
		/**
		 * Icon shown in the component.
		 */
		icon: {
			type: String,
			default: null,
		},
	},
};
</script>
