<template>
	<input ref="input" :disabled="disabled || null" class="input" v-model="value" />
</template>

<script>
import { computed } from '@vue/reactivity';

/**
 * Component to both render text, email and number input.
 *
 * $attrs is automatically binded to the input, so normal html attributes (like: type="...", class="...", and disabled)
 * Can be added to it without the need for props.
 */
export default {
	name: 'VInput',
	props: {
		/**
		 * Optional value to use for input
		 */
		modelValue: {
			type: [String, Number],
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		hasAutoFocus: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit }) {
		const value = computed({
			get() {
				return props.modelValue;
			},
			set(_value) {
				emit('update:modelValue', _value);
			},
		});

		return {
			value,
		};
	},
	mounted: function () {
		if (this.hasAutoFocus) this.$refs.input.focus();
	},
};
</script>
