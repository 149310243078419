import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

export default {
    label: t('fb.permissions.label'),
    info: t('fb.permissions.info'),
    module: 'FlowBuilder',
    permissions: {
        'Read': {
            label: t('fb.permissions.readFlow.label'),
            info: t('fb.permissions.readFlow.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Upsert': {
            implicit: [
                'Read',
            ],
            label: t('fb.permissions.editFlow.label'),
            info: t('fb.permissions.editFlow.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Delete': {
            implicit: [
                'Read',
            ],
            label: t('fb.permissions.deleteFlowDraft.label'),
            info: t('fb.permissions.deleteFlowDraft.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Publish': {
            implicit: [
                'Read',
                'Upsert',
            ],
            label: t('fb.permissions.publishFlow.label'),
            info: t('fb.permissions.publishFlow.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },

        /**
         * Permissions to indicate whether the current
         * user can update/delete special flows, like Script Service,
         * or using PUT or DELETE method
         * 
         * N.B.: This is currently NOT a permission that can be set.
         * This is only available for IsAdmin users, since they are
         * granted all permissions, even the ones that do not exist
         */
        // 'edit special flow'
        // delete special flow
    }
};
