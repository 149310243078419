import { ref } from 'vue';

/**
 * Composable for available routes to use in Vue Components, and other places
 * Docs about composables: https://vuejs.org/guide/reusability/composables.html#composables
 *
 * Routes are updated/set on request, and served within the `routes` array
 */

export const routes = ref([]);

export const update = (newRoutes) => {
	routes.value = newRoutes;
};