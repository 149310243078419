<template>
	<BTable
		:narrowed="narrowed"
		:sticky-header="stickyHeader"
		:scrollable="scrollable"
		:hoverable="hoverable"
		@click="rowClicked"
		:default-sort="defaultSort"
		:default-sort-direction="sortDirection"
		:data="data"
		:rowClass="getRowClass"
		:bTableClasses="tableClasses"
	>
		<BTableColumn
			v-for="(column, index) in columns"
			v-bind:key="index"
			:width="column.width || null"
			:label="column.label"
			:field="column.field"
			:sortable="column.sortable"
			:cellClass="column.cellClass"
			:visible="column.visible !== false"
			:customSort="
				column.sortable && column.isDate
					? sortByDate
					: column.customSort || null
			"
		>
			<template v-slot="props">
				<div
					:class="{
						'is-indent': column.leveled && props.row.level > 0,
					}"
				>
					<template v-if="column.leveled && props.row.level > 0">
						<span
							v-for="item in props.row.level + 1"
							:key="item"
							class="spacer"
						></span>
						<VIcon name="subdir" />
					</template>
					<template v-if="$slots[`${column.field}-${props.row.key}`]">
						<slot :name="`${column.field}-${props.row.key}`"></slot>
					</template>
					<template v-else-if="column.component">
						<Component
							:is="column.component"
							v-bind="
								column.args[props.row[column.field]] ||
								column.args
							"
							@click.prevent.stop="column.click(props.row)"
							v-if="props.row[column.field]"
						/>
						<template v-else>&nbsp;</template>
					</template>
					<template v-else>
						{{ props.row[column.field] || column.default }}
					</template>
				</div>
			</template>
		</BTableColumn>

		<template #empty>
			<div class="has-text-centered">
				{{ emptyText }}
			</div>
		</template>
	</BTable>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
export default {
	name: 'SortableDataTable',
	props: {
		columns: {
			type: Array,
			required: true,
		},
		rowClickedFn: {
			type: Function,
		},
		data: {
			type: Array,
			required: true,
		},
		sortDirection: {
			type: String,
			default: 'ASC',
		},
		defaultSort: {
			type: String,
			required: true,
		},
		emptyText: {
			type: String,
		},
		tableClasses: {
			type: String,
			default: '',
		},
		narrowed: {
			type: Boolean,
			default: true,
		},
		stickyHeader: {
			type: Boolean,
			default: true,
		},
		scrollable: {
			type: Boolean,
			default: true,
		},
		hoverable: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		rowClicked: function (row) {
			if (this.rowClickedFn) {
				this.rowClickedFn(row);
			}
		},
		sortByDate(a, b, isAsc) {
			return Helpers.date.sortByIsoDate(a.time, b.time, isAsc);
		},
		getRowClass: function (row) {
			return row.disabled ? 'is-disabled' : false;
		},
	},
};
</script>
