/**
 * Sometimes the Vuex Store is needed in components, helpers etc.
 * but the store can not be included because it creates an
 * inclusion loop. For those cases the store proxy can be used.
 * It is initialized from main.js before the Vue app is mounted,
 * so it should be safe to use in all JS files.
 */
export let store = false;

/**
 * Called from main.js to initialize
 * the proxy store
 *
 * @param {Object} vuexStore
 *  Store used by the Vue app
 *
 * @returns {void}
 */
export const init = (vuexStore) => {
	store = vuexStore;
};
