import {
	getStoreAction,
	getStoreMutation,
	getStoreGetter,
} from '@assets/scripts/store/config';
import { log, debug } from '@assets/scripts/components/notifications';
import setLoader from '@assets/scripts/store/loader';
import Helpers from '@assets/scripts/helpers';
import { validateComposedDocument, prepareComposedDocumentForPost, createNewComposedDocument } from '@modules/ComposedDocument/components/composed-document';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import i18n from '@assets/i18n';

import {
	DELETE_COMPOSED_DOCUMENT,
	POST_COMPOSED_DOCUMENT,
	GET_COMPOSED_DOCUMENT,
} from '@modules/ComposedDocument/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

export const names = {
	TOGGLE_CREATE_NEW: 'toggleCreateNewDocument',
	NEW_COMPOSED_DOCUMENT: 'newComposedDocument',
	UNSET_COMPOSED_DOCUMENT: 'unsetComposedDocument',
	COMPOSED_DOCUMENT_UPDATED: 'composedDocumentUpdated',
	UPDATE_COMPOSED_DOCUMENT_LIST: 'updateComposedDocumentList',
	LOAD_AND_SHOW_COMPOSED_DOCUMENT: 'loadAndShowComposedDocument',
	VALIDATE_CURRENT_COMPOSED_DOCUMENT: 'validateCurrentComposedDocument',
	PUBLISH_CURRENT_COMPOSED_DOCUMENT: 'publishCurrentComposedDocument',
	SAVE_CURRENT_COMPOSED_DOCUMENT: 'saveCurrentComposedDocument',
	DELETE_COMPOSED_DOCUMENT: 'deleteComposedDocument',
	RESET: 'reset',
};

export default {
	[names.TOGGLE_CREATE_NEW]({ commit }, status = true) {
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'CD'), !!status, {
			root: true,
		});
	},
	/**
	 * Action triggered when a new Composed Document must be created
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} settings
	 *  Settings for the new Composed Document
	 *
	 * @returns {void}
	 */
	async [names.NEW_COMPOSED_DOCUMENT]({ commit }, settings) {
		// show loader
		setLoader('new-composed-document');

		// create new Document object with given settings
		const newComposedDocument = createNewComposedDocument(settings);

		// commit mutation in to set new Document
		commit(getStoreMutation('SET_CURRENT_COMPOSED_DOCUMENT', 'CD'), newComposedDocument, {
			root: true,
		});

		// unset create new
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'CD'), false, {
			root: true,
		});

		// mark new document as modified so it can be saved immediately
		commit(
			getStoreMutation('MARK_MODIFIED', 'CD'),
			{},
			{
				root: true,
			}
		);

		// remove loader
		setLoader('new-composed-document', false);

		// show success message
		log(t('cd.newComposedDocument.createdSuccess'), 'success');
	},
	[names.UNSET_COMPOSED_DOCUMENT]({ commit }) {
		// reset current composed document
		commit(getStoreMutation('UNSET_COMPOSED_DOCUMENT', 'CD'), null, { root: true });

		// unset
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'CD'), false, {
			root: true,
		});
	},
	[names.COMPOSED_DOCUMENT_UPDATED]({ dispatch }, conn_guid) {
			// dispatch action to reload composed document list
			dispatch(
				getStoreAction('UPDATE_COMPOSED_DOCUMENT_LIST', 'CD'),
				conn_guid || false,
				{ root: true }
			);
	},
	[names.UPDATE_COMPOSED_DOCUMENT_LIST](store, conn_guid) {
		// do nothing
		// only exists so components can subscribe to it
	},
	/**
	 * Action triggered to validate the current composed document
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	[names.VALIDATE_CURRENT_COMPOSED_DOCUMENT]({ commit, dispatch }) {
		// prepare for validation
		dispatch(getStoreAction('PREPARE_VALIDATION'), null, { root: true });

		// get current composed document from store
		const currentComposedDocument = this.getters[getStoreGetter('CURRENT_COMPOSED_DOCUMENT', 'CD')];

		// setTimeout is needed becouse we use async functions and we want the dom to be updated to show the loader first
		// setTimeout without time given we use 1 ms just to be sure that it works everywhere
		// we should fix this with better solution in the future because the async function for a reason are blocking the dom update
		setTimeout(async () => {
			const errors = await validateComposedDocument(currentComposedDocument);

			// loop errors and add to the errors array
			errors.forEach((error) => {
				commit(
					getStoreMutation('ADD_VALIDATION_ERROR'),
					error,
					{ root: true }
				);
			});

			// finish validation
			dispatch(getStoreAction('FINISH_VALIDATION'), null, { root: true });
		}, 1);
	},
	/**
	 * Action triggered to save the current Composed Document
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.SAVE_CURRENT_COMPOSED_DOCUMENT]({ dispatch }) {
		// get current composed document from store
		const currentComposedDocument = this.getters[getStoreGetter('CURRENT_COMPOSED_DOCUMENT', 'CD')];

		// get prepared composed document object for post
		const composedDocument = prepareComposedDocumentForPost(currentComposedDocument)

		// post method through api
		const result = await useApiAsync(POST_COMPOSED_DOCUMENT, {
			keys: {
				connection: currentComposedDocument.conn_guid,
				guid: currentComposedDocument.guid,
			},
			parameters: {
				...composedDocument
			}
		});

		if (result !== false) {
			debug('succesfully saved', composedDocument);
			
			// show success message
			log(t('cd.documentDetails.savedSuccessfully'), 'success');
	
			// dispatch action to take needed steps after saving
			dispatch(
				getStoreAction('COMPOSED_DOCUMENT_UPDATED', 'CD'),
				currentComposedDocument.conn_guid,
				{ root: true }
			);
	
			// dispatch action to unset composed document
			dispatch(getStoreAction('UNSET_COMPOSED_DOCUMENT', 'CD'), null, { root: true });
		}
	},
	/**
	 * Action triggered to delete  a composed document with a
	 * given GUID and Connection GUID
	 *
	 * @param {String} guid
	 *  GUID of composed document
	 *
	 * @param {String} conn_guid
	 * GUID of Connection
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.DELETE_COMPOSED_DOCUMENT]({ dispatch }, { guid, conn_guid }) {
		// do API call
		const result = await useApiAsync(DELETE_COMPOSED_DOCUMENT, {
			keys: {
				connection: conn_guid,
				guid
			}
		});

		if (result !== false) {
			// show debug message in console
			debug('successful delete', {
				guid,
				conn_guid,
				result,
			});
	
			// dispatch action to take needed steps after deleting
			dispatch(
				getStoreAction('COMPOSED_DOCUMENT_UPDATED', 'CD'),
				conn_guid,
				{ root: true }
			);
	
			// show success message
			log(t('cd.documentDelete.success'), 'success');

		} else {
			// show debug message in console
			debug('error in delete', {
				guid,
				conn_guid,
			});
		}
	},
	/**
	 * Action triggered to publish the current Composed Document
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.PUBLISH_CURRENT_COMPOSED_DOCUMENT]({ commit, dispatch }) {
		// mark composed document as published
		commit(getStoreMutation('SET_PUBLISHED_STATE', 'CD'), true, {
			root: true,
		});

		// get current composed document from store
		const currentComposedDocument = this.getters[getStoreGetter('CURRENT_COMPOSED_DOCUMENT', 'CD')];

		// get prepared composed document object for post
		const composedDocument = prepareComposedDocumentForPost(currentComposedDocument)

		// post composed document through api
		const result = await useApiAsync(POST_COMPOSED_DOCUMENT, {
			keys: {
				connection: currentComposedDocument.conn_guid,
				guid: currentComposedDocument.guid,
			},
			parameters: {
				...composedDocument
			}
		});

		if (result !== false) {
			debug('successfully published', composedDocument);
	
			// show success message
			log(t('cd.documentDetails.publish.success'), 'success');
	
			// dispatch action to take needed steps after publishing
			dispatch(
				getStoreAction('COMPOSED_DOCUMENT_UPDATED', 'CD'),
				currentComposedDocument.conn_guid,
				{ root: true }
			);
	
			// dispatch action to unset composed document
			dispatch(getStoreAction('UNSET_COMPOSED_DOCUMENT', 'CD'), null, { root: true });
		} else {
			// mark composed document as not published if anything
			// went wrong
			commit(getStoreMutation('SET_PUBLISHED_STATE', 'CD'), false, {
				root: true,
			});
		}
	},
	/**
	 * Action triggered to load an existing Composed Document
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} guid
	 *  GUID of Composed Document to load
	 *
	 * @param {String} conn_guid
	 *  Connection GUID of Composed Document
	 *
	 * @returns {void}
	 */
	async [names.LOAD_AND_SHOW_COMPOSED_DOCUMENT](
		{ commit, dispatch },
		{ guid, conn_guid }
	) {
		// load composed document from api
		const document = await useApiAsync(GET_COMPOSED_DOCUMENT, {
			keys: {
				guid,
				connection: conn_guid
			}
		});

		if (!document) {
			log(t('cd.error.loadComposedDocument'), 'danger');
			return;
		}

		// commit mutation in root store
		commit(getStoreMutation('SET_CURRENT_COMPOSED_DOCUMENT', 'CD'), Helpers.cloneObject(document), {
			root: true,
		});
	},
	[names.RESET]({ commit }) {
		// reset state of store
		commit(getStoreMutation('RESET', 'CD'), null, { root: true });
	},
};
