import Helpers from '@assets/scripts/helpers';

const state = {
	currentUser: {},
    userModified: false,
	currentRole: {},
    roleModified: false,
};

export default () => Helpers.cloneObject(state);
