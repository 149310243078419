import {
	getStoreMutation,
    getStoreAction,
    getStoreGetter
} from '@assets/scripts/store/config';
import {
	createNewScript,
    validateScript,
} from '@modules/ScriptBuilder/components/script';
import { log, debug } from '@assets/scripts/components/notifications';
import Helpers from '@assets/scripts/helpers';
import i18n from '@assets/i18n';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	GET_SCRIPT_BY_ID,
	DELETE_SCRIPT,
	POST_SCRIPT,
} from '@modules/ScriptBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

export const names = {
    LOAD_AND_SHOW_SCRIPT: 'loadAndShowScript',
    DELETE_SCRIPT: 'deleteScript',
	TOGGLE_CREATE_NEW: 'toggleCreateNew',
    SCRIPT_UPDATED: 'scriptUpdated',
    UPDATE_SCRIPT_LIST: 'updateScriptList',
	UNSET_SCRIPT: 'unsetScript',
	RESET: 'reset',
    NEW_SCRIPT: 'newScript',
    SAVE_CURRENT_SCRIPT: 'saveCurrentScript',
    PUBLISH_CURRENT_SCRIPT: 'publishCurrentScript',
    VALIDATE_CURRENT_SCRIPT: 'validateCurrentScript',
};

export default {
    /**
	 * Action triggered when a new Script must be created
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} settings
	 *  Settings for the new Script
	 *
	 * @returns {void}
	 */
	async [names.NEW_SCRIPT]({ commit }, settings) {
		// create new Script object with given settings
		const newScript = createNewScript(settings);

		// commit mutation in to set new script
		commit(getStoreMutation('SET_CURRENT_SCRIPT', 'SB'), newScript, {
			root: true,
		});

		// unset create new
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'SB'), false, {
			root: true,
		});

		// mark new script as modified so it can be saved immediately
		commit(
			getStoreMutation('MARK_MODIFIED', 'SB'),
			{},
			{
				root: true,
			}
		);

		// show success message
		log(t('sb.newScript.createdSuccess'), 'success');
	},
	[names.TOGGLE_CREATE_NEW]({ commit }, status = true) {
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'SB'), !!status, {
			root: true,
		});
	},
    [names.UPDATE_SCRIPT_LIST]() {
		// do nothing
		// only exists so components can subscribe to it
	},
    [names.UNSET_SCRIPT]({ commit }) {
		// reset current script
		commit(getStoreMutation('UNSET_SCRIPT', 'SB'), null, { root: true });

		// unset
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'SB'), false, {
			root: true,
		});
	},
    [names.SCRIPT_UPDATED]({ dispatch }) {
		// dispatch action to reload script list
		dispatch(
			getStoreAction('UPDATE_SCRIPT_LIST', 'SB'),
			{},
			{ root: true }
		);
    },
    /**
	 * Action triggered to delete a script
	 * with a given GUID
	 *
	 * @param {String} guid
	 *  GUID of script
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.DELETE_SCRIPT]({ dispatch }, guid) {
		// do API call
		const result = await useApiAsync(DELETE_SCRIPT, {
			keys: {
				guid
			}
		});

		if (result !== false) {
			// show debug message in console
			debug('successful delete', {
				guid,
				result,
			});
	
			// dispatch action to take needed steps after deleting
			dispatch(
				getStoreAction('SCRIPT_UPDATED', 'SB'),
				null,
				{ root: true }
			);
	
			// show success message
			log(t('sb.scriptDelete.success'), 'success');
		} else {
			// show debug message in console
			debug('error in delete', {
				guid,
				error,
			});
	
			// show error message
			log(t('sb.scriptDelete.error'), 'danger');
		}
	},
    /**
	 * Action triggered to validate the current script
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	[names.VALIDATE_CURRENT_SCRIPT]({ commit, dispatch }) {
		// prepare for validation
		dispatch(getStoreAction('PREPARE_VALIDATION'), null, { root: true });

		// get current script from store
		const currentScript = this.getters[getStoreGetter('CURRENT_SCRIPT', 'SB')];

		// setTimeout is needed becouse we use async functions and we want the dom to be updated to show the loader first
		// setTimeout without time given we use 1 ms just to be sure that it works everywhere
		// we should fix this with better solution in the future because the async function for a reason are blocking the dom update
		setTimeout(async () => {
			const errors = await validateScript(currentScript);

			// loop errors and add to the errors array
			errors.forEach((error) => {
				commit(
					getStoreMutation('ADD_VALIDATION_ERROR'),
					error,
					{ root: true }
				);
			});

			// finish validation
			dispatch(getStoreAction('FINISH_VALIDATION'), null, { root: true });
		}, 1);
	},
    /**
	 * Action triggered to save the current Script
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.SAVE_CURRENT_SCRIPT]({ dispatch }) {
		// get current script from store
		const script = this.getters[getStoreGetter('CURRENT_SCRIPT', 'SB')];

		// post script through api
		const result = await useApiAsync(POST_SCRIPT, {
			parameters: {
				...script
			}
		});

		if (result !== false) {
			debug('succesfully saved', result);
			
			// show success message
			log(t('sb.scriptDetails.savedSuccessfully'), 'success');
	
			// dispatch action to take needed steps after saving
			dispatch(
				getStoreAction('SCRIPT_UPDATED', 'SB'),
				null,
				{ root: true }
			);
	
			// dispatch action to unset script
			dispatch(getStoreAction('UNSET_SCRIPT', 'SB'), null, { root: true });
		}
	},
    /**
	 * Action triggered to publish the current Script
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.PUBLISH_CURRENT_SCRIPT]({ commit, dispatch }) {
		// mark script as published
		commit(getStoreMutation('SET_PUBLISHED_STATE', 'SB'), true, {
			root: true,
		});

		// get current script from store
		const script = this.getters[getStoreGetter('CURRENT_SCRIPT', 'SB')];

		// post script through api
		const result = await useApiAsync(POST_SCRIPT, {
			parameters: {
				...script
			}
		});

		if (result !== false) {
			debug('successfully published', result);

			// show success message
			log(t('sb.scriptDetails.publish.success'), 'success');

			// dispatch action to take needed steps after publishing
			dispatch(
				getStoreAction('SCRIPT_UPDATED', 'SB'),
				null,
				{ root: true }
			);

			// dispatch action to unset script
			dispatch(getStoreAction('UNSET_SCRIPT', 'SB'), null, { root: true });
		} else {
			// mark script as not published if anything
			// went wrong
			commit(getStoreMutation('SET_PUBLISHED_STATE', 'SB'), false, {
				root: true,
			});
		}
	},
    /**
	 * Action triggered to load an existing Script
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} guid
	 *  GUID of Script to load
	 *
	 * @returns {void}
	 */
	async [names.LOAD_AND_SHOW_SCRIPT]({ commit }, guid) {
		// load script from api
		const script = await useApiAsync(GET_SCRIPT_BY_ID, {
			keys: {
				guid
			}
		});

		if (!script) {
			log(t('sb.error.loadScript'), 'danger');
			return;
		}

		// commit mutation in root store
		commit(getStoreMutation('SET_CURRENT_SCRIPT', 'SB'), Helpers.cloneObject(script), {
			root: true,
		});
	},
	[names.RESET]({ commit }) {
		// reset state of store
		commit(getStoreMutation('RESET', 'SB'), null, { root: true });
	},
};
