<template>
	<VField
		:isFloating="true"
		:label="$t('defaultValue.string.label')"
		:class="[{ 'is-active': value }, classes]"
		:isError="showError"
	>
		<VInput
			v-model="value"
			:placeholder="$t('defaultValue.string.label')"
			type="text"
			:disabled="isDisabled"
		/>
	</VField>

	<div class="notification-wrapper" v-if="showError">
		<VNotification
			:text="$t('defaultValue.string.requiredError')"
			:isFullwidth="false"
			:hasClose="false"
			type="danger"
		/>
	</div>
</template>

<script>
export default {
	name: 'StringDefaultValue',
	props: {
		valueModel: {
			type: null,
		},
		validModel: {
			type: Boolean,
			default: false,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		classes: {
			type: String,
		}
	},
	data: function () {
		return {
			value: typeof this.valueModel === 'string' ? this.valueModel : '',
			modified: false,
		};
	},
	mounted: function () {
		if (this.value !== this.valueModel)
			this.$emit('update:valueModel', this.value);
		this.$emit('update:validModel', this.isValid);
	},
	watch: {
		value() {
			this.modified = true;
			this.$emit('update:valueModel', this.value);
		},
		isValid() {
			this.$emit('update:validModel', this.isValid);
		},
	},
	computed: {
		isValid: function () {
			return !this.isRequired || this.value.length > 0;
		},
		showError: function () {
			return this.modified && !this.isValid;
		},
	},
};
</script>
