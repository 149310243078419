import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { log, debug } from '@assets/scripts/components/notifications';
import {
	createNewMethod,
	prepareMethodForPost,
	validateMethod,
} from '@modules/MethodBuilder/components/method';
import Helpers from '@assets/scripts/helpers';
import i18n from '@assets/i18n';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	GET_METHOD,
	POST_METHOD,
	DELETE_METHOD,
} from '@modules/MethodBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

export const names = {
	TOGGLE_CREATE_NEW: 'toggleCreateNewMethod',
	NEW_METHOD: 'newMethod',
	LOAD_AND_SHOW_METHOD: 'loadAndShowMethod',
	UNSET_METHOD: 'unsetMethod',
	SAVE_CURRENT_METHOD: 'saveCurrentMethod',
	PUBLISH_CURRENT_METHOD: 'publishCurrentMethod',
	VALIDATE_CURRENT_METHOD: 'validateCurrentMethod',
	DELETE_METHOD: 'deleteMethod',
	METHOD_UPDATED: 'methodUpdated',
	UPDATE_METHOD_LIST: 'updateMethodList',
	SAVE_FIELD: 'saveField',
	RESET: 'reset',
};

export default {
	[names.TOGGLE_CREATE_NEW]({ commit }, status = true) {
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'METHOD'), !!status, {
			root: true,
		});
	},
	/**
	 * Action triggered when a new Method must be created
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Object} settings
	 *  Settings for the new Method
	 *
	 * @returns {void}
	 */
	[names.NEW_METHOD]({ commit }, settings) {
		// create new Method object with given settings
		const newMethod = createNewMethod(settings);

		// commit mutation in store to set new Method
		commit(getStoreMutation('SET_CURRENT_METHOD', 'METHOD'), newMethod, {
			root: true,
		});

		// unset create new
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'METHOD'), false, {
			root: true,
		});

		// mark new method as modified so it can be saved immediately
		commit(
			getStoreMutation('MARK_MODIFIED', 'METHOD'),
			{},
			{ root: true }
		);

		// show success message
		log(t('method.newMethod.createdSuccess'), 'success');
	},
	/**
	 * Action triggered to load an existing Method
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} guid
	 *  GUID of Metod to load
	 *
	 * @param {String} conn_guid
	 *  Connection GUID of Method
	 *
	 * @returns {void}
	 */
	async [names.LOAD_AND_SHOW_METHOD]({ commit }, { guid, conn_guid }) {
		// load method from api
		const method = await useApiAsync(GET_METHOD, {
			keys: {
				connection: conn_guid,
				guid
			}
		});

		if (!method) {
			log(t('method.error.loadMethod'), 'danger');
			return;
		}

		// commit mutation in store
		commit(getStoreMutation('SET_CURRENT_METHOD', 'METHOD'), Helpers.cloneObject(method), {
			root: true,
		});
	},
	[names.UNSET_METHOD]({ commit }) {
		// reset current method
		commit(getStoreMutation('UNSET_METHOD', 'METHOD'), null, { root: true });

		// unset
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'METHOD'), false, {
			root: true,
		});
	},
	/**
	 * Action triggered to save the current Method
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.SAVE_CURRENT_METHOD]({ dispatch }) {
		// get current method from store
		const currentMethod = this.getters[getStoreGetter('CURRENT_METHOD', 'METHOD')];

		// get prepared method object
		const method = prepareMethodForPost(currentMethod);

		// post method through api
		const result = await useApiAsync(POST_METHOD, {
			keys: {
				connection: currentMethod.conn_guid,
				guid: currentMethod.guid,
			},
			parameters: {
				...method
			}
		});

		if (result !== false) {
			debug('succesfully saved', method);
			
			// show success message
			log(t('method.methodDetails.savedSuccessfully'), 'success');
	
			// dispatch action to take needed steps after saving
			dispatch(
				getStoreAction('METHOD_UPDATED', 'METHOD'),
				currentMethod.conn_guid,
				{ root: true }
			);
	
			// dispatch action to unset method
			dispatch(getStoreAction('UNSET_METHOD', 'METHOD'), null, { root: true });
		}
	},
	/**
	 * Action triggered to publish the current Method
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.PUBLISH_CURRENT_METHOD]({ commit, dispatch }) {
		// mark method as published
		commit(getStoreMutation('SET_PUBLISHED_STATE', 'METHOD'), true, {
			root: true,
		});

		// get current method from store
		const currentMethod = this.getters[getStoreGetter('CURRENT_METHOD', 'METHOD')];

		// get prepared method object
		const method = prepareMethodForPost(currentMethod);

		// post method through api
		const result = await useApiAsync(POST_METHOD, {
			keys: {
				connection: currentMethod.conn_guid,
				guid: currentMethod.guid,
			},
			parameters: {
				...method
			}
		});

		if (result !== false) {
			debug('successfully published', method);
	
			// show success message
			log(t('method.methodDetails.publish.success'), 'success');
	
			// dispatch action to take needed steps after publishing
			dispatch(
				getStoreAction('METHOD_UPDATED', 'METHOD'),
				currentMethod.conn_guid,
				{ root: true }
			);
	
			// dispatch action to unset method
			dispatch(getStoreAction('UNSET_METHOD', 'METHOD'), null, { root: true });
		} else {
			// mark method as not published if anything
			// went wrong
			commit(getStoreMutation('SET_PUBLISHED_STATE', 'METHOD'), false, {
				root: true,
			});
		}
	},
	/**
	 * Action triggered to validate the current method
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	[names.VALIDATE_CURRENT_METHOD]({ commit, dispatch }) {
		// prepare for validation
		dispatch(getStoreAction('PREPARE_VALIDATION'), null, { root: true });

		// get current method from store
		const currentMethod = this.getters[getStoreGetter('CURRENT_METHOD', 'METHOD')];

		// setTimeout is needed becouse we use async functions and we want the dom to be updated to show the loader first
		// setTimeout without time given we use 1 ms just to be sure that it works everywhere
		// we should fix this with better solution in the future because the async function for a reason are blocking the dom update
		setTimeout(async () => {
			const errors = await validateMethod(currentMethod);

			// loop errors and add to the errors array
			errors.forEach((error) => {
				commit(
					getStoreMutation('ADD_VALIDATION_ERROR'),
					error,
					{ root: true }
				);
			});

			// finish validation
			dispatch(getStoreAction('FINISH_VALIDATION'), null, { root: true });
		}, 1);
	},
	/**
	 * Action triggered to delete  a method with a
	 * given GUID and Connection GUID
	 *
	 * @param {String} guid
	 *  GUID of method
	 *
	 * @param {String} conn_guid
	 * GUID of Connection
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.DELETE_METHOD]({ dispatch }, { guid, conn_guid }) {
		// do API call
		const result = await useApiAsync(DELETE_METHOD, {
			keys: {
				connection: conn_guid,
				guid
			}
		});

		if (result !== false) {
			// show debug message in console
			debug('successful delete', {
				guid,
				conn_guid,
				result,
			});
	
			// dispatch action to take needed steps after deleting
			dispatch(
				getStoreAction('METHOD_UPDATED', 'METHOD'),
				conn_guid,
				{ root: true }
			);
	
			// show success message
			log(t('method.methodDelete.success'), 'success');

		} else {
			// show debug message in console
			debug('error in delete', {
				guid,
				conn_guid,
			});
		}
	},
	[names.METHOD_UPDATED]({ dispatch }, conn_guid) {
		// dispatch action to reload method list
		dispatch(
			getStoreAction('UPDATE_METHOD_LIST', 'METHOD'),
			conn_guid || false,
			{ root: true }
		);
	},
	[names.UPDATE_METHOD_LIST](store, conn_guid) {
		// do nothing
		// only exists so components can subscribe to it
	},
	/**
	 * Updates an existing field of the current Method or
	 * adds a new field
	 * N.B.: Field is updated/saved in Current Method in module state
	 * Only after Save by user any changes will be moved to
	 * method
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Object} field
	 *  Field object to save
	 *
	 * @param {Integer} key
	 *  Key of field to update in field list
	 *
	 * @param {String} drawerId
	 *  ID of drawer to close after successful update
	 *
	 * @param {Boolean} showMessage
	 *  Indicator whether to show a success message when field
	 *  is saved
	 *
	 * @param {Boolean} isOutput
	 *  Indicates whether the field is part of the Output of
	 *  the method
	 */
 	[names.SAVE_FIELD](
		{ commit },
		{
			field,
			key = false,
			drawerId = false,
			showMessage = true,
			isOutput = false,
	}
	) {
		// prepare parameters for update mutation
		// success of update is based on value of 'result'
		// property in parameters, since mutations do not
		// return anything
		const param = {
			field,
			key,
			result: false,
			isOutput,
		};

		// save field
		commit(getStoreMutation('SAVE_FIELD', 'METHOD'), param, {
			root: true,
		});

		if (!param.result) {
			// show error
			log(t('error.updateError'), 'danger');
			return;
		}

		// close drawer
		if (drawerId !== false) {
			commit(getStoreMutation('CLOSE_DRAWER'), drawerId, {
				root: true,
			});
		}

		// mark current method as modified
		commit(
			getStoreMutation('MARK_MODIFIED', 'METHOD'),
			{},
			{ root: true }
		);

		// show success message
		if (showMessage) log(t('field.updateSuccess'), 'success');
	},
	[names.RESET]({ commit }) {
		// reset state of store
		commit(getStoreMutation('RESET', 'METHOD'), null, { root: true });
	},
};
