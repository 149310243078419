export default (objects, propToMatch, propToReturn, value) => {
	let result = false;

	objects.some((el) => {
		if (
			typeof el[propToMatch] !== 'undefined' &&
			typeof el[propToReturn] !== 'undefined' &&
			el[propToMatch] === value
		) {
			result = el[propToReturn];
			return true;
		}
	});

	return result;
};
