import Helpers from '@assets/scripts/helpers';
import { apiUserMeta } from '@modules/RestApiBuilder/endpoints';
import usePermission from '@assets/scripts/composables/usePermission';
import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;


/**
 * Returns a newly created API user
 *
 * @returns {Object}
 *  New API User
 */
export const createNewApiUser = () => {
	return Helpers.obj.create(apiUserMeta, {
        api_token: Helpers.newGuid(),
        guid: Helpers.newGuid(),
    });
};

/********************/
/* TABLE FORMATTING */
/********************/

/**
 * Get info about api users to use in Table in ApiUsersList component
 *
 * @param {Array} users
 *  Array of api user objects
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForTable = (apiUsers) => {
	const result = [];
	// loop over users
	apiUsers.forEach((user) => {
        const lastModified = user.modified;
		result.push({
			user,
			id: user.guid,
			userName: user.username,
			isActive: user.is_active ? t('general.yes') : t('general.no'),
			// time as ISO string, used for sorting
			time: lastModified,
			// localized time for display
			last_time_edited: lastModified
				? Helpers.date.localeStringWithMinutes(lastModified)
				: t('general.dash'),
			edit: usePermission('Upsert', 'RestApiBuilder')? 'edit' : 'view',
			delete: usePermission('Delete', 'RestApiBuilder'),
		});
	});

	return result;
};
