import getDefaultState from './state';

export const names = {
	TOGGLE_CREATE_NEW_RECORD: 'toggleCreateNewRecord',
	SET_CURRENT_RECORD: 'setCurrentRecord',
	SET_CURRENT_DOCUMENT: 'setCurrentDocument',
	MARK_MODIFIED: 'markModified',
	RESET: 'reset',
};

export default {
	/**
	 * Sets the current record to newly retrieved record
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} record
	 *  Retrieved record from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_RECORD](state, record) {
		// save record to state
		state.currentRecord = record;

		// a newly loaded record is not modified yet
		state.modified = false;
	},

	/**
	 * Marks the current record as modified
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.MARK_MODIFIED](state) {
		state.modified = true;
	},

	/**
	 * Sets the current document to newly retrieved document
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} document
	 *  Retrieved document from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_DOCUMENT](state, document) {
		// save document to state
		state.currentDocument = document;

		// a newly loaded document is not modified yet
		state.modified = false;
	},

	/**
	 * Resets module state to default
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @returns {void}
	 */
	[names.RESET](state) {
		Object.assign(state, getDefaultState());
	},
};
