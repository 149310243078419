<template>
	<div
		class="select"
		:class="[{ 'is-active': isActive }, { 'is-disabled': $attrs.disabled }, classes]"
	>
		<select
			v-model="value"
			:disabled="$attrs.disabled || null"
			@focus="open"
			@blur="close"
		>
			<option v-if="hasUnsetOption" value="__unset__">{{ unsetOption }}</option>

			<template v-if="selectOptions.length > 0">
				<option
					v-for="(option, index) in selectOptions"
					:key="`option-${index}`"
					:value="option.value"
				>
					{{ option.text }}
				</option>
			</template>
		</select>
	</div>
</template>

<script>
import { computed, ref, unref } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';

export default {
	name: 'VSelect',
	props: {
		/**
		 * Array of objects for the Select options. Expects: [ { text: '', value: '' }, ... ]
		 */
		options: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		/**
		 * Value to used for select
		 */
		modelValue: {
			required: true,
		},
		classes: {
			type: String,
			default: '',
		},
		hasUnsetOption: {
			type: Boolean,
			default: false
		},
		unsetOptionText: {
			type: String,
			default: ''
		}
	},
	setup(props, { emit }) {
		const { t } = useI18n();
		const isActive = ref(false);

		const value = computed({
			get() {
				return props.modelValue;
			},
			set(val) {
				isActive.value = false;
				emit('update:modelValue', val === '__unset__' ? '' : val);
			},
		});

		const close = () => {
			isActive.value = false;
		};

		const open = () => {
			isActive.value = true;
		};

		const selectOptions = computed(() => {
			return unref(props.options);
		});

		const unsetOption = computed(() => {
			return props.unsetOptionText || t('general.none');
		});

		return { value, isActive, close, open, selectOptions, unsetOption };
	},
};
</script>

<style></style>
