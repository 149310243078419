<template>
	<div class="box box-datepicker">
		<VTitle :text="label" :size="4" />

		<p class="is-small" v-if="helpText">
			{{ helpText }}
		</p>

		<VField
			:isFloating="true"
			:label="$t('datepicker.dateFieldlabel')"
			class="is-active datepicker-field"
		>
			<VInput
				v-model="value"
				:placeholder="label"
				:type="inputType"
				step="1"
			/>
		</VField>

	</div>
</template>

<script>
export default {
	name: 'VInputDate',
	props: {
		valueModel: {
			type: null,
		},
		label: {
			type: String,
		},
		helpText: {
			type: String,
		},
		isFloating: {
			type: Boolean,
			default: true,
		},
		setTimeInOutput: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			value: typeof this.valueModel === 'string' ? this.valueModel : '',
		};
	},
	mounted: function () {
		if (this.value !== this.valueModel)
			this.$emit('update:valueModel', this.value);
	},
	watch: {
		value() {
			let output = this.value;

			// check if seconds are omitted by the native input field
			if (this.setTimeInOutput && output.split(':').length < 3) {
				output += ':00';
			}

			this.$emit('update:valueModel', output);
		},
	},
	computed: {
		inputType: function () {
			return this.setTimeInOutput ? 'datetime-local' : 'date';
		},
	},
};
</script>
