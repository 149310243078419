<template>
	<div
		class="content-block"
		:class="[
			{
				'is-large': isLarge,
				'has-fullwidth-body': hasFullwidthBody,
				'is-v-centered': isVCentered,
			},
		]"
	>
		<div class="content-block-card">
			<div :class="headClasses" class="content-block-card-head">
				<div class="content-block-card-title" v-if="title">
					<div class="content-block-card-title-content">
						<VTitle :size="size" :text="title" />
					</div>
				</div>
				<div v-if="$slots.header" class="content-block-card-head-content">
					<!-- @slot This slot adds content to the header part of the overlay. It will be placed below the buttons and title. It is fixed when scrolling -->
					<slot name="header"></slot>
				</div>
			</div>
			<div class="content-block-card-body">
				<!-- @slot This slot adds content to the body part of the overlay. It has scrollable content -->
				<slot />
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'ContentBlock',
	props: {
		/**
		 * If true the block gets a max-width based on the $modal-content-width-large defined in the config-bulma.scss
		 * If false the size will be the default max-width of $modal-content-width-small.
		 */
		isLarge: {
			type: Boolean,
			default: true,
		},
		/**
		 * Option to center a content block vertically. Set to false by default. Reason for
		 * centering vertically might be a content block the only child
		 */
		isVCentered: {
			type: Boolean,
			default: false,
		},
		/**
		 * If true, the body of the modal has no padding left and right.
		 * Useful for blocks with 100% wide content
		 */
		hasFullwidthBody: {
			type: Boolean,
			default: false,
		},
		/**
		 * If true, the head of the modal has padding bottom and bottom border.
		 */
		hasBorderUnderTitle: {
			type: Boolean,
			default: false,
		},
		/**
		 * Text for block title
		 */
		title: {
			type: String,
			default: null,
		},
		/**
		 * Size of the title in the block
		 */
		size: {
			type: Number,
			default: 1,
		},
	},
	computed: {
		headClasses() {
			return this.hasBorderUnderTitle
				? 'has-bottom-divider has-padding-bottom-1'
				: '';
		},
	},
};
</script>
