<template>
	<VOptionBoolean
		:label="$t('defaultValue.boolean.label')"
		v-model:modelValue="value"
		:isDisabled="isDisabled"
		:classes="classes"
	/>

	<div class="notification-wrapper" v-if="showError">
		<VNotification
			:text="$t('defaultValue.boolean.requiredError')"
			:isFullwidth="false"
			:hasClose="false"
			type="danger"
		/>
	</div>
</template>

<script>
export default {
	name: 'BooleanDefaultValue',
	props: {
		valueModel: {
			type: null,
		},
		validModel: {
			type: Boolean,
			default: false,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		classes: {
			type: String,
		}
	},
	data: function () {
		return {
			value:
				typeof this.valueModel === 'boolean' ? this.valueModel : false,
			modified: false,
		};
	},
	mounted: function () {
		if (this.value !== this.valueModel)
			this.$emit('update:valueModel', this.value);
		this.$emit('update:validModel', this.isValid);
	},
	watch: {
		value() {
			this.modified = true;
			this.$emit('update:valueModel', this.value);
		},
		isValid() {
			this.$emit('update:validModel', this.isValid);
		},
	},
	computed: {
		isValid: function () {
			return !this.isRequired || typeof this.value === 'boolean';
		},
		showError: function () {
			return this.modified && !this.isValid;
		},
	},
};
</script>
