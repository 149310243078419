import Field from '@assets/scripts/components/field';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_EXTERNAL_CONNECTOR_BY_ID } from '@modules/FlowBuilder/endpoints';

/**
 * Get the defined output for an External connector
 * with a given GUID
 *
 * @param {String} guid
 *  GUID of External Connector
 *
 * @returns {Array}
 */
export const getOutputForExternalConnector = async ({ method_guid }) => {
	let result = false;

	if (method_guid) {
		// load external Connector definition
		const externalConnector = await useApiAsync(GET_EXTERNAL_CONNECTOR_BY_ID, {
			keys: {
				guid: method_guid
			}
		});

		if (externalConnector) {
			const output = [];
			const addedParents = [];

			// loop over output
			externalConnector.output.forEach((field) => {
				output.push(field);

				// find all ancestor names of the field and loop over it
				Field.getAllAncestorNames(field.name).forEach((ancestor) => {
					// check if ancestor is not added yet, for instance if another
					// field has the same ancestor
					if (addedParents.includes(ancestor.toLowerCase())) return;

					// add ancestor to result
					output.push(Field.createNewParent(ancestor));

					addedParents.push(ancestor.toLowerCase());
				});
			});

			// set result
			result = output;
		}
	}

	return result;
};
