/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
export default {
    pageTitle: 'Composed Document',
	composedDocumentList: {
		title: 'Composed Document Builder',
	},
	docList: {
		addComposedDocument: 'Add composed document',
		table: {
			noResults: 'No composed documents found',
			columns: {
				doc_name: 'Name',
				is_read: 'Is read',
				root_child: 'Root document',
				last_time_edited: 'Last time edited',
				status: 'Status',
			},
		},
		delete: {
			confirm: {
				title: 'Delete this composed document?',
				body: 'Are you sure you want to delete this composed document?',
				confirmButtonText: 'Delete',
			},
		},
	},
	newComposedDocument: {
		title: 'Create new composed document',
		whatName: 'Give your composed document a name',
		giveDescription: 'Add a description for the new composed document',
		nameInputLabel: 'Composed document name',
		whatConnection: 'Which connection will the composed document be part of?',
		selectConnection: 'Select connection',
		submitButton: 'Next',
		createdSuccess: 'Succesfully created new Composed Document',
	},
	documentDelete: {
		error: 'Something went wrong while deleting Composed document. Please try again.',
		success: 'Composed document was succesfully deleted',
	},
	error: {
		loadComposedDocument: 'Document was not found',
	},
	validation: {
		nameEmpty: 'Composed document name can not be empty',
		nameIsShort: 'Composed document name should be at least 2 characters',
		nameContainsSpace: 'Composed document name contains a space',
		nameNotUnique: 'Composed document name already exists within connection',
		rootDocumentNotChosen: 'Root document is not selected',
		documentMethodNotChosen: 'No method choosen for document <strong>{documentName}</strong>',
		atleastOneChildChoosen: 'At least one child document should be selected',
		childNameIsEmpty: 'Child name is empty for document <strong>{documentName}</strong>',
		childFieldIsEmpty: 'Child field is empty for document <strong>{documentName}</strong>',
		keyFieldIsEmpty: 'Key field is empty for document <strong>{documentName}</strong>',
		referencingDocumentIsWrong: 'Document <strong>{documentName}</strong> has wrong reference document',
		childNameIsNotUnique: 'Child name <strong>{childName}</strong> is used more than one time',
	},
	documentDetails: {
		previewTitle: 'Composed document JSON',
		previewButton: 'Preview JSON',
		backToOverview: 'Back to overview',
		documentName: 'Composed document name',
		documentSettings: 'Composed document settings',
		description: 'Description',
		close: {
			confirm: {
				title: 'Go back to composed document overview?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you close this overlay.',
				confirmButtonText: 'Ok',
			},
		},
		documents: 'Documents',
		documentTable: {
			columns: {
				document: 'Document',
				childName: 'Child name',
				method: 'Method',
			},
			chooseDocument: 'Choose document',
			chooseMethod: 'Choose method',
			childName: 'Child name',
			childNameLabel: 'Child name',
			noResults: 'No documents added',
		},
		savedSuccessfully: 'Composed document was successfully saved',
		publish: {
			success: 'Composed document was successfully published',
			confirm: {
				title: 'Publish the composed document?',
				body: 'Are you sure you want to publish the composed document? This action can not be undone.',
				confirmButtonText: 'Publish',
			},
		},
		settings: {
			is_read: 'Is read',
			is_read_info: 'Is this composed document only retrieving data? When true then action in rest flow is a read action.',
		},
		delete: {
			confirm: {
				title: 'Delete this document?',
				body: 'Are you sure you want to delete this document?',
				confirmButtonText: 'Delete',
			},
		},
	},
	permissions: {
		label: 'Composed document',
		info: 'Permissions connected to the Composed Document builder',
		readComposedDocument: {
			label: 'Read composed document',
			info: 'Permission to view all composed documents',
		},
		editComposedDocument: {
			label: 'Edit composed document',
			info: 'Permission to create, update and publish composed documents',
		},
		deleteComposedDocumentDraft: {
			label: 'Delete composed document draft',
			info: 'Permission to delete any composed document draft',
		},
	}
};
