/**
 * File offers a shortcut helper function for adding
 * and removing the global loader animation
 */
import { getStoreMutation } from '@assets/scripts/store/config';
import { store } from '@assets/scripts/components/store-proxy';

export default (key, status = true) => {
	if (!store) return;

	store.commit(getStoreMutation(status ? 'ADD_LOADER' : 'STOP_LOADER'), key, {
		root: true,
	});
};
