import {
	createNewBlock,
	getBlockGuid,
	isSplittingBlock,
	getBlockConnections,
	getBlockChildren,
	updateBlockConnection,
	blockCanBeDeleted,
	blockCanBeDeletedByUser,
	blockIsOfType,
	calculateBlockOutput,
	validateBlock,
	flattenBlocks
} from '@modules/FlowBuilder/components/block';
import {
	addBlockAppendFieldMeta,
	startBlockInputFieldMeta,
} from '@modules/FlowBuilder/endpoints';
import { log } from '@assets/scripts/components/notifications';
import {
	getStoreGetter,
	getStoreMutation,
	getStoreAction,
} from '@assets/scripts/store/config';
import Helpers from '@assets/scripts/helpers';
import setLoader from '@assets/scripts/store/loader';
import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

export const names = {
	INIT: 'init',
	NEW_BLOCK_SELECTED: 'newBlockSelected',
	BLOCK_DELETED: 'blockDeleted',
	SAVE_CURRENT_BLOCK: 'saveCurrentBlock',
	ADD_START_FIELD: 'addStartField',
	ADD_APPEND_FIELD: 'addAppendField',
	ADD_PREDEFINED_START_FIELDS: 'addPredefinedStartFields',
	ADD_RESULT_OUTPUT_FIELDS: 'addResultOutputFields',
	UPDATE_FIELD: 'updateField',
	UPDATE_BLOCK_OUTPUT_RECURSIVE: 'updateBlockOutputRecursive',
	VALIDATE_BLOCK_RECURSIVE: 'validateBlockRecursive',
};

export default {
	/**
	 * Action to (re-)init blocks module with a
	 * new Flow
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Object} flow
	 *  Retrieved flow from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.INIT]({ commit, dispatch }, flow) {
		if (!Helpers.obj.getProp('flow', flow)) return;

		// reset module state
		commit(getStoreMutation('RESET', 'BLOCKS'), false, {
			root: true,
		});

		// store some info in state
		commit(getStoreMutation('SET_IDS', 'BLOCKS'), flow, {
			root: true,
		});

		// flatten parent/child relations in blocks wehere needed
		const blocks = flattenBlocks(flow.flow.blocks);

		// add flow blocks to state
		commit(
			getStoreMutation('UPDATE_BLOCKS', 'BLOCKS'),
			{ blocksToAdd: blocks },
			{ root: true }
		);

		// get GUID of start block
		const startGuid = this.getters[getStoreGetter('START_GUID', 'BLOCKS')];

		// get Object of start block
		const startBlock =
			this.getters[getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')](startGuid);

		// set output of block recursively, starting with
		// the start block
		dispatch(
			getStoreAction('UPDATE_BLOCK_OUTPUT_RECURSIVE', 'BLOCKS'),
			{ block: startBlock },
			{
				root: true,
			}
		);
	},
	/**
	 * Creates block(s) and connections to add to
	 * the flow after user selected the type of block to
	 * add. Function also:
	 *  - adds additional Error or Close block if user
	 *    has selected a new Check block to be added.
	 *  - removes Error block if user selected a position
	 *    between Check and Error block
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {String} type
	 *  Type of block to add to flow
	 *
	 * @returns {void}
	 */
	[names.NEW_BLOCK_SELECTED]({ commit, state, dispatch }, type) {
		// show loader
		setLoader('new-block');

		// setTimeout is needed becouse we use async functions and we want the dom to be updated to show the loader first
		// setTimeout without time given we use 1 ms just to be sure that it works everywhere
		// we should fix this with better solution in the future because the async function for a reason are blocking the dom update
		setTimeout(() => {
			// array of new blocks to add to flow
			const blocksToAdd = [];

			// array of blocks to remove from flow
			const blocksToDelete = [];

			// GUID of optional new error block
			let errorGuid = false;

			// GUID of optional new close block
			let closeGuid = false;

			// get options for position of new block from store state
			const { srcGuid, trgtGuid, isFalseExit, isAfterResult } = {
				...state.newBlockOptions,
			};

			// create new block
			const newBlock = createNewBlock(type, [srcGuid], [trgtGuid]);

			// get GUID of new block
			const newGuid = getBlockGuid(newBlock);

			// update Out/OutFalse connection of parent
			// of new block
			commit(
				getStoreMutation('UPDATE_BLOCK_CONNECTIONS', 'BLOCKS'),
				{
					guid: srcGuid,
					newGuid: newGuid,
					// determine exit of parent block to connect to
					connection: isFalseExit ? 'FALSE' : 'OUT',
					replace: trgtGuid,
				},
				{ root: true }
			);

			// if new block is a Check Block:
			//  1) the TRUE exit of the new block will connect
			//     to the given child (trgtGuid)
			//
			//  2) a new Error block will be placed below
			//     the FALSE exit of the new block if the new
			//     block is above the Result block in the Flow
			//
			//  3) a new Close block will be placed below
			//     the FALSE exit of the new block if the new
			//     block is below the Result block in the Flow
			if (isSplittingBlock(newBlock)) {
				if (isAfterResult) {
					// create new close block
					const closeBlock = createNewBlock('CLOSE', [newGuid]);

					// get GUID of new close block
					closeGuid = getBlockGuid(closeBlock);

					// add close block to list of blocks
					// that will be added to flow
					blocksToAdd.push(closeBlock);
				} else {
					// create new error block
					const errorBlock = createNewBlock('ERROR', [newGuid]);

					// get GUID of new error block
					errorGuid = getBlockGuid(errorBlock);

					// add error block to list of blocks
					// that will be added to flow
					blocksToAdd.push(errorBlock);
				}
			}

			// get target block
			const targetBlock =
				this.getters[getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')](trgtGuid);

			// check if target block is an Error block
			if (targetBlock && blockIsOfType(targetBlock, 'ERROR')) {
				// indicate that target block must be deleted
				blocksToDelete.push(targetBlock);

				// get GUID of result block
				const resultGuid =
					this.getters[getStoreGetter('RESULT_GUID', 'BLOCKS')];

				// update In connection of Result block
				commit(
					getStoreMutation('UPDATE_BLOCK_CONNECTIONS', 'BLOCKS'),
					{
						guid: resultGuid,
						newGuid: newGuid,
						connection: 'IN',
						replace: false,
					},
					{ root: true }
				);

				// change OUT connection of new block
				// to result block, since target block will
				// be deleted
				updateBlockConnection(newBlock, resultGuid, 'OUT', trgtGuid);
			} else {
				// update In connection of child
				// of new block
				commit(
					getStoreMutation('UPDATE_BLOCK_CONNECTIONS', 'BLOCKS'),
					{
						guid: trgtGuid,
						newGuid: newGuid,
						connection: 'IN',
						replace: srcGuid,
					},
					{ root: true }
				);
			}

			// set FALSE exit of new block in case a new
			// error block or a close block has additionally
			// been added
			if (errorGuid || closeGuid) {
				updateBlockConnection(
					newBlock,
					errorGuid || closeGuid,
					'FALSE',
					false
				);
			}

			// add new block to list of blocks to add to flow
			blocksToAdd.push(newBlock);

			// commit mutation to store to update the list of blocks
			commit(
				getStoreMutation('UPDATE_BLOCKS', 'BLOCKS'),
				{ blocksToAdd, blocksToDelete },
				{
					root: true,
				}
			);

			// set output of new block recursively
			dispatch(
				getStoreAction('UPDATE_BLOCK_OUTPUT_RECURSIVE', 'BLOCKS'),
				{
					block: newBlock,
					input: this.getters[getStoreGetter('BLOCK_INPUT', 'BLOCKS')](
						newGuid
					),
				},
				{
					root: true,
				}
			);

			// commit mutation to mark flow as modified
			commit(
				getStoreMutation('FLOW_MODIFIED', 'FLOW'),
				{},
				{
					root: true,
				}
			);

			// remove loader
			setLoader('new-block', false);
		}, 1);
	},
	/**
	 * Deletes block chosen by user from the flow
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Object} block
	 *  Block to delete
	 *
	 * @returns {void}
	 */
	[names.BLOCK_DELETED]({ commit, dispatch }, block) {
		if (!blockCanBeDeletedByUser(block)) return;

		// show loader
		setLoader('delete-block');

		// setTimeout is needed becouse we use async functions and we want the dom to be updated to show the loader first
		// setTimeout without time given we use 1 ms just to be sure that it works everywhere
		// we should fix this with better solution in the future because the async function for a reason are blocking the dom update
		setTimeout(() => {
			// get GUID of block to delete
			const guid = getBlockGuid(block);

			// add block-to-be-deleted to array of blocks to delete
			const blocksToDelete = [block];

			// prepare array of blocks to add
			const blocksToAdd = [];

			// get GUID of result block
			const resultGuid =
				this.getters[getStoreGetter('RESULT_GUID', 'BLOCKS')];

			// check if block is splitting block,
			// which means it has multiple exits
			// if so, all children on the FALSE side
			// of the block will be deleted as well,
			// the result block excluded
			if (isSplittingBlock(block)) {
				/**
				 * Helper function to recursively delete a given
				 * block and all it's children
				 *
				 * @param {Object} block
				 *  Block to delete
				 *
				 * @param {Object} parent
				 *  Parent of block to delete
				 *
				 * @returns {void}
				 */
				const recursivelyDeleteBlock = (block, parent) => {
					// check if given block can be deleted
					// i.e. is not a Start or Result block etc.
					if (blockCanBeDeleted(block)) {
						// add block to array of blocks to delete
						blocksToDelete.push(block);

						// loop over children on both sides of the block
						getBlockChildren(block).forEach((childGuid) => {
							// get child block
							const childBlock =
								this.getters[
									getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')
								](childGuid);

							// recursively delete child block as well
							recursivelyDeleteBlock(childBlock, block);
						});
					} else {
						// if block can not be deleted, the parent's GUID
						// must be removed from the IN port
						commit(
							getStoreMutation('UPDATE_BLOCK_CONNECTIONS', 'BLOCKS'),
							{
								guid: getBlockGuid(block),
								newGuid: false, // do not replace it with another GUID
								connection: 'IN',
								replace: getBlockGuid(parent),
							},
							{ root: true }
						);
					}
				};

				// loop over all children on the FALSE side
				// of the block-to-be-deleted
				getBlockConnections(block, 'FALSE').forEach((childGuid) => {
					// get child block
					const childBlock =
						this.getters[getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')](
							childGuid
						);

					// recursively delete child block
					recursivelyDeleteBlock(childBlock, block);
				});
			}

			// get all block children from the OUT (true) port,
			// these children will be directly connected to the
			// parents of the block-to-be-deleted
			const childrenGuids = getBlockConnections(block, 'OUT');

			// loop over children GUIDs
			childrenGuids.forEach((childGuid) => {
				// remove block-to-be-deleted from child IN port
				commit(
					getStoreMutation('UPDATE_BLOCK_CONNECTIONS', 'BLOCKS'),
					{
						guid: childGuid,
						newGuid: false,
						connection: 'IN',
						replace: guid,
					},
					{ root: true }
				);
			});

			// loop over parent GUIDs
			getBlockConnections(block, 'IN').forEach((parentGuid) => {
				// get parent block
				const parentBlock =
					this.getters[getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')](
						parentGuid
					);

				// determine port of parent block to update
				const isFalseExit =
					getBlockConnections(parentBlock, 'FALSE').indexOf(guid) !== -1;

				// remove block-to-be-deleted from parent OUT or FALSE ports
				commit(
					getStoreMutation('UPDATE_BLOCK_CONNECTIONS', 'BLOCKS'),
					{
						guid: parentGuid,
						newGuid: false,
						connection: isFalseExit ? 'FALSE' : 'OUT',
						replace: guid,
					},
					{ root: true }
				);

				// loop over children GUIDs
				childrenGuids.forEach((childGuid) => {
					// check if block-to-be-deleted is located between
					// FALSE port of check block and result block
					if (isFalseExit && childGuid === resultGuid) {
						// if so, create a new Error block
						const newErrorBlock = createNewBlock('ERROR', [parentGuid]);
						blocksToAdd.push(newErrorBlock);

						// get GUID of new block
						const newErrorBlockGuid = getBlockGuid(newErrorBlock);

						// update OutFalse connection of parent
						// to link to new Error block
						commit(
							getStoreMutation('UPDATE_BLOCK_CONNECTIONS', 'BLOCKS'),
							{
								guid: parentGuid,
								newGuid: newErrorBlockGuid,
								connection: 'FALSE',
								replace: false,
							},
							{ root: true }
						);
					} else {
						// update Out/OutFalse connection of parent
						// of block-to-be-deleted
						commit(
							getStoreMutation('UPDATE_BLOCK_CONNECTIONS', 'BLOCKS'),
							{
								guid: parentGuid,
								newGuid: childGuid,
								connection: isFalseExit ? 'FALSE' : 'OUT',
								replace: false,
							},
							{ root: true }
						);

						// update In connection of child
						// of block-to-be-deleted
						commit(
							getStoreMutation('UPDATE_BLOCK_CONNECTIONS', 'BLOCKS'),
							{
								guid: childGuid,
								newGuid: parentGuid,
								connection: 'IN',
								replace: false,
							},
							{ root: true }
						);
					}
				});
			});

			// commit mutation to store to update the list of blocks
			commit(
				getStoreMutation('UPDATE_BLOCKS', 'BLOCKS'),
				{ blocksToAdd, blocksToDelete },
				{
					root: true,
				}
			);

			// loop over children GUIDs again to update block outputs
			childrenGuids.forEach((childGuid) => {
				// set output of block recursively
				dispatch(
					getStoreAction('UPDATE_BLOCK_OUTPUT_RECURSIVE', 'BLOCKS'),
					{
						block: this.getters[
							getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')
						](childGuid),
						input: this.getters[
							getStoreGetter('BLOCK_INPUT', 'BLOCKS')
						](childGuid),
					},
					{
						root: true,
					}
				);
			});

			// commit mutation to mark flow as modified
			commit(
				getStoreMutation('FLOW_MODIFIED', 'FLOW'),
				{},
				{
					root: true,
				}
			);

			// stop loader
			setLoader('delete-block', false);
		}, 1);
	},
	[names.SAVE_CURRENT_BLOCK]({ commit, dispatch }) {
		// commit mutation to save current block
		commit(
			getStoreMutation('SAVE_CURRENT_BLOCK', 'BLOCKS'),
			{},
			{
				root: true,
			}
		);

		// commit mutation to mark current flow as modified
		commit(
			getStoreMutation('FLOW_MODIFIED', 'FLOW'),
			{},
			{
				root: true,
			}
		);

		try {
			// get current block
			const currentBlock =
				this.getters[getStoreGetter('CURRENT_BLOCK', 'BLOCKS')];

			// get guid of current block
			const guid = getBlockGuid(currentBlock);

			// get actual block, since currentBlock is a clone, not a reference
			const block =
				this.getters[getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')](guid);

			// get input for current block
			const input =
				this.getters[getStoreGetter('BLOCK_INPUT', 'BLOCKS')](guid);

			// set output of saved block recursively
			dispatch(
				getStoreAction('UPDATE_BLOCK_OUTPUT_RECURSIVE', 'BLOCKS'),
				{
					block,
					input,
				},
				{
					root: true,
				}
			);
		} catch (err) {}

		// commit mutation to store to close current block overlay
		commit(
			getStoreMutation('CLOSE_BLOCK_DETAILS', 'BLOCKS'),
			{},
			{
				root: true,
			}
		);
	},
	/**
	 * Adds a new field to the current block IF
	 * that block is a start block
	 * N.B.: Field is added to Current block in module state
	 * which is not part of a flow. Only after Save by user any
	 * changes will be moved to current flow.
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {String} name
	 *  Name of Start field to add
	 */
	[names.ADD_START_FIELD]({ commit }, name) {
		// create new field with given name
		const newField = Field.createNew(
			{ name },
			startBlockInputFieldMeta,
			true
		);

		// add new field to start block fields
		commit(
			getStoreMutation('ADD_FIELD', 'BLOCKS'),
			{ field: newField, type: 'start' },
			{
				root: true,
			}
		);

		// mark current block as modified
		commit(
			getStoreMutation('BLOCK_CONFIG_MODIFIED', 'BLOCKS'),
			{},
			{
				root: true,
			}
		);

		// get start fields for current block
		const fields =
			this.getters[getStoreGetter('START_BLOCK_FIELDS', 'BLOCKS')];

		// open drawer with field information of last field
		commit(
			getStoreMutation('OPEN_DRAWER'),
			{
				type: 'startFieldDetails',
				data: fields.length - 1,
			},
			{
				root: true,
			}
		);

		// show success message
		log(
			t('field.newCreated', {
				field: Field.getNameAsPath(name),
			}),
			'success'
		);
	},
	/**
	 * Adds a new field to the current block IF
	 * that block is an append block
	 * N.B.: Field is added to Current block in module state
	 * which is not part of a flow. Only after Save by user any
	 * changes will be moved to current flow.
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {String} name
	 *  Name of Start field to add
	 */
	[names.ADD_APPEND_FIELD]({ commit }, name) {
		// create new field with given name
		const newField = Field.createNew(
			{ name },
			addBlockAppendFieldMeta,
			true
		);

		// add new field to add block fields
		commit(
			getStoreMutation('ADD_FIELD', 'BLOCKS'),
			{ field: newField, type: 'add' },
			{ root: true }
		);

		// mark current block as modified
		commit(
			getStoreMutation('BLOCK_CONFIG_MODIFIED', 'BLOCKS'),
			{},
			{ root: true }
		);

		// get start fields for current block
		const fields =
			this.getters[getStoreGetter('ADD_BLOCK_FIELDS', 'BLOCKS')];

		// open drawer with field information of last field
		commit(
			getStoreMutation('OPEN_DRAWER'),
			{
				type: 'addFieldDetails',
				data: fields.length - 1,
			},
			{ root: true }
		);

		// show success message
		log(
			t('field.newCreated', {
				field: Field.getNameAsPath(name),
			}),
			'success'
		);
	},
	/**
	 * Adds selected predefined field to the current block IF
	 * that block is a start block
	 * N.B.: Fields are added to Current block in module state
	 * which is not part of a flow. Only after Save by user any
	 * changes will be moved to current flow.
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Array} fields
	 *  Array of predefined fields to add
	 */
	[names.ADD_PREDEFINED_START_FIELDS]({ commit }, fields = []) {
		// loop over fields
		fields.forEach((field) => {
			// create new field with given name
			const newField = Field.createNew(field);

			// add new field to start block fields
			commit(
				getStoreMutation('ADD_FIELD', 'BLOCKS'),
				{ field: newField, type: 'start' },
				{ root: true }
			);
		});
		// show success message
		log(
			t('fb.predefinedFields.newAdded', {
				count: fields.length,
			}),
			'success'
		);

		// mark current block as modified
		commit(
			getStoreMutation('BLOCK_CONFIG_MODIFIED', 'BLOCKS'),
			{},
			{ root: true }
		);
	},
	/**
	 * Adds selected output fields to the current block IF
	 * that block is a result block
	 * N.B.: Fields are added to Current block in module state
	 * which is not part of a flow. Only after Save by user any
	 * changes will be moved to current flow.
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Array} fields
	 *  Array of output fields to add
	 */
	[names.ADD_RESULT_OUTPUT_FIELDS]({ commit }, fields = []) {
		// loop over fields
		fields.forEach((field) => {
			// create new output field with given name
			// and alias set to Child name by default
			const newField = {
				name: field.name,
				alias: Field.getChildName(field.name),
			};

			// add new field to result block fields
			commit(
				getStoreMutation('ADD_FIELD', 'BLOCKS'),
				{ field: newField, type: 'result' },
				{ root: true }
			);
		});

		// show success message
		log(
			t('fb.predefinedFields.newAdded', {
				count: fields.length,
			}),
			'success'
		);

		// mark current block as modified
		commit(
			getStoreMutation('BLOCK_CONFIG_MODIFIED', 'BLOCKS'),
			{},
			{
				root: true,
			}
		);
	},
	/**
	 * Updates an existing field of the current block IF
	 * that block is a start or add block
	 * N.B.: Field is updated in Current block in module state
	 * which is not part of a flow. Only after Save by user any
	 * changes will be moved to current flow.
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Object} field
	 *  Field object to save
	 *
	 * @param {Integer} key
	 *  Key of field to update in field list
	 *
	 * @param {String} type
	 *  Type of field, either 'start' or 'add'
	 *
	 * @param {String} drawerId
	 *  ID of drawer to close after successful update
	 */
	[names.UPDATE_FIELD](
		{ commit },
		{ field, key, type = 'start', drawerId = false }
	) {
		// prepare parameters for update mutation
		// success of update is based on value of 'result'
		// property in parameters, since mutations do not
		// return anything
		const param = {
			field,
			key,
			type,
			result: false,
		};

		// save field
		commit(getStoreMutation('UPDATE_FIELD', 'BLOCKS'), param, {
			root: true,
		});

		if (!param.result) {
			// show error
			log(t('error.updateError'), 'danger');
			return;
		}

		// close drawer
		if (drawerId !== false)
			commit(getStoreMutation('CLOSE_DRAWER'), drawerId, {
				root: true,
			});

		// mark current block as modified
		commit(
			getStoreMutation('BLOCK_CONFIG_MODIFIED', 'BLOCKS'),
			{},
			{
				root: true,
			}
		);

		// show success message
		log(t('field.updateSuccess'), 'success');
	},
	/**
	 * Recursively calculates and sets output
	 * for a given block
	 *
	 * @param {Object} block
	 *  Block to set output for
	 *
	 *  Input for Block
	 * @param {Object} input
	 *
	 * @returns {void}
	 */
	[names.UPDATE_BLOCK_OUTPUT_RECURSIVE](store, { block, input = [] }) {
		// show loader
		setLoader('update-recursive');

		// setTimeout is needed becouse we use async functions and we want the dom to be updated to show the loader first
		// setTimeout without time given we use 1 ms just to be sure that it works everywhere
		// we should fix this with better solution in the future because the async function for a reason are blocking the dom update
		setTimeout(async () => {
			const setOutputRecursive = async (block, input) => {
				// get calculated output for block
				const output = await calculateBlockOutput(block, input);
	
				// set block output to calculated value
				this.commit(
					getStoreMutation('UPDATE_BLOCK_OUTPUT', 'BLOCKS'),
					{ block, output },
					{
						root: true,
					}
				);
	
				// loop over children
				for (const childGuid of getBlockChildren(block)) {
					// retrieve block object for child
					const child =
						this.getters[getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')](
							childGuid
						);
	
					// retrieve input for child
					const childInput =
						this.getters[getStoreGetter('BLOCK_INPUT', 'BLOCKS')](
							childGuid
						);
	
					// set output for child
					await setOutputRecursive(child, childInput);
				}
			};
	
			await setOutputRecursive(block, input);
	
			// stop loader
			setLoader('update-recursive', false);
			
		}, 1);
	},
	/**
	 * Recursively validation
	 * for a given block
	 *
	 * @param {String} guid
	 *  guid of the block to validate
	 *
	 * @returns {void}
	 */
	async [names.VALIDATE_BLOCK_RECURSIVE](store, guid) {
		// get Object of start block
		const block =
			this.getters[getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')](guid);
		const alreadyCheckedBlocks = [];
		let validationErrors = [];
		const validateRecursive = async (block) => {
			if(!alreadyCheckedBlocks.includes(block.guid)){
				alreadyCheckedBlocks.push(block.guid);
				validationErrors = await validateBlock(block);
			

				// loop errors and add to the flowErrors array
				validationErrors.forEach((error) => {
					this.commit(
						getStoreMutation('ADD_VALIDATION_ERROR'),
						error,
						{
							root: true,
						}
					);
				});
				// loop over children
				for (const childGuid of getBlockChildren(block)) {
					// retrieve block object for child
					const child =
						this.getters[getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')](
							childGuid
						);

					// validate child
					await validateRecursive(child);
				}
			}
		};

		await validateRecursive(block);
	},
};
