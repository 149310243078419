<template>
	<VOverlay
		class="confirmation-popup"
		:hasBackground="true"
		:hasJsonButton="false"
		:hasCloseButton="false"
		:closeOnBackgroundClick="true"
		:isVCentered="false"
		:size="2"
		:icon="confirmation.icon || null"
		:title="confirmation.title || null"
		align=""
		:modelValue="true"
		@update:modelValue="cancelFunction"
	>
		{{ confirmation.body }}

		<template #footer>
			<VButton
				v-if="confirmation.confirmButtonText"
				:text="confirmation.confirmButtonText"
				variant="is-primary"
				@clicked.prevent.stop="confirmFunction"
				ref="confirm"
			/>
			<VButton
				v-if="confirmation.cancelButtonText"
				:text="confirmation.cancelButtonText"
				variant="is-secondary"
				@clicked.prevent.stop="cancelFunction"
				ref="cancel"
			/>
		</template>
	</VOverlay>
</template>

<script>
import { getStoreAction, getStoreGetter } from '@assets/scripts/store/config';
import { useStore } from 'vuex';
import { ref } from 'vue'
import { useFocus } from '@vueuse/core'

export default {
	name: 'TheConfirmation',
	setup: function() {
		const store = useStore();

		const state = {
			confirmation: store.getters[getStoreGetter('CONFIRMATION')],
		};

		const confirm = ref();
		const cancel = ref();

		// default focus target is cancel button
		let focusTarget = confirm;

		// use the target from confirmation if exist
		if(state.confirmation.focusTarget === 'cancel'){
			focusTarget = cancel;
		}

		// set initial focus on the target using useFocus from @vueuse/core
		useFocus(focusTarget, { initialValue: true });

		return {
			...state, confirm, cancel,
		};
	},
	methods: {
		cancelFunction: function () {
			this.$store.dispatch(getStoreAction('CANCEL_CONFIRMATION'));
		},
		confirmFunction: function () {
			this.$store.dispatch(getStoreAction('CONFIRM'));
		},
	},
};
</script>
