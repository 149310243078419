import getDefaultState from './state';
import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import usePermission from '@assets/scripts/composables/usePermission';

export const names = {
	TOGGLE_CREATE_NEW: 'toggleCreateNewMethod',
	SET_CURRENT_METHOD: 'setCurrentMethod',
	UNSET_METHOD: 'unsetCurrentMethod',
	SET_CURRENT_CONNECTION: 'setCurrentConnection',
	MARK_MODIFIED: 'markModified',
	SET_PUBLISHED_STATE: 'setPublishedState',
	SAVE_FIELD: 'saveField',
	SET_NAME: 'setName',
	RESET: 'reset',
};

export default {
	[names.TOGGLE_CREATE_NEW](state, status = true) {
		if (state.showCreateNew !== status) state.showCreateNew = status;
	},
	/**
	 * Sets the current method to newly retrieved method
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} method
	 *  Retrieved method from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_METHOD](state, method) {
		// save method to state
		state.currentMethod = method;

		// a newly loaded method is not modified yet
		state.modified = false;
	},
	/**
	 * Unsets the current method
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.UNSET_METHOD](state) {
		// unset current method in state
		state.currentMethod = {};

		// unset modified marker
		state.modified = false;
	},
	/**
	 * Sets the current connection to given connection
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} connection
	 *  Retrieved connection from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_CONNECTION](state, connection) {
		// save connection guid to state
		state.currentConnection = connection.conn_guid;
	},
	/**
	 * Marks the current method as modified
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.MARK_MODIFIED](state) {
		state.modified = true;

		// every change to the method means that the method should be validated again
		this.commit(
			getStoreMutation('SET_IS_VALID'), false, { root: true }
		);
	},
	/**
	 * Updates the published state of the current method
	 *
	 * @param {Object} currentMethod
	 *	Currently active method
	 *
	 * @param {Boolean} status
	 *  Boolean indicating whether current method is
	 *  published
	 */
	[names.SET_PUBLISHED_STATE]({ currentMethod }, status = false) {
		currentMethod.is_published = status;
	},
	/**
	 * Updates a Method field with a given key of
	 * the currently viewed/edited method, or adds
	 * a new field to it
	 *
	 * @param {Object} state
	 *  Ref to module state
	 *
	 * @param {Object} param
	 *  Parameters, not destructured since the object
	 *  is used to signal success/failure back to
	 *  caller function
	 *
	 *   @param {Object} field
	 *    Field object to save
	 *   @param {Integer} key
	 *    Key of field to update in field list, or false
	 *    to trigger insert of new field
	 *   @param {Boolean} isOutput
	 *    Indicates whether the field is part of the Output of
	 *    the method
	 *   @param {Boolean} result
	 *    Indicates success/failure of update
	 */
	[names.SAVE_FIELD](state, param) {
		const { field, isOutput, key } = param;

		const method = this.getters[getStoreGetter('CURRENT_METHOD', 'METHOD')];

		if (!method) {
			param.result = false;
			return;
		}

		// get current fields
		const fields = isOutput ? method.output : method.input;

		if (key === false) {
			// add new field
			fields.push(field);
			// mark insert as success
			param.result = true;
		} else if (typeof fields[key] !== 'undefined') {
			// replace field with new field
			fields[key] = field;
			// mark update as success
			param.result = true;
		} else {
			// mark update as failed
			param.result = false;
		}
	},
	/**
	 * Updates the name of the currently active method
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {String} newName
	 *  Name to set for method
	 *
	 * @returns {void}
	 */
	[names.SET_NAME]({ currentMethod }, newName) {
		// check if currently in edit mode
		if (!usePermission('Upsert', 'MethodBuilder')) return;

		// set name on method
		currentMethod.name = newName;

		// mark document as modified
		this.commit(
			getStoreMutation('MARK_MODIFIED', 'METHOD'), null, { root: true }
		);
	},
	/**
	 * Resets module state to default
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @returns {void}
	 */
	[names.RESET](state) {
		Object.assign(state, getDefaultState());
	},
};
