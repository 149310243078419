<template>
	<VOverlay
		class="login-overlay"
		:hasBackground="false"
		:hasJsonButton="false"
		:hasCloseButton="false"
		:closeOnBackgroundClick="false"
		v-model="overlayIsActive"
		:modalHasSharpCorner="true"
		:useFocusTrap="false"
		:closeWithEscape="false"
	>
		<template #header>
			<img class="has-margin-bottom-4" src="@assets/images/Logo.svg" />
		</template>

		<VTitle class="has-margin-bottom-3" :size="1">{{
			$t('resetPassword.title')
		}}</VTitle>

		<VNotification
			v-if="errorMessage"
			:text="errorMessage"
			:isFullwidth="false"
			:hasClose="false"
			type="danger"
		/>

		<div class="login-form-wrapper">
			<VField
				:isFloating="true"
				:label="$t('resetPassword.label.newPassword')"
				:class="{ 'is-active': newPassword }"
				:isError="newPasswordError || noMatchError"
				:help="$t('resetPassword.helpText')"
			>
				<VInput
					v-model="newPassword"
					:placeholder="$t('resetPassword.label.newPassword')"
					type="password"
					@keypress.enter.prevent="resetPassword"
					@keyup="testPasswordWithDebounce(newPassword)"
				/>
			</VField>
			<VField
				:isFloating="true"
				:label="$t('resetPassword.label.confirmPassword')"
				:class="{ 'is-active': confirmPassword }"
				:isError="confirmPasswordError || noMatchError"
			>
				<VInput
					v-model="confirmPassword"
					:placeholder="$t('resetPassword.label.confirmPassword')"
					type="password"
					@keypress.enter.prevent="resetPassword"
				/>
			</VField>
		</div>
		<template #footer>
			<VButton
				class="button-modal-footer"
				:text="$t('resetPassword.button')"
				icon="chevron-right"
				@click="resetPassword"
				:iconOnRight="true"
				:disabled="buttonIsDisabled"
			/>
		</template>
	</VOverlay>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { getStoreAction } from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import Helpers from '@assets/scripts/helpers';
import { CHECK_SECRET_KEY } from '@assets/scripts/api/config';

export default {
	name: 'ResetPassword',
	setup() {
		const { t } = useI18n();
		const store = useStore();
		const route = useRoute();

		const state = reactive({
			newPassword: '',
			confirmPassword: '',
			overlayIsActive: true,
			newPasswordError: false,
			noMatchError: false,
			confirmPasswordError: false,
			errorMessage: '',
			testPasswordWithDebounce: Helpers.debounce((password) => {
				testPassword(password);
			}, 500),
		});

		const testPassword = (password) => {
			const strongRegex = new RegExp(
				'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})'
			);
			if (!strongRegex.test(password)) {
				state.newPasswordError = true;
				state.errorMessage = t(
					'resetPassword.passwordNoMatchRequirements'
				);
			} else {
				state.newPasswordError = false;
				state.errorMessage = '';
			}
		};

		// check new password
		const resetPassword = () => {
			if (state.newPassword.length < 1 || state.newPasswordError) {
				state.newPasswordError = true;
				state.errorMessage = t('resetPassword.noNewPassword');
			} else if (state.confirmPassword.length < 1) {
				state.confirmPasswordError = true;
				state.errorMessage = t('resetPassword.noConfirmPassword');
			} else if (state.newPassword !== state.confirmPassword) {
				state.noMatchError = true;
				state.errorMessage = t('resetPassword.passwordNoMatch');
			} else {
				state.newPasswordError = false;
				state.noMatchError = false;
				state.confirmPasswordError = false;
				state.errorMessage = '';

				store
					.dispatch(getStoreAction('RESET_PASSWORD'), {
						newPassword: state.newPassword,
						key: route.query.key,
					})
					.then(() => {
						// success, no further action needed
						state.errorMessage = '';
					})
					.catch((error = false) => {
						// show error in form
						state.errorMessage = error;
					});
			}
		};

		return {
			...toRefs(state),
			resetPassword,
		};
	},
	mounted: async function () {
		const route = useRoute();
		const router = useRouter();
		// check secret key 
		const checkSecretKey = await useApiAsync(CHECK_SECRET_KEY, {
			parameters: {
				key: route.query.key,
			}
		});

		if(!checkSecretKey) {
			// redirect to request password page
			router.push({ name: 'requestPassword' })
		}
	},
	computed: {
		buttonIsDisabled() {
			return !(
				!this.newPasswordError &&
				!!this.newPassword &&
				this.newPassword.length > 0 &&
				!!this.confirmPassword &&
				this.confirmPassword.length > 0
			);
		},
	},
};
</script>
