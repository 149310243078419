import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { log, debug } from '@assets/scripts/components/notifications';
import setLoader from '@assets/scripts/store/loader';
import {
	createNewDocument,
	getDefaultDocumentFields,
	constructFullDocument,
	validateDocument,
} from '@modules/DocumentBuilder/components/connection-document';
import Helpers from '@assets/scripts/helpers';
import i18n from '@assets/i18n';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	GET_DOCUMENT,
	POST_DOCUMENT,
	DELETE_DOCUMENT,
} from '@modules/DocumentBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

export const names = {
	TOGGLE_CREATE_NEW: 'toggleCreateNewDocument',
	NEW_DOCUMENT: 'newDocument',
	LOAD_AND_SHOW_DOCUMENT: 'loadAndShowDocument',
	UNSET_DOCUMENT: 'unsetDocument',
	SAVE_CURRENT_DOCUMENT: 'saveCurrentDocument',
	PUBLISH_CURRENT_DOCUMENT: 'publishCurrentDocument',
	VALIDATE_CURRENT_DOCUMENT: 'validateCurrentDocument',
	DELETE_DOCUMENT: 'deleteDocument',
	DOCUMENT_UPDATED: 'documentUpdated',
	UPDATE_DOCUMENT_LIST: 'updateDocumentList',
	SAVE_FIELD: 'saveField',
	RESET: 'reset',
};

export default {
	[names.TOGGLE_CREATE_NEW]({ commit }, status = true) {
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'DB'), !!status, {
			root: true,
		});
	},
	/**
	 * Action triggered when a new Document must be created
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} settings
	 *  Settings for the new Document
	 *
	 * @returns {void}
	 */
	async [names.NEW_DOCUMENT]({ commit, dispatch }, settings) {
		// show loader
		setLoader('new-document');

		// create new Document object with given settings
		const newDocument = createNewDocument(settings);

		// commit mutation in to set new Document
		commit(getStoreMutation('SET_CURRENT_DOCUMENT', 'DB'), newDocument, {
			root: true,
		});

		// add default fields to Document
		const defaultFields = await getDefaultDocumentFields(newDocument);

		// add all default fields
		if (typeof defaultFields === 'object') {
			Object.values(defaultFields).forEach((field) => {
				dispatch(getStoreAction('SAVE_FIELD', 'DB'), 
					{
						field,
						showMessage : false,
					},
					{ root: true }
				)
			});
		}

		// unset create new
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'DB'), false, {
			root: true,
		});

		// mark new document as modified so it can be saved immediately
		commit(
			getStoreMutation('MARK_MODIFIED', 'DB'),
			{},
			{
				root: true,
			}
		);

		// remove loader
		setLoader('new-document', false);

		// show success message
		log(t('db.newDocument.createdSuccess'), 'success');
	},
	/**
	 * Action triggered to load an existing Document
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} guid
	 *  GUID of Document to load
	 *
	 * @param {String} conn_guid
	 *  Connection GUID of Document
	 *
	 * @returns {void}
	 */
	async [names.LOAD_AND_SHOW_DOCUMENT]({ commit }, { guid, conn_guid }) {
		// load document from api
		const document = await useApiAsync(GET_DOCUMENT, {
			keys: {
				guid,
				connection: conn_guid
			}
		});

		if (!document) {
			log(t('db.error.loadDocument'), 'danger');
			return;
		}

		// commit mutation in root store
		commit(getStoreMutation('SET_CURRENT_DOCUMENT', 'DB'), Helpers.cloneObject(document), {
			root: true,
		});
	},
	[names.UNSET_DOCUMENT]({ commit }) {
		// reset current document
		commit(getStoreMutation('UNSET_DOCUMENT', 'DB'), null, { root: true });

		// unset
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'DB'), false, {
			root: true,
		});
	},
	/**
	 * Action triggered to save the current Document
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.SAVE_CURRENT_DOCUMENT]({ dispatch }) {
		// get current document from store
		const currentDoc = this.getters[getStoreGetter('CURRENT_DOCUMENT', 'DB')];

		// get fully constructed document object
		const document = constructFullDocument(currentDoc);

		// save document
		const result = await useApiAsync(POST_DOCUMENT, {
			keys: {
				guid: currentDoc.guid,
				connection: currentDoc.conn_guid
			},
			parameters: {
				...document
			}
		});

		if (result !== false) {
			debug('succesfully saved', document);
			
			// show success message
			log(t('db.docDetails.savedSuccessfully'), 'success');

			// dispatch action to take needed steps after saving
			dispatch(
				getStoreAction('DOCUMENT_UPDATED', 'DB'),
				currentDoc,
				{ root: true }
			);

			// dispatch action to unset document
			dispatch(getStoreAction('UNSET_DOCUMENT', 'DB'), null, { root: true });
		}
	},
	/**
	 * Action triggered to publish the current Document
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.PUBLISH_CURRENT_DOCUMENT]({ commit, dispatch }) {
		// mark document as published
		commit(getStoreMutation('SET_PUBLISHED_STATE', 'DB'), true, {
			root: true,
		});

		// get current document from store
		const currentDoc = this.getters[getStoreGetter('CURRENT_DOCUMENT', 'DB')];

		// get fully constructed document object
		const document = constructFullDocument(currentDoc);

		// publish document
		const result = await useApiAsync(POST_DOCUMENT, {
			keys: {
				guid: currentDoc.guid,
				connection: currentDoc.conn_guid
			},
			parameters: {
				...document
			}
		});

		if (result !== false) {
			debug('successfully published', document);

			// show success message
			log(t('db.docDetails.publish.success'), 'success');

			// dispatch action to take needed steps after publishing
			dispatch(
				getStoreAction('DOCUMENT_UPDATED', 'DB'),
				currentDoc,
				{ root: true }
			);

			// dispatch action to unset document
			dispatch(getStoreAction('UNSET_DOCUMENT', 'DB'), null, { root: true });
		} else {
			// mark document as not published if anything
			// went wrong
			commit(getStoreMutation('SET_PUBLISHED_STATE', 'DB'), false, {
				root: true,
			});
		}
	},
	/**
	 * Action triggered to validate the current document
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	[names.VALIDATE_CURRENT_DOCUMENT]({ commit, dispatch }) {
		// prepare for validation
		dispatch(getStoreAction('PREPARE_VALIDATION'), null, { root: true });

		// get current document from store
		const currentDoc = this.getters[getStoreGetter('CURRENT_DOCUMENT', 'DB')];

		// setTimeout is needed becouse we use async functions and we want the dom to be updated to show the loader first
		// setTimeout without time given we use 1 ms just to be sure that it works everywhere
		// we should fix this with better solution in the future because the async function for a reason are blocking the dom update
		setTimeout(async () => {
			const errors = await validateDocument(currentDoc);

			// loop errors and add to the errors array
			errors.forEach((error) => {
				commit(
					getStoreMutation('ADD_VALIDATION_ERROR'),
					error,
					{ root: true }
				);
			});

			// finish validation
			dispatch(getStoreAction('FINISH_VALIDATION'), null, { root: true });
		}, 1);
	},
	/**
	 * Action triggered to delete  a document with a
	 * given GUID and Connection GUID
	 *
	 * @param {String} guid
	 *  GUID of document
	 *
	 * @param {String} conn_guid
	 * GUID of Connection
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.DELETE_DOCUMENT]({ dispatch }, { guid, conn_guid }) {

		// load document from api
		const document = await useApiAsync(GET_DOCUMENT, {
			keys: {
				guid,
				connection: conn_guid
			}
		});

		// delete document
		const result = await useApiAsync(DELETE_DOCUMENT, {
			keys: {
				guid,
				connection: conn_guid
			}
		});

		if (result !== false) {
			// show debug message in console
			debug('successful delete', {
				guid,
				conn_guid,
				result,
			});
	
			// dispatch action to take needed steps after deleting
			dispatch(
				getStoreAction('DOCUMENT_UPDATED', 'DB'),
				document,
				{ root: true }
			);
	
			// show success message
			log(t('db.docDelete.success'), 'success');
		} else {
			// show error message
			log(t('db.docDelete.error'), 'danger');
		}
	},
	[names.DOCUMENT_UPDATED]({ dispatch }, document) {
			// dispatch action to reload document list
			dispatch(
				getStoreAction('UPDATE_DOCUMENT_LIST', 'DB'),
				document.conn_guid || false,
				{ root: true }
			);
	},
	[names.UPDATE_DOCUMENT_LIST](store, conn_guid) {
		// do nothing
		// only exists so components can subscribe to it
	},
	/**
	 * Updates an existing field of the current Document or
	 * adds a new field
	 * N.B.: Field is updated/saved in Current Document in module state
	 * Only after Save by user any changes will be moved to
	 * document
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Object} field
	 *  Field object to save
	 *
	 * @param {Integer} key
	 *  Key of field to update in field list
	 *
	 * @param {String} drawerId
	 *  ID of drawer to close after successful update
	 *
	 * @param {Boolean} showMessage
	 *  Indicator whether to show a success message when field
	 *  is saved
	 */
 	[names.SAVE_FIELD](
		{ commit },
		{
			field,
			key = false,
			drawerId = false,
			showMessage = true,
	}
	) {
		// prepare parameters for update mutation
		// success of update is based on value of 'result'
		// property in parameters, since mutations do not
		// return anything
		const param = {
			field,
			key,
			result: false,
		};

		// save field
		commit(getStoreMutation('SAVE_FIELD', 'DB'), param, {
			root: true,
		});

		if (!param.result) {
			// show error
			log(t('error.updateError'), 'danger');
			return;
		}

		// close drawer
		if (drawerId !== false) {
			commit(getStoreMutation('CLOSE_DRAWER'), drawerId, {
				root: true,
			});
		}

		// mark current document as modified
		commit(
			getStoreMutation('MARK_MODIFIED', 'DB'),
			{},
			{
				root: true,
			}
		);

		// show success message
		if (showMessage) log(t('field.updateSuccess'), 'success');
	},
	[names.RESET]({ commit }) {
		// reset state of store
		commit(getStoreMutation('RESET', 'DB'), null, { root: true });
	},
};
