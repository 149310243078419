// naming used in definition of Users
export const userMeta = {
	guid: {
		name: 'UserGuid',
		default: '',
		omitIfEmpty: true,
	},
	api_token: {
		name: 'ApiToken',
		default: '',
		omitIfEmpty: true,
	},
	service_token: {
		name: 'ServiceToken',
		default: '',
		omitIfEmpty: true,
	},
	service_token_date: {
		name: 'ServiceTokenDateTime',
		default: false,
		omitIfEmpty: true,
	},
	email: {
		name: 'EmailAddress',
		default: '',
	},
	first_name: {
		name: 'FirstName',
		default: '',
	},
	last_name: {
		name: 'Surname',
		default: '',
	},
	is_active: {
		name: 'IsActive',
		type: 'boolean',
		default: false,
	},
	is_admin: {
		name: 'IsAdmin',
		type: 'boolean',
		default: false,
	},
	logins: {
		name: 'NrTimesLoggedIn',
		type: 'integer',
		default: 0,
	},
	last_login: {
		name: 'LastLogin',
		default: false,
		omitIfEmpty: true,
	},
	created: {
		name: 'CreatedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	created_by: {
		name: 'CreatedBy',
		default: false,
		omitIfEmpty: true,
	},
	modified: {
		name: 'LastModifiedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	modified_by: {
		name: 'ModifiedBy',
		default: false,
		omitIfEmpty: true,
	},
	is_deleted: {
		name: 'IsDeleted',
		type: 'boolean',
		default: false,
	},
	deleted: {
		name: 'DeletedDateTime',
		default: null,
		omitIfEmpty: true,
	},
	gender_code: {
		name: 'GenderCode',
		default: '',
	},
	two_factor: {
		name: 'TwoFactorEnabled',
		type: 'boolean',
		default: false,
	},
	two_factor_key: {
		name: 'TwoFactorKey',
		default: '',
	},
	roles: {
		name: 'RoleGuids',
		type: 'array',
		default: [],
	},
};

// naming used in definition of Roles
export const roleMeta = {
	name: {
		name: 'RoleName',
		default: '',
	},
	guid: {
		name: 'RoleGuid',
		type: 'integer',
		default: false,
	},
	permissions: {
		name: 'Permissions',
		type: 'object',
		default: {}
	},
};

// File exports all available Endpoints

const apiBase = 'UIServices/UserManagement';

// get all users
export const GET_USERS = {
	path: 'GetUsers',
	realm: apiBase,
	meta: userMeta,
};

// get user
export const GET_USER = {
	path: 'GetUser/{guid}',
	realm: apiBase,
	meta: userMeta,
};

// post or update user
export const POST_USER = {
	path: 'PostUser',
	method: 'post',
	realm: apiBase,
	meta: userMeta,
};

// delete user
export const DELETE_USER = {
	path: 'DeleteUser/{guid}',
	method: 'delete',
	realm: apiBase,
};

// get all roles
export const GET_ROLES = {
	path: 'GetRoles',
	realm: apiBase,
	meta: roleMeta,
};

// get role
export const GET_ROLE = {
	path: 'GetRole/{guid}',
	realm: apiBase,
	meta: roleMeta,
};

// post or update role
export const POST_ROLE = {
	path: 'PostRole',
	method: 'post',
	realm: apiBase,
	meta: roleMeta,
};

// delete role
export const DELETE_ROLE = {
	path: 'DeleteRole/{guid}',
	method: 'delete',
	realm: apiBase,
};

// reset mfa
export const RESET_MFA = {
	path: 'Deactivate2FA',
	method: 'post',
	realm: apiBase,
	parameters: {
		user_guid: 'UserGuid',
	},
};
