import { createI18n } from 'vue-i18n';
import i18nEn from '@assets/i18n/en.js';

export default createI18n({
	legacy: false,
	locale: 'en',
	fallbackLocale: 'en',
	allowComposition: true,
	warnHtmlInMessage: 'off',
	messages: {
		en: i18nEn,
	},
});
