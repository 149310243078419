import { getStoreMutation, getStoreGetter } from '@assets/scripts/store/config';
import getDefaultState from './state';
import { userCanModifyScript } from '@modules/ScriptBuilder/components/script';

export const names = {
	TOGGLE_CREATE_NEW: 'toggleCreateNew',
	MARK_MODIFIED: 'markModified',
	RESET: 'reset',
    UNSET_SCRIPT: 'unsetScript',
    SET_NAME: 'setName',
    SET_CURRENT_SCRIPT: 'setCurrentScript',
    SET_PUBLISHED_STATE: 'setPublishedState',
};

export default {
	[names.TOGGLE_CREATE_NEW](state, status = true) {
		if (state.showCreateNew !== status) state.showCreateNew = status;
	},

    /**
	 * Updates the published state of the current script
	 *
	 * @param {Object} currentScript
	 *	Currently active script
	 *
	 * @param {Boolean} status
	 *  Boolean indicating whether current script is
	 *  published
	 */
	[names.SET_PUBLISHED_STATE]({ currentScript }, status = false) {
		currentScript.is_published = status;
	},

    /**
	 * Sets the current script to given script
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} script
	 *  Script to set as current
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_SCRIPT](state, script) {
		// save script to state
		state.currentScript = script;

		// a newly loaded script is not modified yet
		state.modified = false;

		// determine if meta document can be modified
		state.canBeModified = userCanModifyScript();
	},

	/**
	 * Unsets the current script
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.UNSET_SCRIPT](state) {
		// unset current script in state
		state.currentScript = {};

		// unset modified marker
		state.modified = false;
	},

	/**
	 * Marks the current script as modified
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.MARK_MODIFIED](state) {
		state.modified = true;

		// every change to the script means that the script should be validated again
		this.commit(
			getStoreMutation('SET_IS_VALID'), false, { root: true }
		);
	},

    /**
	 * Updates the name of the currently active script
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {String} newName
	 *  Name to set for script
	 *
	 * @returns {void}
	 */
	[names.SET_NAME]({ currentScript }, newName) {
		// check if currently in edit mode
		if (!this.getters[getStoreGetter('CAN_BE_EDITED', 'SB')]) return;

		// set name on script
		currentScript.name = newName;

		// mark script as modified
		this.commit(
			getStoreMutation('MARK_MODIFIED', 'SB'), null, { root: true }
		);
	},

	/**
	 * Resets module state to default
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @returns {void}
	 */
	[names.RESET](state) {
		Object.assign(state, getDefaultState());
	},
};
