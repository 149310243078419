import modules from '@modules/store';

import RootGetters from '@assets/scripts/store/getters';
import RootMutations from '@assets/scripts/store/mutations';
import RootActions from '@assets/scripts/store/actions';

import { IS_PROD } from '@assets/scripts/helpers';

export const stores = {
	ROOT: 'root',
};

const getterNames = {
	[stores.ROOT]: RootGetters,
};

const mutationNames = {
	[stores.ROOT]: RootMutations,
};

const actionNames = {
	[stores.ROOT]: RootActions,	
};

const getPrefix = (store) => {
	return store === 'ROOT' ? '' : `${stores[store]}/`;
};

export const getStoreGetter = (getter, store = 'ROOT') => {
	if (!IS_PROD) {
		if (!stores[store]) {
			throw new Error(`Call to unknown store: ${store}`);
		}

		if (!getterNames[stores[store]][getter]) {
			throw new Error(
				`Call to unkown getter "${getter}" in store "${store}".`
			);
		}
	}
	return `${getPrefix(store)}${getterNames[stores[store]][getter]}`;
};

export const getStoreMutation = (mutation, store = 'ROOT') => {
	if (!IS_PROD) {
		if (!stores[store]) {
			throw new Error(`Call to unknown store: ${store}`);
		}

		if (!mutationNames[stores[store]][mutation]) {
			throw new Error(
				`Call to unkown mutation "${mutation}" in store "${store}".`
			);
		}
	}

	return `${getPrefix(store)}${mutationNames[stores[store]][mutation]}`;
};

export const getStoreAction = (action, store = 'ROOT') => {
	if (!IS_PROD) {
		if (!stores[store]) {
			throw new Error(`Call to unknown store: ${store}`);
		}

		if (!actionNames[stores[store]][action]) {
			throw new Error(
				`Call to unkown action "${action}" in store "${store}".`
			);
		}
	}

	return `${getPrefix(store)}${actionNames[stores[store]][action]}`;
};

// loop over modules
for (const module in modules) {
	// check if module defines store names
	if (modules[module].storeNames) {
		const id = modules[module]?.storeId ?? module;

		// add module store names to existing store names
		stores[id] = module;
		actionNames[module] = modules[module].storeNames?.actions ?? [];
		getterNames[module] = modules[module].storeNames?.getters ?? [];
		mutationNames[module] = modules[module].storeNames?.mutations ?? [];
	}
}
