import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import getDefaultState from './state';
import { userCanModifyDoc } from '@modules/MetaDocumentBuilder/components/metadata-document';

export const names = {
	TOGGLE_CREATE_NEW: 'toggleCreateNewDocument',
	SET_CURRENT_META_DOCUMENT: 'setCurrentMetaDocument',
	UNSET_META_DOCUMENT: 'unsetCurrentMetaDocument',
	MARK_MODIFIED: 'markModified',
	SET_PUBLISHED_STATE: 'setPublishedState',
	SET_NAME: 'setName',
	RESET: 'reset',
	SAVE_FIELD: 'saveField',
};

export default {
	[names.TOGGLE_CREATE_NEW](state, status = true) {
		if (state.showCreateNew !== status) state.showCreateNew = status;
	},
	/**
	 * Sets the current meta document to given meta document
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} document
	 *  Meta document to set as current
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_META_DOCUMENT](state, document) {
		// save document to state
		state.currentMetaDocument = document;

		// a newly loaded document is not modified yet
		state.modified = false;

		// determine if meta document can be modified
		state.canBeModified = userCanModifyDoc();
	},

	/**
	 * Updates the published state of the current document
	 *
	 * @param {Object} currentMetaDocument
	 *	Currently active document
	 *
	 * @param {Boolean} status
	 *  Boolean indicating whether current document is
	 *  published
	 */
	[names.SET_PUBLISHED_STATE]({ currentMetaDocument }, status = false) {
		currentMetaDocument.is_published = status;
	},

	/**
	 * Unsets the current meta document
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.UNSET_META_DOCUMENT](state) {
		// unset current meta document in state
		state.currentMetaDocument = {};

		// unset modified marker
		state.modified = false;
	},
	/**
	 * Marks the current metadata document as modified
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.MARK_MODIFIED](state) {
		state.modified = true;

		// every change to the document means that the document should be validated again
		this.commit(
			getStoreMutation('SET_IS_VALID'), false, { root: true }
		);
	},
	/**
	 * Updates the name of the currently active meta document
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {String} newName
	 *  Name to set for meta document
	 *
	 * @returns {void}
	 */
	[names.SET_NAME]({ currentMetaDocument }, newName) {
		// check if currently in edit mode
		if (!this.getters[getStoreGetter('CAN_BE_EDITED', 'MB')]) return;

		// set name on document
		currentMetaDocument.name = newName;

		// mark document as modified
		this.commit(
			getStoreMutation('MARK_MODIFIED', 'MB'), null, { root: true }
		);
	},
	/**
	 * Updates a meta Document field with a given key of
	 * the currently viewed/edited meta document, or adds
	 * a new field to it
	 *
	 * @param {Object} state
	 *  Ref to module state
	 *
	 * @param {Object} param
	 *  Parameters, not destructured since the object
	 *  is used to signal success/failure back to
	 *  caller function
	 *
	 *   @param {Object} field
	 *    Field object to save
	 *   @param {Integer} key
	 *    Key of field to update in field list, or false
	 *    to trigger insert of new field
	 *   @param {Boolean} result
	 *    Indicates success/failure of update
	 *
	 *  Start field to add to current block
	 */
	 [names.SAVE_FIELD](state, param) {
		const { field, key } = param;

		const metaDocument = this.getters[getStoreGetter('CURRENT_META_DOCUMENT', 'MB')];

		if (!metaDocument) {
			param.result = false;
			return;
		}

		// get current fields
		const fields = metaDocument.elements;

		if (key === false) {
			// add new field
			fields.push(field);
			// mark insert as success
			param.result = true;
		} else if (typeof fields[key] !== 'undefined') {
			// replace field with new field
			fields[key] = field;
			// mark update as success
			param.result = true;
		} else {
			// mark update as failed
			param.result = false;
		}
	},
	/**
	 * Resets module state to default
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @returns {void}
	 */
	[names.RESET](state) {
		Object.assign(state, getDefaultState());
	},
};
