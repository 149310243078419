import { userCanModifyConn } from '@modules/ConnectionBuilder/components/connection';
import getDefaultState from './state';
import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';

export const names = {
	MARK_MODIFIED: 'markModified',
	RESET: 'reset',
	TOGGLE_CREATE_NEW: 'toggleCreateNew',
	SET_CURRENT_CONNECTION: 'setCurrentConnection',
	UNSET_CONNECTION: 'unsetConnection',
	SET_NAME: 'setName',
};

export default {
	[names.TOGGLE_CREATE_NEW](state, status = true) {
		if (state.showCreateNew !== status) state.showCreateNew = status;
	},
	/**
	 * Updates the name of the currently active connection
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {String} newName
	 *  Name to set for connection
	 *
	 * @returns {void}
	 */
	[names.SET_NAME]({ currentConnection }, newName) {
		// check if currently in edit mode
		if (!this.getters[getStoreGetter('CAN_BE_EDITED', 'CB')]) return;

		// set name on connection
		currentConnection.name = newName;

		// mark connection as modified
		this.commit(
			getStoreMutation('MARK_MODIFIED', 'CB'), null, { root: true }
		);
	},
	/**
	 * Unsets the current connection
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.UNSET_CONNECTION](state) {
		// unset current connection in state
		state.currentConnection = {};

		// unset modified marker
		state.modified = false;
	},
	/**
	 * Marks the current connection as modified
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.MARK_MODIFIED](state) {
		state.modified = true;

		// every change to the connection means that the connection should be validated again
		this.commit(
			getStoreMutation('SET_IS_VALID'), false, { root: true }
		);
	},
	/**
	 * Resets module state to default
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @returns {void}
	 */
	[names.RESET](state) {
		Object.assign(state, getDefaultState());
	},
	/**
	 * Sets the current connection to newly retrieved connection
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} connection
	 *  Retrieved connection from Nominow REST API
	 *
	 * @returns {void}
	 */
	 [names.SET_CURRENT_CONNECTION](state, connection) {
		// save document to state
		state.currentConnection = connection;

		// a newly loaded document is not modified yet
		state.modified = false;

		// determine if document can be modified
		state.canBeModified = userCanModifyConn();
	},
};
