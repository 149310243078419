export default (blockInputs, mappingObject, objectDefinition) => {
	const fromField = blockInputs.find(
		(input) => input.name === mappingObject.from
	);
	const toField = objectDefinition.input.find(
		(input) => input.name === mappingObject.to
	);
	return {
		fromField: fromField,
		toField: toField,
	};
};
