import Helpers from '@assets/scripts/helpers';

const state = {
	showCreateNew: false,
	modified: false,
	canBeModified: false,
	currentMetaDocument: {},
};

export default () => Helpers.cloneObject(state);
