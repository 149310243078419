import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;    

export default {
    label: t('cd.permissions.label'),
    info: t('cd.permissions.info'),
    module: 'ComposedDocument',
    permissions: {
        'Read': {
            label: t('cd.permissions.readComposedDocument.label'),
            info: t('cd.permissions.readComposedDocument.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Upsert': {
            implicit: [
                'Read',
                'Publish',
            ],
            label: t('cd.permissions.editComposedDocument.label'),
            info: t('cd.permissions.editComposedDocument.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Delete': {
            implicit: [
                'Read',
            ],
            label: t('cd.permissions.deleteComposedDocumentDraft.label'),
            info: t('cd.permissions.deleteComposedDocumentDraft.info'),
            availableInRole: true // if true will be visible in role details as a checkbox
        },
        'Publish': {
            // this permission is only for the backend to check if user can publish composed document 
            // in the frontend we implicit this permission with Upsert permission and we only show one checkbox for both Upsert and publish
            // in this case backend will get a separate publish and upsert permissions
            availableInRole: false // if true will be visible in role details as a checkbox
        },
    }
}